import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../ui/form';
import { useForm } from 'react-hook-form';
import PhoneNumber from '../telInput';
import { Icons } from '../../../../assets/icons';
import { Button } from '../../../ui/button';
import { TOKEN } from '../../../../utils/token';
import { Input } from '../../../ui/input';
import { useCookies } from 'react-cookie';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { toast } from 'sonner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetTimeZones } from '../../../../helpers/api/useAuth';
import { Checkbox } from '../../../ui/checkbox';
import { Label } from '../../../ui/label';
import { getErrorMessage } from '../../../../utils/helper';
import ReactPasswordChecklist from 'react-password-checklist';
import useAxiosWithInterceptor from '../../../../helpers/axiosConfig';
import { useLocation } from 'react-router-dom';

interface Zone {
  id: string;
}

type Props = {
  setLoading: (arg0: boolean) => void;
  isLoading: boolean;
  toggleCompanyForm: (arg0: boolean) => void;
};

export interface ICompanyAccount {
  companyName: string;
  password: string;
  email: string;
  phone: string;
  pushNotificationId: string;
  defaultCurrencyId: number;
}

const CompanyForm = (props: Props) => {
  const location = useLocation(); // Access the URL
  const searchParams = new URLSearchParams(location.search); // Parse the query string
  const referralCode = searchParams.get('ref'); // Get the "referral" parameter
  const [agree, setAgree] = useState<boolean>(false);
  const { isLoading, setLoading, toggleCompanyForm } = props;
  const setCookie = useCookies()[1];
  const zonesRef = useRef<Zone[] | null>(null);
  const { data } = useGetTimeZones();
  const timezone = data?.data;
  const axiosInstance = useAxiosWithInterceptor();

  const getTimeZone = useCallback(async () => {
    const timezoneOffset = new Date().getTimezoneOffset();
    let userTimeZone: string | number = timezoneOffset / 60;
    if (timezoneOffset === 0) {
      userTimeZone = `00:00:00`;
    } else if (userTimeZone < 0 && timezoneOffset !== 0) {
      userTimeZone = `0${Math.abs(userTimeZone)}:00:00`;
    } else if (userTimeZone > 0 && timezoneOffset !== 0) {
      userTimeZone = `-0${userTimeZone}:00:00`;
    }
    if (timezone && timezone && timezone.length) {
      const filteredZones = timezone.filter(
        (zone: { baseUTCOffSet: string }) =>
          zone.baseUTCOffSet === userTimeZone.toString()
      );
      zonesRef.current = filteredZones; // Update zonesRef with filtered zones
      // Now you can use zonesRef.current to access the filtered zones
    }
  }, [timezone]);

  useEffect(() => {
    getTimeZone();
  }, [getTimeZone]);

  const defaultCompanyValues: ICompanyAccount = {
    companyName: '',
    email: '',
    password: '',
    phone: '',
    defaultCurrencyId: 1,
    pushNotificationId: '',
  };

  const form = useForm({
    defaultValues: defaultCompanyValues,
  });

  const {
    formState: { errors },
  } = form;

  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const handleRegisterCompany = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    form.handleSubmit(async (data) => {
      // Mark the callback function as async
      const regexPattern =
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])(?!.*\s).{8,}$/;
      if (!regexPattern.test(data.password)) {
        toast.warning('Password too weak');
        return;
      }
      setLoading(true);
      let payload = {
        ...data,
        accountType: 'Corporate',
        referredBy: referralCode || '',
        timeZone: zonesRef.current && zonesRef.current[0]?.id,
      };
      try {
        const res = await axiosInstance.post(`/api/v1/user/register`, payload);
        if (res && res.data) {
          setCookie(TOKEN.TOKENEXP, res.data.data.jwtTokenExpiry);
          setCookie(TOKEN.ACCESS, res.data.data.jwtToken);
          setCookie(TOKEN.ID, res.data.data.user?.id);
          setCookie(TOKEN.EMAIL, res.data.data.user?.email);
          toggleCompanyForm(true);
        }
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        const message = getErrorMessage(error);
        toast.error(message);
      }
    })();
  };
  const password = form.watch('password');
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <>
      <Form {...form}>
        <form
          onSubmit={handleRegisterCompany}
          className="flex flex-col w-full gap-2"
        >
          <FormField
            name="companyName"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: 'Company name is required',
              },
              validate: (value) =>
                value.trim() !== '' || 'Company name cannot be empty',
            }}
            render={({ field }) => (
              <FormItem className="relative ">
                <FormLabel className="font-normal">Company Name</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="text"
                    placeholder="Enter your company name"
                    autoComplete="off"
                    error={Boolean(errors.companyName)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            name="email"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: 'Email is required',
              },
            }}
            render={({ field }) => (
              <FormItem className="relative ">
                <FormLabel className="font-normal">Company Email</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="email"
                    placeholder="Enter your company email"
                    autoComplete="off"
                    error={Boolean(errors.email)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <PhoneNumber form={form} fieldName="Company Phone Number" />
          <FormField
            name="password"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: 'Password is required',
              },
            }}
            render={({ field }) => (
              <FormItem className="relative ">
                <FormLabel className="font-normal">Password</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type={passwordShown ? 'text' : 'password'}
                    placeholder="Enter password"
                    error={Boolean(errors.password)}
                    className="h-12"
                    endIcon={
                      <span onClick={togglePassword}>
                        {!passwordShown ? (
                          <FontAwesomeIcon icon={faEye} />
                        ) : (
                          <FontAwesomeIcon icon={faEyeSlash} />
                        )}
                      </span>
                    }
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <ReactPasswordChecklist
            rules={['minLength', 'specialChar', 'number', 'capital']}
            minLength={6}
            value={password}
            onChange={() => {
              form.setValue('password', password);
            }}
            iconSize={10}
          />
          <div className="flex flex-col gap-2 mt-4">
            <h1 className="text-sm font-normal">
              Do you agree to our Terms and Conditions?
            </h1>
            <div className="flex items-center space-x-3 space-y-0">
              <Checkbox
                checked={agree}
                onCheckedChange={(checked) => setAgree(checked === true)}
              />
              <div className="leading-none">
                <Label onClick={() => setAgree((prev) => !prev)}>
                  I have read and agree to the Terms and Conditions.
                </Label>
              </div>
            </div>
          </div>
          <Button className="mt-4" disabled={isLoading || !agree}>
            {isLoading ? (
              <Icons.Spinner className="w-5 h-5 animate-spin" />
            ) : (
              'Next'
            )}
          </Button>
        </form>
      </Form>
    </>
  );
};
export default CompanyForm;
