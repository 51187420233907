import React, { ReactNode } from 'react';
import { cn } from '../../lib/utils';

interface MyComponentProps {
  children: ReactNode;
  className?: string;
}

const ContainerLayout: React.FC<MyComponentProps> = ({
  children,
  className,
}) => {
  return (
    <div className="w-[90%] md:w-[93%] lg:w-[96%] mx-auto dark:p-0 p-0 lg:p-4 bg-[#F2F2F2] dark:bg-transparent rounded-[40px] relative h-full min-h-[90vh]">
      <div
        className={cn(
          'flex flex-col items-start h-full gap-4 dark:p-0 p-0 bg-white dark:bg-transparent lg:p-5 rounded-[30px]',
          className
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default ContainerLayout;
