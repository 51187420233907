import MyAsset from '../../components/dashboard/MyAsset';

import React from 'react';
import { useGetMyAsset } from '../../helpers/api/useAsset';

const MyAssetPage = () => {
  const {
    isLoading: dealsLoading,
    isError,
    data,
    mutate,
    errorMessage,
  } = useGetMyAsset();
  const myAssets = data?.data?.data;

  return (
    <MyAsset
      firstAsset={myAssets && myAssets?.slice(0, 1)[0]}
      assets={myAssets ?? []}
      isLoading={dealsLoading}
      setTab={mutate}
      isError={isError}
      errorMessage={errorMessage ?? ''}
    />
  );
};

export default MyAssetPage;
