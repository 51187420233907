import React from 'react';
import Footer from './Footer';
import Nav from './Navbar';
import { useEffect } from 'react';

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Nav />
      <main className="flex flex-col gap-4 app-container">
        <section className="py-4 space-y-4 sm:py-6 sm:space-y-0">
          <h4 className="text-2xl md:text-6xl font-bold text-[#04093F] leading-none md:leading-[80px]">
            Your privacy matters...
          </h4>
          <p className="text-[15px] text-[#04093F]">
            Learn about our commitment to keeping your data private
          </p>
        </section>
        <section className="pb-4 space-y-4">
          <p>
            Musha is committed to protecting the privacy and security of your
            personal information. This Privacy Policy outlines how we collect,
            use, share, and protect your information when you use our website,
            mobile applications, products, and services.
          </p>

          <h2 className="mb-2 text-xl font-semibold">Information We Collect</h2>

          <p>
            We may collect personal information about you directly from you or
            from third parties, as well as automatically through your use of our
            Services. The types of personal information we may collect include:
          </p>

          <ul className="mb-4 list-disc list-inside">
            <li>
              Personal Information: When you visit our Site or use our services,
              we may collect personal information such as your name, email
              address, contact details, and any other information you
              voluntarily provide.
            </li>
            <li>
              Usage Information: We may collect information about your device,
              browser type, IP address, and browsing activity on our Site.
            </li>
            <li>
              Cookies: We use cookies and similar tracking technologies to
              enhance your experience on our Site and analyze usage patterns.
            </li>
          </ul>

          <h2 className="mb-2 text-xl font-semibold">
            How We Use the Information
          </h2>

          <ul className="mb-4 list-disc list-inside">
            <li>
              We use your personal information to provide and improve our
              services, communicate with you, and personalize your experience on
              our Site.
            </li>
            <li>
              We may use usage information and cookies to analyze trends,
              administer the Site, track users' movements, and gather
              demographic information.
            </li>
            <li>
              We may use your information to send you marketing communications
              about our products and services, subject to your preferences.
            </li>
          </ul>

          <h2 className="mb-2 text-xl font-semibold">Information Sharing:</h2>

          <ul className="mb-4 list-disc list-inside">
            <li>
              We may share your information with third-party service providers
              who assist us in operating our Site and providing our services.
            </li>
            <li>
              We may disclose your information to comply with legal obligations,
              enforce our policies, or protect our rights, property, or safety.
            </li>
          </ul>

          <h2 className="mb-2 text-xl font-semibold">Data Security</h2>
          <p>
            We implement appropriate security measures to protect your personal
            information against unauthorized access, alteration, disclosure, or
            destruction.
          </p>

          <h2 className="mb-2 text-xl font-semibold"> Your Choices</h2>

          <p>
            You may opt-out of receiving marketing communications from us by
            following the instructions provided in the communication or
            contacting us directly. You can disable cookies in your browser
            settings, although this may affect your experience on our Site.
          </p>

          <h2 className="mb-2 text-xl font-semibold">Children's Privacy</h2>

          <p>
            Our Site and services are not directed at children under the age of
            18, and we do not knowingly collect personal information from
            children.
          </p>

          <h2 className="mb-2 text-xl font-semibold">
            Changes to This Privacy Policy
          </h2>

          <p>
            We may update this Privacy Policy from time to time by posting the
            revised version on our Site. Your continued use of the Site after
            the posting of any changes constitutes your acceptance of such
            changes.
          </p>

          <h2 className="mb-2 text-xl font-semibold">Contact Us</h2>

          <p>
            If you have any questions or concerns about this Privacy Policy or
            our data practices, please contact us at{' '}
            <a href="mailto:support@mushaapp.com" className="text-blue-500">
              mail
            </a>
            . By using our Site, you consent to the terms of this Privacy
            Policy.
          </p>
        </section>
      </main>{' '}
      <Footer />
    </div>
  );
};

export default Privacy;
