import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { FieldValues, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { Button } from '../../ui/button';
import { Input } from '../../ui/input';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../ui/form';
import { cn } from '../../../lib/utils';
import {
  useGetAssetConfig,
  useGetCountry,
  useGetLga,
  useGetPropertyTypes,
  useGetState,
} from '../../../helpers/api/useAsset';
import Select from 'react-select';
import { Label } from '../../ui/label';
import { toast } from 'sonner';
import {
  FirstAssetFormSchema,
  FirstAssetType,
  INewAssetFirstForm,
} from './schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { ensureNumber, removeHtmlTags } from '../../../utils/helper';
import { CustomOption, styles } from '../../../lib/react-select-config';
import { ExtendedNewAsset } from '.';
import { Icons } from '../../../assets/icons';
import QuillEditor from './quillEditor';

type Props = {
  setClear: (arg0: boolean) => void;
  isClear: boolean;
  onFinish: (data: any) => void;
  data: ExtendedNewAsset | undefined;
  triggerDefaultValue: boolean;
};

const StepOne: React.FC<Props> = ({
  onFinish,
  data,
  isClear,
  setClear,
  triggerDefaultValue,
}) => {
  const [selectedPropertyIndex, setSelectedPropertyIndex] = useState<number>();
  const [propertyTypeName, setPropertyTypeName] = useState<string>('');
  const [moratoriumName, setMoratoriumName] = useState<string>('');
  const assetForm = {
    title: '',
    description: '',
    moratoriumId: undefined,
    propertyTypeId: undefined,
    address: {
      localGovernmentId: undefined,
      fullAddress: '',
      country: undefined,
      state: undefined,
    },
  };

  const form = useForm<FirstAssetType>({
    mode: 'onChange',
    resolver: yupResolver(FirstAssetFormSchema),
    defaultValues: assetForm,
  });

  const {
    control,
    formState: { errors },
    setValue,
    clearErrors,
  } = form;
  const { loadingTypes, propertyTypes } = useGetPropertyTypes();
  const moratoriumId = form.getValues('moratoriumId');
  const propertyTypeId = form.getValues('propertyTypeId');
  const countryId = useWatch({ control, name: 'address.country' });
  const stateId = useWatch({ control, name: 'address.state' });
  const { countries, loadingCountries } = useGetCountry();
  const { states, loadingStates } = useGetState(String(countryId?.value));
  const { lga, loadingLga } = useGetLga(String(stateId?.value));
  const { assetConfig, isSuccess, loadingAssetConfig, isError, errorMessage } =
    useGetAssetConfig(propertyTypeId as string, stateId?.value);
  const [dropdownVisibility, setDropdownVisibility] = useState({
    country: false,
    state: false,
    lga: false,
  });

  const toggleDropdown = (id: string, currentState: boolean) => {
    setDropdownVisibility((prevState) => ({
      ...prevState,
      [id]: currentState,
    }));
  };

  const handlePropertyTypeCheck = (id: number, name: string) => {
    setSelectedPropertyIndex(id);

    if (selectedPropertyIndex !== id) {
      // Clear moratorium field if the same property type is clicked again
      setValue('moratoriumId', '' as never);
      clearErrors('moratoriumId');
    }
    setValue('propertyTypeId', id as never);
    setPropertyTypeName(name);
    clearErrors('propertyTypeId');
  };
  const inputRef = useRef<HTMLInputElement | null>(null); // Initialize with null

  useEffect(() => {
    if (isClear) {
      form.reset(assetForm);
      setClear(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClear]);

  useEffect(() => {
    if (isSuccess) {
      const {
        minimumUnit,
        minimumPriceUnit,
        maximumRoi,
        roiMutiple,
        isPropertyConversionAllowed,
      } = assetConfig;
      setValue('availableUnits', minimumUnit as never);
      setValue('unitPrice', minimumPriceUnit as never);
      setValue('roiMutiple', roiMutiple as never);
      setValue('maximumRoi', maximumRoi as never);
      setValue(
        'isPropertyConversionAllowed',
        isPropertyConversionAllowed as never
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage ?? 'Cannot get property type configuration');
      setValue('moratoriumId', '' as never);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const submitForm: SubmitHandler<FieldValues> = async (values) => {
    const unitPrice = data?.unitPrice;
    const formData = { ...values } as FirstAssetType;
    const payload: INewAssetFirstForm = {
      title: formData.title || '', // Use empty string as default value if title is undefined
      description: formData.description || '',
      moratoriumId: formData.moratoriumId, // Assuming moratoriumId is a string, parse it to number
      propertyTypeId: Number(formData.propertyTypeId), // Assuming propertyTypeId is a string, parse it to number
      address: {
        fullAddress: formData.address.fullAddress || '',
        localGovernmentId: formData.address.localGovernmentId, // Assuming localGovernmentId is a string, parse it to number
        country: formData.address.country,
        state: formData.address.state,
      },
      availableUnits: formData?.availableUnits,
      unitPrice:
        typeof unitPrice !== 'undefined' && unitPrice !== formData?.unitPrice
          ? unitPrice
          : formData?.unitPrice,
      roiMutiple: formData?.roiMutiple,
      maximumRoi: formData?.maximumRoi,
      isPropertyConversionAllowed: formData?.isPropertyConversionAllowed,
    };

    onFinish({
      ...payload,
      propertyName: propertyTypeName,
      moratoriumName: moratoriumName,
    });
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (assetConfig && assetConfig.moratorium?.length > 0) {
      if (
        !moratoriumId ||
        moratoriumId === '' ||
        moratoriumId === undefined ||
        moratoriumId === null
      ) {
        form.setError('moratoriumId', {
          type: 'manual',
          message: 'Moratorium is required',
        });
        return;
      }
    }
    form.handleSubmit(submitForm)();
  };

  useEffect(() => {
    if (data) {
      form.reset({
        title: data?.title,
        description: data?.description,
        moratoriumId: data?.moratoriumId?.toString(),
        propertyTypeId: data?.propertyTypeId,
        address: {
          fullAddress: data?.address?.fullAddress || '',
          localGovernmentId: data?.address?.localGovernmentId, // Assuming localGovernmentId is a string, parse it to number
          country: data?.address?.country,
          state: data?.address?.state,
        },
        availableUnits: data?.availableUnits,
        unitPrice: data?.unitPrice,
        roiMutiple: data?.roiMutiple,
        maximumRoi: data?.maximumRoi,
        isPropertyConversionAllowed: data?.isPropertyConversionAllowed,
      });
      setSelectedPropertyIndex(Number(data?.propertyTypeId));
      setMoratoriumName(data?.moratoriumName);
      setPropertyTypeName(data?.propertyName);
      clearErrors('propertyTypeId');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // Quill editor
  const handleSetValue = (name: string, value: string) => {
    form.setValue('description', value.toString() as never, {
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const description = useWatch({ control, name: 'description' });

  const contentLength = useMemo(() => {
    return removeHtmlTags(description ?? '').trim().length;
  }, [description]);

  useEffect(() => {
    if (contentLength > 1500) {
      form.setError('description', {
        type: 'manual',
        message: 'Description must not be at greater than 1500 characters long',
      });
    } else {
      clearErrors('description');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentLength]);

  return (
    <Form {...form}>
      <form className="space-y-3" onSubmit={handleSubmit}>
        <FormField
          name="title"
          control={control}
          render={({ field }) => (
            <FormItem className="relative ">
              <FormLabel>Property Title</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  type="text"
                  placeholder="Enter property title"
                  autoComplete="off"
                  error={Boolean(errors.title)}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="space-y-1">
          <Label
            className={cn({
              'text-destructive': Boolean(errors.propertyTypeId),
            })}
          >
            Property Type
          </Label>
          {loadingTypes ? (
            <div className="flex items-center gap-4 overflow-x-auto">
              {[...Array(4)].map((_, index) => (
                <div
                  key={index}
                  className="relative flex-shrink-0 w-[130px] h-[85px] inline-flex items-center justify-between p-3 rounded-lg dark:bg-transparent bg-zinc-50 animate-pulse"
                >
                  <div className="flex flex-col w-full gap-1 mt-5">
                    <span className="w-full h-3 bg-gray-200 rounded-xl animate-pulse" />
                    <span className="w-full h-3 bg-gray-200 rounded-xl animate-pulse" />
                  </div>
                  <span className="absolute w-4 h-4 bg-gray-200 top-2 right-2 rounded-xl animate-pulse" />
                </div>
              ))}
            </div>
          ) : !propertyTypes ||
            (propertyTypes && propertyTypes.length === 0) ? (
            <div className="w-full p-4 border rounded-lg border-destructive">
              <p className="text-sm">No property type found</p>
            </div>
          ) : (
            <ul className="flex gap-4 py-2 overflow-x-auto">
              {propertyTypes?.map(
                (
                  property: {
                    id: number;
                    name: string;
                  },
                  i: number
                ) => (
                  <li className="relative" key={i}>
                    <FormField
                      name="propertyTypeId"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <label
                              htmlFor={`propertyTypeId_${i}`}
                              className={cn(
                                'w-[130px] h-[85px] inline-flex items-center justify-between p-3 text-gray-500 bg-[#F7F9FB] dark:bg-transparent rounded-lg cursor-pointer dark:border hover:text-gray-600 hover:bg-[#F7F9FB]/90 dark:text-gray-400 ',
                                {
                                  'border-2 border-[#9AB5FF] ':
                                    selectedPropertyIndex === property?.id,
                                },
                                {
                                  'border border-red-600 focus-visible:ring-red-500 bg-white':
                                    Boolean(errors.propertyTypeId),
                                }
                              )}
                              onClick={() =>
                                handlePropertyTypeCheck(
                                  property?.id,
                                  property?.name.toLowerCase()
                                )
                              }
                            >
                              <Input
                                {...field}
                                type="radio"
                                id={`propertyTypeId_${i}`}
                                className="absolute w-5 h-5 top-2 right-2 peer accent-primary"
                                value={property?.id}
                                ref={inputRef} // Assign the ref directly
                                checked={property?.id === selectedPropertyIndex}
                              />
                              <div className="flex flex-col gap-2">
                                <p className="flex-shrink-0 text-sm truncate w-28">
                                  {property?.name}
                                </p>
                              </div>
                            </label>
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </li>
                )
              )}
            </ul>
          )}
        </div>
        <div className="flex flex-col w-full gap-4">
          <div className="grid grid-cols-2 gap-4">
            <FormField
              control={control}
              name="address.country"
              rules={{ required: true }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Country</FormLabel>
                  <FormControl>
                    <Select
                      {...field}
                      onChange={field.onChange}
                      defaultValue={field.value}
                      isSearchable
                      onMenuOpen={() => toggleDropdown('country', true)}
                      onMenuClose={() => toggleDropdown('country', false)}
                      placeholder="Select an option"
                      className={`${
                        errors.address?.country
                          ? 'border-destructive'
                          : 'border-border'
                      }  w-full capitalize border rounded-lg h-12`}
                      isDisabled={false}
                      isLoading={loadingCountries}
                      components={{
                        Option: CustomOption,
                        IndicatorSeparator: () => null,
                      }}
                      options={
                        countries?.map((item: any) => ({
                          value: item.id,
                          label: item.name,
                        })) || []
                      }
                      styles={styles}
                      menuIsOpen={dropdownVisibility.country}
                    />
                  </FormControl>
                  {errors.address?.country && (
                    <p className="text-xs font-medium text-destructive">
                      Country is required
                    </p>
                  )}
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="address.state"
              rules={{ required: true }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>State</FormLabel>
                  <FormControl>
                    <Select
                      {...field}
                      onChange={field.onChange}
                      defaultValue={field.value}
                      isSearchable
                      onMenuOpen={() => toggleDropdown('state', true)}
                      onMenuClose={() => toggleDropdown('state', false)}
                      placeholder="Select an option"
                      className={`${
                        errors.address?.state
                          ? 'border-destructive'
                          : 'border-border'
                      }  w-full capitalize border rounded-lg h-12`}
                      isDisabled={false}
                      isLoading={loadingStates}
                      components={{
                        Option: CustomOption,
                        IndicatorSeparator: () => null,
                      }}
                      options={
                        states?.map((item: any) => ({
                          value: item.id,
                          label: item.name,
                        })) || []
                      }
                      styles={styles}
                      menuIsOpen={dropdownVisibility.state}
                    />
                  </FormControl>
                  {errors.address?.state && (
                    <p className="text-xs font-medium text-destructive">
                      State is required
                    </p>
                  )}
                </FormItem>
              )}
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <FormField
              control={control}
              name="address.localGovernmentId"
              rules={{ required: true }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Local Government</FormLabel>
                  <FormControl>
                    <Select
                      {...field}
                      onChange={field.onChange}
                      defaultValue={field.value}
                      isSearchable
                      onMenuOpen={() => toggleDropdown('lga', true)}
                      onMenuClose={() => toggleDropdown('lga', false)}
                      placeholder="Select an option"
                      className={`${
                        errors.address?.localGovernmentId
                          ? 'border-destructive'
                          : 'border-border'
                      }  w-full capitalize border rounded-lg h-12`}
                      isDisabled={false}
                      isLoading={loadingLga}
                      components={{
                        Option: CustomOption,
                        IndicatorSeparator: () => null,
                      }}
                      options={
                        lga?.map((item: any) => ({
                          value: item.id,
                          label: item.name,
                        })) || []
                      }
                      styles={styles}
                      menuIsOpen={dropdownVisibility.lga}
                    />
                  </FormControl>
                  {errors.address?.localGovernmentId && (
                    <p className="text-xs font-medium text-destructive">
                      Local government ID is required
                    </p>
                  )}
                </FormItem>
              )}
            />

            <FormField
              name="address.fullAddress"
              control={control}
              render={({ field }) => (
                <FormItem className="relative ">
                  <FormLabel>Address</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type="text"
                      placeholder="Enter address"
                      error={Boolean(errors?.address?.fullAddress)}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        {propertyTypeId !== undefined &&
          stateId?.value !== undefined &&
          !isError && (
            <div className="space-y-1">
              <Label
                className={cn({
                  'text-destructive': Boolean(errors.moratoriumId),
                })}
              >
                Moratorium
              </Label>
              {loadingAssetConfig ? (
                <div className="flex items-center gap-4">
                  <div className="flex items-center justify-start w-40 h-12 px-4 border rounded-lg bg-zinc-50 animate-pulse">
                    <span className="w-24 h-3 bg-gray-200 rounded-xl animate-pulse" />
                  </div>
                  <div className="flex items-center justify-start w-40 h-12 px-4 border rounded-lg bg-zinc-50 animate-pulse">
                    <span className="w-24 h-3 bg-gray-200 rounded-xl animate-pulse" />
                  </div>
                </div>
              ) : (
                <ul className="flex gap-2 py-2 overflow-x-auto">
                  {assetConfig &&
                    assetConfig?.moratorium?.length > 0 &&
                    assetConfig?.moratorium?.map(
                      (
                        mora: {
                          id: number;
                          name: string;
                        },
                        i: number
                      ) => (
                        <li className="relative" key={i}>
                          <FormField
                            name="moratoriumId"
                            control={control}
                            rules={{
                              required: true,
                            }}
                            render={({ field }) => (
                              <FormItem>
                                <FormControl>
                                  <label
                                    htmlFor={`moratoriumId_${i}`}
                                    className={cn(
                                      'w-full border inline-flex items-center justify-between py-2 px-4 rounded-lg cursor-pointer hover:text-gray-600  ',
                                      {
                                        'bg-[#21D363] hover:bg-[#21D363]/80 text-white':
                                          ensureNumber(moratoriumId) ===
                                          mora?.id,
                                      },
                                      {
                                        'bg-[#F7F9FB] hover:bg-[#F7F9FB]/90 text-gray-500 ':
                                          ensureNumber(moratoriumId) !==
                                          mora?.id,
                                      },
                                      {
                                        'border-destructive': Boolean(
                                          errors.moratoriumId
                                        ),
                                      }
                                    )}
                                  >
                                    <Input
                                      id={`moratoriumId_${i}`}
                                      type="radio"
                                      className="hidden"
                                      {...field}
                                      onChange={() => {
                                        setValue(
                                          'moratoriumId',
                                          mora?.id.toString() as never
                                        );
                                        setMoratoriumName(mora?.name);
                                        clearErrors('moratoriumId');
                                      }}
                                    />
                                    <p className="m-0">{mora?.name}</p>
                                  </label>
                                </FormControl>
                              </FormItem>
                            )}
                          />
                        </li>
                      )
                    )}
                </ul>
              )}
            </div>
          )}

        <Suspense
          fallback={
            <div className="h-[250px] w-full rounded-lg opacity-50 border flex items-center justify-center">
              <p>loading...</p>
            </div>
          }
        >
          <QuillEditor
            setValue={handleSetValue}
            defaultValue={data?.description as string}
            error={
              Boolean(errors?.description) || description === '<p><br></p>'
            }
            triggerDefaultValue={triggerDefaultValue}
          />
          {errors?.description?.message && (
            <p className="text-xs font-medium text-destructive">
              {errors?.description?.message}
            </p>
          )}{' '}
          {/* Word Count */}
          <div className="flex items-center justify-between ">
            <Button
              type="button"
              size="sm"
              disabled={contentLength === 1 || contentLength === 0}
              variant="secondary"
              className="flex items-center gap-1 text-sm"
              onClick={() => {
                form.setValue('description', '' as never);

                // Clear the editor using inner HTML :)
                const element = document.getElementsByClassName('ql-editor');
                element[0].innerHTML = '';

                // clear errors
                form.clearErrors('description');
              }}
            >
              <Icons.RemoveIcon />
              Clear
            </Button>

            <span className="text-[#A0AEC0]">
              {contentLength === 1 ? 0 : contentLength} / 1000 words
            </span>
          </div>
        </Suspense>

        <div className="pt-4">
          <Button
            disabled={loadingAssetConfig || isError || contentLength > 1500}
            type="submit"
            className="w-full h-12"
          >
            Next
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default StepOne;
