import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '../../ui/dialog';
import { QRCodeSVG } from 'qrcode.react';

interface QRCodeDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  referralLink: string;
}

export function QRCodeDialog({
  isOpen,
  onOpenChange,
  referralLink,
}: QRCodeDialogProps) {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle className="text-center">Scan QR Code</DialogTitle>
        </DialogHeader>
        <div className="flex items-center justify-center p-6">
          <QRCodeSVG value={referralLink} size={200} />
        </div>
      </DialogContent>
    </Dialog>
  );
}
