import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Icons } from '../../../assets/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Switch } from '../../ui/switch';
import { Avatar, AvatarFallback, AvatarImage } from '../../ui/avatar';
import ThemeSwitcher from '../../ui/theme-switcher';
import React, { useState } from 'react';
import { Building2Icon } from 'lucide-react';
import { routes } from '../../../utils/route';
import { IUser } from '../../../interface/auth/api';
import UpdatePhoneNumberDialog from './updatePhoneNumberDialog';

interface MenuItemProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  onClick?: () => void;
  rightElement?: React.ReactNode;
}

const MenuItem: React.FC<MenuItemProps> = ({
  icon,
  title,
  description,
  onClick,
  rightElement,
}) => (
  <div
    onClick={onClick}
    className="flex items-center justify-between gap-4 cursor-pointer"
  >
    <div className="flex items-center gap-4">
      <div className="bg-[#F8F8FB] dark:bg-transparent dark:border justify-center rounded-full w-[59.37px] h-[59.37px] flex items-center flex-shrink-0">
        {icon}
      </div>
      <div className="flex flex-col">
        <h5 className="text-steel-gray dark:text-[#ffffff] font-medium text-base sm:text-lg">
          {title}
        </h5>
        <p className="text-sm text-light-grey sm:text-base">{description}</p>
      </div>
    </div>
    {rightElement || <Icons.ArrowDown className="-rotate-90" />}
  </div>
);

const Profile = ({
  user,
  set2Fa,
  is2fa,
  logout,
}: {
  user: IUser;
  set2Fa: (twofa: boolean) => void;
  is2fa: boolean;
  logout: () => void;
}) => {
  const navigate = useNavigate();
  let profilePicture = user && user?.profilePicture;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const firstName = user?.firstname;
  const lastName = user?.lastname;
  const fullName =
    firstName && lastName ? `${firstName} ${lastName}` : firstName || lastName;

  const menuItems: MenuItemProps[] = [
    {
      icon: <Icons.UserIcon className="text-primary dark:text-white" />,
      title: 'My Account',
      description: 'Update your account details',
      onClick: () => setIsOpen(true),
      // onClick: () => navigate(routes.profileUpdate),
    },
    {
      icon: <Icons.MarketIcon className="text-steel-gray dark:text-white" />,
      title: 'Order History',
      description: 'View your transaction history',
      onClick: () => navigate(routes.orderHistory),
    },
    {
      icon: <Icons.ReferralIcon className="text-steel-gray dark:text-white" />,
      title: 'Referral Dashboard',
      description: 'View your referrals and earnings',
      onClick: () => navigate(routes.referralDashboard),
    },
    {
      icon: (
        <Icons.FeedBackIcon className="w-5 h-5 text-steel-gray dark:text-white" />
      ),
      title: 'Feedback',
      description: 'Help us improve',
      onClick: () =>
        window.open('https://forms.gle/1tjppqhWPYu7Kwok6', '_blank'),
    },
    {
      icon: <Icons.LockIcon className="text-steel-gray dark:text-white" />,
      title: 'Dark Mode',
      description: 'Adjust your display preferences for comfort',
      rightElement: <ThemeSwitcher />,
    },
    {
      icon: <Icons.TwoFactorIcon />,
      title: 'Two-factor Authentication',
      description: 'Further secure your account for safety',
      rightElement: <Switch checked={is2fa} onCheckedChange={set2Fa} />,
    },
    {
      icon: <Icons.Logout2 className="text-steel-gray dark:text-white" />,
      title: 'Log out',
      description: 'Safely exit your account',
      onClick: logout,
    },
  ];

  return (
    <div className="flex flex-col w-full min-h-screen gap-4">
      <div className="w-full p-4 rounded-2xl bg-primary dark:bg-[#6E7DAA]">
        <div className="flex items-center gap-3 sm:justify-center bg-inherit">
          <Avatar className="w-20 h-20">
            <AvatarImage src={profilePicture} className="object-cover" />
            <AvatarFallback>
              {user?.accountType.toLowerCase() === 'corporate'
                ? user?.companyName?.charAt(0)
                : fullName.charAt(0)}
            </AvatarFallback>
          </Avatar>
          <div className="text-white bg-inherit">
            <h3 className="font-bold bg-inherit">
              {user?.accountType.toLowerCase() === 'corporate'
                ? user?.companyName
                : fullName || 'Not Available'}
            </h3>
            <p className="text-sm text-[#D7D7D7] bg-inherit">
              {user?.accountType || 'Not Available'}
            </p>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center gap-4 px-0 md:p-4">
        <div
          onClick={() => navigate(routes.myAsset)}
          className="relative p-4 bg-white dark:bg-transparent dark:border-[#3b3a3a] dark:border rounded-2xl h-full sm:h-[262px] w-[222.63px] flex flex-col items-center justify-center hover:border "
          style={{
            boxShadow:
              '0px 14.8421049118042px 44.52631378173828px 0px #0000000A',
          }}
        >
          <div className="flex flex-col items-center gap-4">
            <div className="bg-[#F8F8FB] dark:bg-transparent dark:border justify-center rounded-full w-[59.37px] h-[59.37px] flex items-center flex-shrink-0">
              <Building2Icon className="text-primary dark:text-white" />
            </div>
            <div className="space-y-2 text-center">
              <h5 className="text-steel-gray dark:text-[#ffffff] text-base sm:text-lg font-medium">
                My Assets
              </h5>
              <p className="text-sm text-light-grey sm:text-base">
                Access your <br className="sm:hidden" /> assets
              </p>
            </div>
          </div>

          <div className="hidden sm:absolute md:block bottom-5">
            <Link to={routes.myAsset}>
              <span className="inline-flex items-center gap-2 text-sm text-primary sm:text-base">
                View <FontAwesomeIcon icon={faArrowRight} />
              </span>
            </Link>
          </div>
        </div>
        <div
          onClick={() => navigate(routes.savedAsset)}
          style={{
            boxShadow:
              '0px 14.8421049118042px 44.52631378173828px 0px #0000000A',
          }}
          className="relative p-4 bg-white dark:bg-transparent dark:border-[#3b3a3a] dark:border hover:border rounded-2xl h-full sm:h-[262px] w-[222.63px] flex flex-col items-center justify-center"
        >
          <div className="bg-[#F8F8FB] dark:bg-transparent dark:border justify-center rounded-full w-[59.37px] h-[59.37px] flex items-center flex-shrink-0">
            <Icons.AssetIcon className="text-primary dark:text-white" />
          </div>
          <div className="mt-3 space-y-2 text-center">
            <h5 className="text-steel-gray dark:text-[#ffffff]  text-base sm:text-lg font-medium">
              Saved Assets
            </h5>
            <p className="text-sm text-light-grey sm:text-base">
              Access your saved assets
            </p>
          </div>
          <div className="hidden sm:absolute md:block bottom-5">
            <Link to={routes.savedAsset}>
              <span className="inline-flex items-center gap-2 text-sm text-primary sm:text-base">
                View <FontAwesomeIcon icon={faArrowRight} />
              </span>
            </Link>
          </div>
        </div>
      </div>
      <h4 className="text-steel-gray dark:text-[#ffffff] text-base sm:text-[20px] text-left mt-5">
        More
      </h4>

      <div className="flex flex-col w-full gap-5 bg-white dark:bg-transparent">
        {menuItems.map((item, index) => (
          <MenuItem key={index} {...item} />
        ))}
      </div>
      <UpdatePhoneNumberDialog user={user} isOpen={isOpen} onOpen={setIsOpen} />
    </div>
  );
};

export default Profile;
