import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { Label } from '../../ui/label';
import { Input } from '../../ui/input';
import { toast } from 'sonner';
import { Icons } from '../../../assets/icons';
import { routes } from '../../../utils/route';

const ChangePassword = () => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  const handleChange = (e) => {
    setPassword((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const HandleChangePassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await axiosPrivate.post(`user/change-password`, password);
      if (res && res.data) {
        setLoading(false);
        toast.success('Password change successful');
        navigate(routes.dashboard);
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.response?.data?.responseMessage || 'An error occurred');
    }
  };

  return (
    <div className="w-full max-w-sm mx-auto">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col items-center justify-center gap-2">
          <h4 className="text-2xl font-medium">Change Your Password</h4>
          <p className="text-sm font-normal">Let's get your password updated</p>
        </div>
        <div className="flex flex-col gap-3">
          <div className="w-full flex flex-col gap-1.5">
            <Label htmlFor="oldPassword">Old Password</Label>
            <Input
              id="oldPassword"
              type="password"
              placeholder="Enter password"
              className="h-12"
              autoComplete="off"
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className=" w-full flex flex-col gap-1.5">
            <Label htmlFor="newPassword">New Password</Label>
            <Input
              id="newPassword"
              type="password"
              placeholder="Enter password"
              className="h-12"
              autoComplete="off"
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className=" w-full flex flex-col gap-1.5">
            <Label htmlFor="confirmNewPassword">Confirm New Password</Label>
            <Input
              id="confirmNewPassword"
              type="password"
              placeholder="Enter password"
              className="h-12"
              autoComplete="off"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>

        <div className="w-full mt-3">
          <button
            onClick={(e) => HandleChangePassword(e)}
            className="bg-primary inline-flex items-center justify-center w-full p-3 text-sm font-medium text-white transition-colors rounded-lg whitespace-nowrap focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-[#8C93B4]"
          >
            {!loading ? (
              'Submit'
            ) : (
              <Icons.Spinner className="w-6 h-6 animate-spin" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
