import { useRoutes, useLocation } from 'react-router-dom';
import { Suspense, useContext, useEffect } from 'react';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { GlobalContext, GlobalContextType } from './context/GlobalContext';
import { appRoutes } from './utils/route';

const AppRoutes = () => {
  const element = useRoutes(appRoutes);
  return element;
};

function App() {
  const { dispatch } = useContext(GlobalContext) as GlobalContextType;
  const location = useLocation();

  useEffect(() => {
    // Determine dark mode based on route and localStorage
    const currentDarkMode =
      location.pathname !== '/' && localStorage.getItem('darkMode') === 'true';

    // Dispatch dark mode state
    dispatch({ type: 'TOGGLE_DARK_MODE', payload: currentDarkMode });

    // Toggle dark class on html element
    document.documentElement.classList.toggle('dark', currentDarkMode);
  }, [dispatch, location.pathname]);

  return (
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <Suspense fallback={null}>
        <AppRoutes />
      </Suspense>
    </CookiesProvider>
  );
}

export default App;
