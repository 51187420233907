import React from 'react';
import { Icons } from '../../assets/icons';
import Video from './Video';
import Footer from './Footer';
import Hero from './Hero';
import Navbar from './Navbar';
import ScrollToTop from './scrollToTop';

const Home = () => {
  const features = [
    {
      name: 'Push to deploy.',
      description:
        'We help developers and property owners sell projects and properties faster by offering either ROI or part ownership.',
      icon: Icons.biHouse,
    },
    {
      name: 'SSL certificates.',
      description:
        'We enable users to co-own properties from anywhere in the world.',
      icon: Icons.subwayWorld,
    },
    {
      name: 'Database backups.',
      description:
        'We offer Real-time monitoring and management of property portfolio.',
      icon: Icons.charts,
    },
    {
      name: 'Database backups.',
      description:
        'Musha offers only thoroughly verified properties, developers and owners.',
      icon: Icons.shieldHouse,
    },
  ];
  return (
    <div>
      <ScrollToTop />
      <Navbar />
      <Hero />
      <div className=" app-container">
        <div className="flex flex-col gap-3 text-primary">
          <img src="/Vector.png" alt="" className="w-20 h-3" />
          <h4 className="text-5xl font-bold">Experience Musha</h4>
          <p className="w-full max-w-6xl">
            Musha aims to break the monopoly of the wealthy in the real estate
            sector and make it accessible to everyone across Africa and beyond.
            You can ask the thousands of realtors using Musha or you can sign up
            yourself.
          </p>
        </div>
        <div className="grid items-center grid-cols-1 py-10 max-w-2x gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <dl className="max-w-xl mt-10 space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature, index) => (
                  <div
                    key={index}
                    className="relative flex items-center gap-8 pl-9"
                  >
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon
                        className="absolute w-12 h-10 left-1 top-1"
                        aria-hidden="true"
                      />
                    </dt>
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
            src="/exp-unsplash.jpg"
            alt="hero-img"
            className="w-full h-full shadow-xl max-w-none rounded-xl md:-ml-4 lg:-ml-0"
            width={432}
            height={442}
          />
        </div>
      </div>
      <Video />
      <Footer />
    </div>
  );
};

export default Home;
