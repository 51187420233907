import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export const TOKEN = {
  ACCESS: 'musha_token',
  REFRESH: 'musha_refresh_token',
  TOKENEXP: 'musha_token_expiry',
  ID: 'musha_userId',
  EMAIL: 'musha_email',
} as const;

export const getTokens = () => ({
  accessToken: cookies.get(TOKEN.ACCESS),
  refreshToken: cookies.get(TOKEN.REFRESH),
  tokenExpiry: cookies.get(TOKEN.TOKENEXP),
});

export const setTokens = (
  accessToken: string,
  refreshToken: string,
  tokenExpiry: string
) => {
  cookies.set(TOKEN.ACCESS, accessToken, { path: '/' });
  cookies.set(TOKEN.REFRESH, refreshToken, { path: '/' });
  cookies.set(TOKEN.TOKENEXP, tokenExpiry, { path: '/' });
};

export const clearTokens = () => {
  Object.values(TOKEN).forEach((tokenKey) => {
    cookies.remove(tokenKey, { path: '/' });
  });
};
