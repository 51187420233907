import React from 'react';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../ui/form';
import { useForm } from 'react-hook-form';
import PhoneNumber from '../telInput';
import { Icons } from '../../../../assets/icons';
import { Button } from '../../../ui/button';
import { Input } from '../../../ui/input';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { toast } from 'sonner';
import { getErrorMessage } from '../../../../utils/helper';
import { SectionEnum } from '../../../../pages/register/register';
import { useGetOtp } from '../../../../helpers/api/useAuth';
import { useCookies } from 'react-cookie';

type Props = {
  setLoading: (arg0: boolean) => void;
  isLoading: boolean;
  next: (arg0: SectionEnum) => void;
};

interface IContactAccount {
  firstname: string;
  lastname: string;
  contactPersonEmail: string;
  phone: string;
}

const ContactForm = (props: Props) => {
  const [cookies] = useCookies(['musha_email', 'musha_userId']);
  const { isLoading, setLoading, next } = props;
  const getOtp = useGetOtp();
  const axiosPrivate = useAxiosPrivate();
  const defaultContactValues: IContactAccount = {
    firstname: '',
    lastname: '',
    contactPersonEmail: '',
    phone: '',
  };

  const form = useForm({
    defaultValues: defaultContactValues,
  });

  const getCode = async (email: string) => {
    if (process.env.REACT_APP_ENV === 'development') {
      try {
        await axiosPrivate.get(`/user/temp-otp/${email}`);
      } catch (err: any) {
        console.error(
          err.response?.data?.responseMessage ?? 'An error occurred'
        );
      }
    }
  };

  const HandleContactRegister = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    form.handleSubmit(async (data) => {
      let payload = { ...data };
      setLoading(true);
      try {
        const [res] = await Promise.all([
          axiosPrivate.post(`user/complete-corporate-onboarding`, payload),
          getCode(cookies?.musha_email),
          getOtp.mutateAsync({ otpType: 'Phone' }), // Production OTP
        ]);

        if (res && res.data) {
          setLoading(false);
          toast.success('Company registration successful');
          // Call getCode for development OTP

          next(SectionEnum.Verification);
        }
      } catch (error: any) {
        const message = getErrorMessage(error);
        toast.error(message);
      }
      setLoading(false);
    })();
  };

  return (
    <Form {...form}>
      <form
        onSubmit={HandleContactRegister}
        className="flex flex-col w-full gap-2"
      >
        <div className="grid grid-cols-2 gap-4">
          <FormField
            name="firstname"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: 'First name is required',
              },
              validate: (value) =>
                value.trim() !== '' || 'First name cannot be empty',
            }}
            render={({ field }) => (
              <FormItem className="relative ">
                <FormLabel className="font-normal">First Name</FormLabel>
                <FormControl>
                  <Input
                    type="text"
                    autoComplete="off"
                    placeholder="Enter your first name"
                    error={Boolean(form.formState.errors.firstname)}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="lastname"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: 'Last name is required',
              },
              validate: (value) =>
                value.trim() !== '' || 'Last name cannot be empty',
            }}
            render={({ field }) => (
              <FormItem className="relative ">
                <FormLabel className="font-normal">Last Name</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    autoComplete="off"
                    type="text"
                    placeholder="Enter your last name"
                    error={Boolean(form.formState.errors.lastname)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormField
          name="contactPersonEmail"
          control={form.control}
          rules={{
            required: {
              value: true,
              message: 'Email is required',
            },
          }}
          render={({ field }) => (
            <FormItem className="relative ">
              <FormLabel className="font-normal">Email</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  type="email"
                  autoComplete="off"
                  placeholder="Enter your email"
                  error={Boolean(form.formState.errors.contactPersonEmail)}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <PhoneNumber form={form} />

        <Button className="mt-4 " disabled={isLoading}>
          {isLoading ? (
            <Icons.Spinner className="w-5 h-5 animate-spin" />
          ) : (
            'Next'
          )}
        </Button>
      </form>
    </Form>
  );
};
export default ContactForm;
