import React, { useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { Input } from '../../ui/input';
import { Button } from '../../ui/button';
import { Icons } from '../../../assets/icons';
import { IUser } from '../../../interface/auth/api';

interface UpdatePhoneNumberProps {
  onUpdate: () => void;
  onCancel: () => void;
  user: IUser | null;
}

const UpdatePhoneNumber: React.FC<UpdatePhoneNumberProps> = ({
  onUpdate,
  onCancel,
  user,
}) => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const axiosPrivate = useAxiosPrivate();

  const handleUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      await axiosPrivate.patch(`user/user`, {
        phone: phoneNumber,
        timeZone: user?.timeZone,
      });
      onUpdate();
    } catch (err: any) {
      setError(err.response?.data?.responseMessage || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleUpdate} className="space-y-4">
      <Input
        type="tel"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        placeholder="Enter new phone number"
        required
      />
      {error && <p className="text-sm text-red-500">{error}</p>}
      <div className="flex justify-between">
        <Button
          type="button"
          variant="outline"
          disabled={loading}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button type="submit" disabled={loading}>
          {loading ? (
            <>
              <Icons.Spinner className="w-4 h-4 animate-spin" /> Updating
            </>
          ) : (
            'Update'
          )}
        </Button>
      </div>
    </form>
  );
};

export default UpdatePhoneNumber;
