import React, { useEffect } from 'react';
import { Switch } from './switch';
import { useContext } from 'react';
import { GlobalContext, GlobalContextType } from '../../context/GlobalContext'; // Assuming ActionType is defined in your GlobalContext file

const ThemeSwitcher: React.FC = () => {
  const { isDarkmode, dispatch } = useContext(
    GlobalContext
  ) as GlobalContextType;

  const toggleDarkMode = () => {
    const updatedDarkMode = !isDarkmode;
    localStorage.setItem('darkMode', updatedDarkMode.toString());
    dispatch({ type: 'TOGGLE_DARK_MODE', payload: updatedDarkMode }); // Dispatch action to update global state
  };

  useEffect(() => {
    document.documentElement.classList.toggle('dark', isDarkmode);
  }, [isDarkmode]);

  return <Switch checked={isDarkmode} onCheckedChange={toggleDarkMode} />;
};

export default ThemeSwitcher;
