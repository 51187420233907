import { Check, Copy } from 'lucide-react';
import { Button } from '../../ui/button';
import { useReferralSummary } from '../../../helpers/api/useProfile';
import React, { useState } from 'react';
import ContainerLayout from '../../core/containerLayout';
import { QRCodeDialog } from './QrCodeDialog';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from '../../ui/skeleton';
import ErrorMessage from '../../ui/error-fallback';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

export default function ReferralDashboard() {
  const [isQROpen, setIsQROpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { data, isLoading, isError, errorMessage, refetch } =
    useReferralSummary();
  const referralLink = `${window.location.origin}/register?ref=${
    data?.refLink || ''
  }`;

  const [isCopied, setIsCopied] = useState<boolean>(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(referralLink);
      setIsCopied(true); // Show the tick icon
      setTimeout(() => setIsCopied(false), 3000); // Hide it after 3 seconds
    } catch (error) {
      console.error('Failed to copy referral link:', error);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  if (isLoading) {
    return (
      <ContainerLayout className="w-full">
        <div className="w-full max-w-3xl py-8 mx-auto space-y-8">
          <div className="w-full space-y-4">
            <Skeleton className="h-4 w-[200px]" />
            <Skeleton className="w-full h-10" />
            <Skeleton className="h-10 w-[150px]" />
          </div>

          <div className="w-full space-y-4">
            <Skeleton className="h-6 w-[250px]" />
            <div className="grid gap-4 sm:grid-cols-2">
              <Skeleton className="h-[120px] rounded-2xl" />
              <Skeleton className="h-[120px] rounded-2xl" />
            </div>
          </div>

          <div className="w-full space-y-4">
            <Skeleton className="h-6 w-[250px]" />
            <div className="grid gap-4 sm:grid-cols-2">
              <Skeleton className="h-[120px] rounded-2xl" />
              <Skeleton className="h-[120px] rounded-2xl" />
            </div>
          </div>

          <Skeleton className="w-full h-12" />
        </div>
      </ContainerLayout>
    );
  }

  if (isError) {
    return (
      <ErrorMessage
        message={errorMessage}
        refetch={() => {
          // document.location.reload();
          refetch();
        }}
      />
    );
  }
  return (
    <ContainerLayout className="w-full min-h-screen">
      <div className="max-w-3xl mx-auto space-y-8">
        <div className="space-y-2">
          <Button variant="ghost" className="p-0" onClick={handleBack}>
            <FontAwesomeIcon icon={faAngleLeft} />{' '}
            <h4 className="font-medium cursor-pointer dark:text-white text-steel-gray">
              {'Back'}
            </h4>
          </Button>
          <h1 className="md:text-[32px] font-medium sm:text-2xl dark:text-white text-steel-gray">
            Referrals Dashboard
          </h1>
        </div>

        <section className="space-y-4">
          <div className="space-y-2">
            <p className="text-base dark:text-foreground text-light-gray">
              Your referral link;
            </p>
            <div className="flex items-center gap-2">
              <p className="truncate w-72 dark:text-white text-steel-gray">
                {referralLink}
              </p>
              <Button
                variant="ghost"
                size="icon"
                onClick={handleCopy}
                className="rounded-full"
              >
                {isCopied ? (
                  <Check className="w-5 h-5 text-green-500" />
                ) : (
                  <Copy className="w-5 h-5" />
                )}
              </Button>
            </div>
          </div>

          <p className="text-base dark:text-foreground text-light-gray">
            Or click to scan you QR code
          </p>
          <Button onClick={() => setIsQROpen(true)} className="text-white">
            Get QR Code
          </Button>
        </section>
        <hr className="w-full" />
        <section className="space-y-4">
          <h2 className="text-2xl font-semibold dark:text-white text-steel-gray">
            Your Referral Count
          </h2>
          <p className="text-base dark:text-foreground text-light-gray">
            Here is an overview of all your earnings from your referrals.
          </p>
          <div className="grid max-w-md gap-6 sm:grid-cols-2">
            <div className="p-6 bg-[#FAFAFA] dark:bg-[#212121] shadow-sm rounded-2xl">
              <div className="text-center">
                <p className="text-[48px] font-semibold dark:text-white text-primary">
                  {data?.corporateCount || 0}
                </p>
                <p className="text-base dark:text-white text-steel-gray">
                  Corporate Referrals
                </p>
              </div>
            </div>
            <div className="p-6 bg-[#FAFAFA] dark:bg-[#212121] shadow-sm rounded-2xl">
              <div className="text-center">
                <p className="text-[48px] font-semibold dark:text-white text-primary">
                  {data?.individualCount || 0}
                </p>
                <p className="text-base dark:text-white text-steel-gray">
                  Individual Referrals
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="space-y-4">
          <h2 className="text-2xl font-semibold dark:text-white text-steel-gray">
            Your Referral Earnings
          </h2>
          <p className="text-base dark:text-foreground text-light-gray">
            Here is an overview of all your earnings from your referrals.
          </p>
          <div className="grid max-w-md gap-6 sm:grid-cols-2">
            <div className="bg-[#FAFAFA] dark:bg-[#212121] shadow-sm rounded-2xl">
              <div className="text-center bg-[#FAFAFA] dark:bg-[#212121] shadow-sm rounded-2xl">
                <div className="border-b">
                  <p className="text-[40px] p-5 font-semibold dark:text-white text-primary">
                    ₦ {data?.corporateEarnings?.toLocaleString() || 0}
                  </p>
                </div>
                <p className="p-5 text-base dark:text-white text-steel-gray">
                  Corporate Earnings
                </p>
              </div>
            </div>
            <div className="text-center bg-[#FAFAFA] dark:bg-[#212121] shadow-sm rounded-2xl">
              <div className="border-b">
                <p className="text-[40px] p-5 font-semibold dark:text-white text-primary">
                  ₦ {data?.individualEarnings?.toLocaleString() || 0}
                </p>
              </div>
              <p className="p-5 text-base dark:text-white text-steel-gray">
                Individual Earnings
              </p>
            </div>
          </div>
        </section>

        <p className="text-sm text-[#F04438]">
          NB: This shows the accrued amount for user who have completed a
          purchase and companies who have had their assets sold.
        </p>
        <hr className="w-full" />
        <div className="space-y-4">
          <p className="text-sm dark:text-foreground text-light-gray">
            To initiate a payout request, Click on the button below;
          </p>
          <Button className="w-full max-w-md py-6 sm:text-lg" disabled>
            Request Payout
          </Button>
        </div>
      </div>

      <QRCodeDialog
        isOpen={isQROpen}
        onOpenChange={setIsQROpen}
        referralLink={referralLink}
      />
    </ContainerLayout>
  );
}
