import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from '../../ui/input';
import { Button } from '../../ui/button';
import { useLogout } from '../../../helpers/api/useAuth';
import { cn } from '../../../lib/utils';
import { useCallback, useContext, useEffect, useState } from 'react';
import _debounce from 'lodash/debounce';
import { HamburgerMenuIcon, MoonIcon, SunIcon } from '@radix-ui/react-icons';
import {
  GlobalContext,
  GlobalContextType,
} from '../../../context/GlobalContext';
import React from 'react';
import { Avatar, AvatarFallback, AvatarImage } from '../../ui/avatar';
import { IUser } from '../../../interface/auth/api';
import { Icons } from '../../../assets/icons';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../ui/select';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../../ui/dropdown-menu';
import { routes } from '../../../utils/route';

const Header = ({ user }: { user: IUser }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const [, setExpandSearch] = useState<Boolean>(false);
  const [showSearch, setShowSearch] = useState<Boolean>(false);
  const { logoutUser: logout } = useLogout();
  const { isDarkmode, dispatch } = useContext(
    GlobalContext
  ) as GlobalContextType;
  const [selectedValue, setSelectedValue] = useState<string>(
    searchParams.get('category') || ''
  );
  const [inputValue, setInputValue] = useState<string>(
    searchParams.get('query') || ''
  );

  let profilePicture =
    user && user.profilePicture
      ? user.profilePicture
      : '/assets/images/profileImagePlaceholder.png';

  const toggleDarkMode = () => {
    const updatedDarkMode = !isDarkmode;
    localStorage.setItem('darkMode', updatedDarkMode.toString());
    dispatch({ type: 'TOGGLE_DARK_MODE', payload: updatedDarkMode }); // Dispatch action to update global state
  };

  useEffect(() => {
    if (pathname !== routes.market) {
      setInputValue('');
    }
  }, [pathname]);

  useEffect(() => {
    document.documentElement.classList.toggle('dark', isDarkmode);
  }, [isDarkmode]);

  // Create a memoized debounced search function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    _debounce((value: string, category: string) => {
      if (location.pathname !== routes.market) {
        navigate(
          `${routes.market}?query=${encodeURIComponent(
            value
          )}&category=${category}`
        );
      } else {
        setSearchParams({ query: value, category });
      }
    }, 300),
    [location.pathname, navigate, setSearchParams]
  );

  // Modify input change handler to pass both value and category
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    debouncedSearch(value, selectedValue);
  };

  // Modify select change handler to trigger search if input exists
  const handleSelectChange = (value: string) => {
    setSelectedValue(value);
    if (inputValue) {
      debouncedSearch(inputValue, value);
    }
  };

  const arrayOfObjects = [
    {
      value: 'availableUnits',
      label: 'Available Units',
    },
    {
      value: 'state',
      label: 'State',
    },
    {
      value: 'unitPrice',
      label: 'Unit Price',
    },
    {
      value: 'title',
      label: 'Title',
    },
    {
      value: 'lga',
      label: 'LGA',
    },
    {
      value: 'fullAddress',
      label: 'Full Address',
    },
  ];

  const bottomLinks = [
    {
      link: routes.dashboard,
      text: 'Home',
      icon: <Icons.HomeIcon className="w-4 h-4" />,
    },
    {
      link: routes.market,
      text: 'Market',
      icon: <Icons.MarketIcon className="w-4 h-4" />,
    },
    {
      link: routes.profile,
      icon: <Icons.ProfileIcon className="w-4 h-4" />,
      text: 'Profile',
    },
  ];

  if (user?.accountType.toLowerCase() === 'corporate') {
    bottomLinks.push({
      link: routes.addAsset,
      text: 'Add Asset',
      icon: <Icons.AssetIcon className="w-4 h-4" />,
    });
  } else {
    bottomLinks.push({
      link: routes.myAsset,
      text: 'My Asset',
      icon: <Icons.HouseIcon className="w-4 h-4" />,
    });
  }

  const isActiveLink = (currentLink: string, currentPathname: string) => {
    // Get the segments of the current link and current pathname
    const linkSegments = currentLink
      .split('/')
      .filter((segment) => segment !== '');
    const pathnameSegments = currentPathname
      .split('/')
      .filter((segment) => segment !== '');

    // Compare each segment of the current link with the corresponding segment of the current pathname
    for (let i = 0; i < linkSegments.length; i++) {
      if (pathnameSegments[i] !== linkSegments[i]) {
        return false; // Sub page doesn't match, link is not active
      }
    }

    return true; // All segments match, link is active
  };

  return (
    <>
      <header className="sticky top-0 left-0 z-[999] py-4 w-[90%] md:w-[93%] lg:w-[96%] mx-auto flex flex-col gap-4 bg-white/80 dark:bg-[#171717]/80 backdrop-blur-sm">
        <div className="flex items-center justify-between gap-6 lg:justify-end">
          <div
            className="flex items-start cursor-pointer lg:hidden"
            onClick={() => navigate(routes.profile)}
          >
            <Link to="/">
              <img
                className="hidden object-contain w-10 h-14"
                src="/LOGO.png"
                alt="Logo"
              />
            </Link>
            <hr className="w-[1px] h-16 hidden bg-primary-100" />
            <div className="flex items-center gap-2 mt-1 ">
              <Avatar>
                <AvatarImage
                  src={profilePicture}
                  className="object-cover border"
                />
                <AvatarFallback>
                  {user?.firstname?.charAt(0) + user?.lastname?.charAt(0)}
                </AvatarFallback>
              </Avatar>
              <div className="flex flex-col gap-0">
                <p className="text-xs text-light-grey">Hello</p>
                <h3 className="text-sm font-medium">{user?.firstname}</h3>
              </div>
            </div>
          </div>
          <div className="items-center hidden gap-4 bg-transparent md:flex">
            <div className="relative flex items-center w-full">
              <Input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                className={cn('w-96 rounded-l-full h-12 pl-5')}
                placeholder="Search for assets"
                endIcon={
                  <FontAwesomeIcon
                    className="z-20 mr-2 cursor-pointer"
                    icon={faMagnifyingGlass}
                    onClick={() => {
                      setExpandSearch((prevExpandSearch) => !prevExpandSearch);
                    }}
                  />
                }
              />
              <Select value={selectedValue} onValueChange={handleSelectChange}>
                <SelectTrigger className="h-12 border text-xs font-medium rounded-r-full w-full min-w-40 py-2.5 px-4 relative focus:outline-none">
                  <SelectValue placeholder="Select category" />
                </SelectTrigger>
                <SelectContent>
                  {arrayOfObjects?.map((item: any, index: number) => (
                    <SelectItem key={index} value={item.value}>
                      {item?.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <Button
              onClick={toggleDarkMode}
              className="bg-[#F2F2F2] dark:bg-[#111113] hover:bg-opacity-30 rounded-2xl w-12 h-12 flex items-center shadow-none"
            >
              {isDarkmode ? (
                <SunIcon className="text-gray-900 dark:text-white" />
              ) : (
                <MoonIcon className="text-gray-900 dark:text-white" />
              )}
            </Button>
            <Button
              onClick={logout}
              variant="destructive"
              className="flex items-center w-12 h-12 p-0 px-3 shadow-none bg-red-100/50 dark:bg-red-100/50 hover:bg-red-200 rounded-2xl"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                id="logout"
                className="w-7 h-7 fill-destructive"
              >
                <path d="M12.59,13l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76,1,1,0,0,0-.21-.33l-4-4a1,1,0,1,0-1.42,1.42L12.59,11H3a1,1,0,0,0,0,2ZM12,2A10,10,0,0,0,3,7.55a1,1,0,0,0,1.8.9A8,8,0,1,1,12,20a7.93,7.93,0,0,1-7.16-4.45,1,1,0,0,0-1.8.9A10,10,0,1,0,12,2Z"></path>
              </svg>
            </Button>
          </div>
          <div className="flex items-center gap-2 bg-transparent md:hidden">
            <FontAwesomeIcon
              className="z-20 mr-2 cursor-pointer"
              icon={faMagnifyingGlass}
              onClick={() => {
                setShowSearch((prevSearch) => !prevSearch);
              }}
            />
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button className="bg-[#F2F2F2] dark:bg-[#111113] hover:bg-opacity-30 rounded-xl w-10 h-10 p-1 flex items-center shadow-none">
                  <HamburgerMenuIcon className="text-gray-900 dark:text-white" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56">
                <DropdownMenuLabel>Appearance</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  onClick={toggleDarkMode}
                  className="gap-2 p-4 "
                >
                  {isDarkmode ? (
                    <span className="flex items-start w-full gap-3">
                      <SunIcon className="text-gray-900 dark:text-white" />
                      <span className="text-xs text-white">Light</span>
                    </span>
                  ) : (
                    <span className="flex items-start w-full gap-3">
                      <MoonIcon className="text-gray-900 dark:text-white" />
                      <span className="text-xs text-gray-900">Dark</span>
                    </span>
                  )}
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="gap-2 p-4 bg-transparent text-destructive"
                  onClick={logout}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    id="logout"
                    className="w-5 h-5 fill-destructive"
                  >
                    <path d="M12.59,13l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76,1,1,0,0,0-.21-.33l-4-4a1,1,0,1,0-1.42,1.42L12.59,11H3a1,1,0,0,0,0,2ZM12,2A10,10,0,0,0,3,7.55a1,1,0,0,0,1.8.9A8,8,0,1,1,12,20a7.93,7.93,0,0,1-7.16-4.45,1,1,0,0,0-1.8.9A10,10,0,1,0,12,2Z"></path>
                  </svg>{' '}
                  <p>Logout</p>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
        <Input
          type="search"
          value={inputValue}
          onChange={handleInputChange}
          className={cn(
            'bg-[#F2F2F2] rounded-xl h-11 transition-all duration-300',
            {
              ' w-full transition-all duration-300 block md:hidden': showSearch,
            },
            {
              ' transition-all duration-300 w-0 hidden': !showSearch,
            }
          )}
          placeholder="Search for assets"
        />{' '}
      </header>
      <ul className="fixed bottom-0 lg:hidden z-50 left-[50%] lg:left-[unset] translate-x-[-50%] bg-white dark:bg-[#111113]/80 backdrop-blur-sm flex w-full px-[5%] items-center justify-between lg:justify-start gap-4 border-t dark:border-[#3b3a3a] border-tint-300">
        {bottomLinks.map((link, index) => (
          <Link to={link.link} key={index}>
            <li
              className={cn(
                'py-2 flex items-center flex-col gap-[8px] text-[#BEBEBE]',
                {
                  'text-primary':
                    pathname === link.link || isActiveLink(link.link, pathname),
                }
              )}
            >
              {link.icon}
              <p className="text-xs leading-[150%] tracking-[-0.3px]">
                {link.text}
              </p>

              {isActiveLink(link.link, pathname) && (
                <div className="bg-primary w-[66px] h-[2px] absolute top-0" />
              )}
            </li>
          </Link>
        ))}
      </ul>
    </>
  );
};

export default Header;
