import Footer from './Footer';
import Nav from './Navbar';
import { useEffect } from 'react';

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="">
      <Nav />
      <main className="app-container">
        <div className="lg:py-16 md:py-12 py-9">
          <div className="flex flex-col justify-between gap-8 lg:flex-row">
            <div className="flex flex-col justify-center w-full lg:w-5/12">
              <h1 className="pb-4 text-3xl font-bold leading-9 text-gray-800 lg:text-4xl">
                Just a little story about Musha...
              </h1>
              <p className="text-base font-normal leading-6 text-gray-600 ">
                Founded with a vision to revolutionize the way assets are bought
                and sold, our journey began with a simple idea: to create a
                platform where individuals and businesses could easily transact
                with confidence and security. Since our inception, we've been
                committed to providing a seamless experience for our users,
                constantly innovating and adapting to meet their evolving needs.
                Today, we're proud to be a leading destination for asset
                trading, trusted by thousands of users worldwide. Our story is
                one of growth, innovation, and above all, dedication to our
                users.
              </p>
            </div>
            <div className="w-full lg:w-8/12 ">
              <img
                className="object-cover w-full h-full"
                src="https://i.ibb.co/FhgPJt8/Rectangle-116.png"
                alt="A group of People"
              />
            </div>
          </div>

          <div className="flex flex-col justify-between gap-8 pt-12 lg:flex-row">
            <div className="w-full lg:w-8/12">
              <img
                className="w-full h-full rounded-lg"
                src="/ritchie-valens-6fxiPO6bPpM-unsplash.jpg"
                alt="table"
              />
            </div>
            <div className="flex flex-col justify-center w-full lg:w-5/12">
              <h1 className="pb-4 text-3xl font-bold leading-9 text-gray-800 lg:text-4xl">
                The future holds so much more...
              </h1>
              <p className="text-base font-normal leading-6 text-gray-600 ">
                Welcome to Musha, where we connect buyers and sellers of various
                assets in a seamless and secure environment. At our core, we
                believe in empowering individuals and businesses alike to unlock
                the full potential of their assets, whether it's through
                selling, trading, or discovering new opportunities. Our
                user-friendly interface, coupled with cutting-edge security
                measures, ensures a hassle-free experience for all our users.
                Join us today and become part of a thriving community dedicated
                to maximizing the value of assets worldwide.
              </p>
            </div>
          </div>
        </div>
      </main>{' '}
      <Footer />
    </div>
  );
};

export default About;
