import React, { useEffect } from 'react';
import Footer from './Footer';
import Nav from './Navbar';
import { Link } from 'react-router-dom';
import { routes } from '../../utils/route';

const RefundPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Nav />
      <main className="flex flex-col gap-4 app-container">
        <section className="py-4 space-y-4 sm:py-6 sm:space-y-0">
          <h4 className="text-2xl md:text-6xl font-bold text-[#04093F] leading-none md:leading-[80px]">
            Our Refund Policy
          </h4>
          <p className="text-[15px] text-[#04093F]">
            Understand the terms under which refunds are processed at Musha.
          </p>
        </section>
        <section className="pb-4 space-y-4">
          <p>
            At Musha, we strive to ensure that our users have a smooth and
            transparent experience. Please review our refund policy carefully to
            understand the conditions under which refunds are granted.
          </p>

          <h2 className="mb-2 text-xl font-semibold">
            1. No Refunds on Completed Transactions
          </h2>
          <p>
            All completed transactions on the Musha platform are non-refundable.
            Once a transaction has been finalized, funds cannot be reversed or
            refunded.
          </p>

          <h2 className="mb-2 text-xl font-semibold">
            2. Refund Requests on Exceptional Grounds
          </h2>
          <p>
            Refunds may be considered in exceptional cases, such as technical
            errors or unauthorized transactions. Refund requests must be
            submitted within 14 days of the transaction, and will be reviewed on
            a case-by-case basis by our support team.
          </p>

          <h2 className="mb-2 text-xl font-semibold">
            3. Refund Review Process
          </h2>
          <ul className="mb-4 list-decimal list-inside">
            <li>
              To initiate a refund request, please contact our support team at{' '}
              <a href="mailto:support@mushaapp.com" className="text-blue-500">
                support@mushaapp.com
              </a>{' '}
              with detailed information regarding the transaction.
            </li>
            <li>
              The refund request will be reviewed, and Musha will communicate
              the decision to approve or deny the refund within 4-5 business
              days.
            </li>
          </ul>

          <h2 className="mb-2 text-xl font-semibold">
            4. Processing Time for Approved Refunds
          </h2>
          <p>
            If a refund request is approved, Musha will process the refund to
            the original payment method used for the transaction within 3
            business days. However, the processing time may vary based on the
            policies of the respective financial institution.
          </p>

          <h2 className="mb-2 text-xl font-semibold">
            5. Cancellation of Ongoing Transactions
          </h2>
          <p>
            Transactions that are still in progress (such as pending investments
            or uncompleted transfers) can be canceled within 24 hours. Once the
            transaction is completed, it will fall under the non-refundable
            policy.
          </p>

          <h2 className="mb-2 text-xl font-semibold">6. Moratorium Period</h2>
          <p>
            If applicable, users must wait until the end of a designated
            moratorium period before reselling assets. Refunds will not be
            granted for changes of mind or dissatisfaction with a property's
            performance.
          </p>

          <h2 className="mb-2 text-xl font-semibold">7. Fraud and Misuse</h2>
          <p>
            Any suspected fraudulent activity or misuse of the Musha platform
            will result in the immediate suspension of the account, and any
            refunds will be subject to investigation. Musha reserves the right
            to deny refunds in cases of fraudulent transactions or violation of
            terms.
          </p>

          <h2 className="mb-2 text-xl font-semibold">8. Contact Information</h2>
          <p>
            For any questions regarding this refund policy or to submit a refund
            request, please contact our customer service team at:
          </p>
          <ul className="list-none">
            <li>
              Email:{' '}
              <a href="mailto:support@mushaapp.com" className="text-blue-500">
                support@mushaapp.com
              </a>
            </li>
            <li>
              WhatsApp:{' '}
              <Link to={routes.contactUs} className="text-blue-500 underline">
                Contact us
              </Link>
            </li>
            <li>Business Hours: Monday – Friday, 9:00 AM to 5:00 PM (WAT)</li>
          </ul>

          <h2 className="mt-4 text-lg font-bold">Note:</h2>
          <p>
            Musha reserves the right to update or modify this refund policy at
            any time without prior notice. Any changes will be sent to users via
            their registered emails. It is also the responsibility of users to
            review the policy periodically.
          </p>

          <p>
            By using Musha’s platform, you agree to this refund policy and the
            terms set forth herein.
          </p>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default RefundPolicy;
