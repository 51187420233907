import * as React from 'react';
import {
  CaretSortIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DotsHorizontalIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from '@radix-ui/react-icons';
import {
  ColumnDef,
  ColumnFiltersState,
  FilterFn,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { rankItem } from '@tanstack/match-sorter-utils';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { Input } from '../ui/input';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table';
import { Button } from '../ui/button';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { CurrencyFormat } from '../../utils/helper';
import { routes } from '../../utils/route';

export interface IOrderHistory {
  id: number;
  userId: number;
  assetId: number;
  units: number;
  convertedAmount: number;
  amount: number;
  status: string;
  assetTitle: string;
  name: string;
  paymentReference: string;
  paymentProcessorId: number;
  exchangeRateId: number;
  paymentDate: null;
  dateUpdated: null;
  dateCreated: Date;
  feeConfigId: number;
  paymentProcessor: string;
  exchangeRate: number;
  feeConfig: string;
  isAgentCommissionFeeFactored: boolean;
}

export function OrderHistory({
  history,
  loading,
}: {
  history: IOrderHistory[];
  loading: boolean;
}) {
  const data: IOrderHistory[] = history;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const navigate = useNavigate();
  const columns: ColumnDef<any>[] = [
    {
      accessorKey: 'paymentReference',
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            className="px-0"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Payment reference
            <CaretSortIcon className="w-4 h-4 ml-2" />
          </Button>
        );
      },
      cell: ({ row }) => (
        <span className="flex-shrink-0 pl-2">
          {row.getValue('paymentReference') || '--'}
        </span>
      ),
    },
    {
      accessorKey: 'assetTitle',
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            className="px-0"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Asset title
            <CaretSortIcon className="w-4 h-4 ml-2" />
          </Button>
        );
      },
      cell: ({ row }) => (
        <span className="flex-shrink-0">{row.getValue('assetTitle')}</span>
      ),
    },
    {
      accessorKey: 'units',
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            className="px-0"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Units
            <CaretSortIcon className="w-4 h-4 ml-2" />
          </Button>
        );
      },
      cell: ({ row }) => (
        <span className="flex-shrink-0 pl-5 lowercase">
          {row.getValue('units')}
        </span>
      ),
    },
    {
      accessorKey: 'amount',
      header: ({ column }) => (
        <div
          className={
            'text-nowrap text-xs font-medium uppercase md:w-full w-40 break-normal whitespace-pre flex-shrink-0 '
          }
        >
          Amount
        </div>
      ),
      cell: ({ row }) => (
        <CurrencyFormat value={row.getValue('amount') || 0} currency={'NGN'} />
      ),
    },
    {
      accessorKey: 'status',
      header: ({ column }) => (
        <div
          className={
            'text-nowrap text-xs font-medium uppercase md:w-full w-40 break-normal whitespace-pre flex-shrink-0 '
          }
        >
          Status
        </div>
      ),
      cell: ({ row }) => (
        <span className="flex-shrink-0 capitalize">
          {row.getValue('status')}
        </span>
      ),
    },
    {
      accessorKey: 'paymentDate',
      header: ({ column }) => (
        <div
          className={
            'text-nowrap text-xs font-medium uppercase md:w-full w-40 break-normal whitespace-pre flex-shrink-0 '
          }
        >
          Date purchased
        </div>
      ),
      cell: ({ row }) => {
        const paymentDate = row.getValue('paymentDate') ?? '';
        // Ensure paymentDate is a valid string or number before converting to Date
        const formatted = paymentDate
          ? new Date(paymentDate as string | number).toLocaleString()
          : '--';
        return <span className="flex-shrink-0">{formatted}</span>;
      },
    },
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }) => {
        const payment = row.original;
        const handleCopyClick = () => {
          // Copy the value of the password input field to the clipboard using the Clipboard API
          if (payment.paymentReference) {
            navigator.clipboard
              .writeText(payment.paymentReference)
              .then(() => {
                toast.success('Reference has been copied to the clipboard!');
                return true; // Return a value
              })
              .catch((error) => {
                console.error('Unable to copy text: ', error);
                throw error; // Throw an error in case of failure
              });
          }
        };
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="w-8 h-8 p-0">
                <span className="sr-only">Open menu</span>
                <DotsHorizontalIcon className="w-4 h-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>Actions</DropdownMenuLabel>
              <DropdownMenuItem onClick={handleCopyClick}>
                Copy payment reference
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => navigate(`${routes.asset}/${payment?.assetId}`)}
              >
                View asset
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});
  const [globalFilter, setGlobalFilter] = React.useState('');
  const fuzzyFilter: FilterFn<any> = (
    row: { getValue: (arg0: any) => any },
    columnId: any,
    value: any,
    addMeta: (arg0: { itemRank: any }) => void
  ) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);

    // Store the itemRank info
    addMeta({
      itemRank,
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
  };

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    globalFilterFn: fuzzyFilter,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      globalFilter,
    },
  });

  return (
    <div className="w-full min-h-[70vh]">
      <div className="flex items-center py-4">
        <Input
          placeholder="Search ..."
          value={globalFilter ?? ''}
          onChange={(event) => table.setGlobalFilter(event.target.value)}
          className="max-w-sm rounded-xl"
        />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="outline"
              className="hidden ml-auto rounded-xl md:flex"
            >
              View <ChevronDownIcon className="w-4 h-4 ml-2" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={(value) =>
                      column.toggleVisibility(!!value)
                    }
                  >
                    {column.id}
                  </DropdownMenuCheckboxItem>
                );
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="border rounded-xl">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {loading ? (
              Array.from(Array(10).keys()).map((id) => (
                <TableRow key={id}>
                  {Array.from(Array(7).keys()).map((row) => (
                    <TableCell key={row} className="px-3 py-[26px]">
                      <div className="w-full h-4 rounded-full animate-pulse bg-muted" />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  className="flex-shrink-0 "
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className="flex-shrink-0 ">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="text-center h-96 md:h-[500px]"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end py-4 space-x-2">
        <div className="flex-1 text-sm text-muted-foreground">
          Page {table.getState().pagination.pageIndex + 1} of{' '}
          {table.getPageCount()}
        </div>
        <div className="flex items-center space-x-2">
          <Button
            variant="outline"
            className="hidden w-8 h-8 p-0 lg:flex"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            <span className="sr-only">Go to first page</span>
            <DoubleArrowLeftIcon className="w-4 h-4" />
          </Button>
          <Button
            variant="outline"
            className="w-8 h-8 p-0"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <span className="sr-only">Go to previous page</span>
            <ChevronLeftIcon className="w-4 h-4" />
          </Button>
          <Button
            variant="outline"
            className="w-8 h-8 p-0"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <span className="sr-only">Go to next page</span>
            <ChevronRightIcon className="w-4 h-4" />
          </Button>
          <Button
            variant="outline"
            className="hidden w-8 h-8 p-0 lg:flex"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            <span className="sr-only">Go to last page</span>
            <DoubleArrowRightIcon className="w-4 h-4" />
          </Button>
        </div>
      </div>
    </div>
  );
}
