import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../ui/button';
import { Form } from '../../ui/form';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { toast } from 'sonner';
import { IUser } from '../../../interface/auth/api';
import { useLogout } from '../../../helpers/api/useAuth';

interface ResetPasswordFormProps {
  user: IUser;
}

export const ResetPassword: React.FC<ResetPasswordFormProps> = ({ user }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const axiosPrivate = useAxiosPrivate();
  const { logoutUser: logout } = useLogout();
  const form = useForm({});

  const handleResetPassword = async () => {
    setLoading(true);
    try {
      const res = await axiosPrivate.post(`user/reset-password`, {
        emailAddress: user.email,
      });
      if (res && res.data) {
        setLoading(false);
        toast.success(res.data.data || 'A reset email has been sent to you');
        // Assuming you have a logout function
        await logout();
      }
    } catch (err: any) {
      setLoading(false);
      toast(err.response?.data?.responseMessage || 'An error occurred');
    }
  };

  return (
    <Form {...form}>
      <form
        className="flex flex-col w-full gap-4"
        onSubmit={form.handleSubmit(handleResetPassword)}
      >
        <p className="text-sm text-muted-foreground">
          A password reset link will be sent to: <strong>{user.email}</strong>
        </p>

        <Button
          type="submit"
          size="lg"
          className="w-full mt-2"
          disabled={loading}
        >
          {loading ? 'Sending...' : 'Reset Password'}
        </Button>
      </form>
    </Form>
  );
};
