import {
  createContext,
  useEffect,
  useReducer,
  ReactNode,
  Dispatch,
} from 'react';
import React from 'react';

interface User {
  // Replace 'any' with the actual type of your user object
}

interface AuthState {
  user: User | null;
  loading: boolean;
  error: string | null;
}

interface AuthAction {
  type: string;
  payload?: User | string; // Replace 'any' with the actual type of your payload
}

interface AuthContextType {
  user: User | null;
  loading: boolean;
  error: string | null;
  dispatch: Dispatch<AuthAction>;
}

const INITIAL_STATE: AuthState = {
  user: null,
  loading: false,
  error: null,
};

export const AuthContext = createContext<AuthContextType | null>(null);

const AuthReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case 'LOGIN_START':
      return {
        user: null,
        loading: true,
        error: null,
      };
    case 'LOGIN_SUCCESS':
      return {
        user: action.payload as User,
        loading: false,
        error: null,
      };
    case 'LOGIN_FAIL':
      return {
        user: null,
        loading: false,
        error: action.payload as string,
      };
    case 'LOGOUT':
      return INITIAL_STATE;
    default:
      return state;
  }
};

interface AuthContextProviderProps {
  children: ReactNode;
}

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);

  useEffect(() => {
    if (state.user) {
      localStorage.setItem('user', JSON.stringify(state.user));
    } else {
      localStorage.removeItem('user');
    }
  }, [state.user]);

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        loading: state.loading,
        error: state.error,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
