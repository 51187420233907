import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../components/core/logo';
import CreateAccount from '../../components/auth/createAccount/Register';
import RequiredSteps from '../../components/auth/createAccount/RequiredSteps';
import ProfilePicture from '../../components/auth/createAccount/ProfilePicture';
import Verification from '../../components/auth/createAccount/Verification';
import ChangePassword from '../../components/auth/createAccount/ChangePassword';
import ForgotPassword from '../../components/auth/createAccount/ForgotPassword';
import FileUpload from '../../components/auth/createAccount/FileUpload';
import { toast } from 'sonner';
import React from 'react';
import { getBase64 } from '../../utils/helper';
import { cn } from '../../lib/utils';
import { useGetRequiredDocuments } from '../../helpers/api/useAuth';

export enum SectionEnum {
  Register = 'register',
  Verification = 'verification',
  ChangePassword = 'changePassword',
  ForgotPassword = 'forgotPassword',
  RequiredSteps = 'requiredSteps',
  ProfilePicture = 'profilePicture',
  FileUpload = 'fileUpload',
}

const Register = () => {
  const location = useLocation();
  const [section, setSection] = useState<SectionEnum>(SectionEnum.Register);
  const navigate = useNavigate();
  const { data, isLoading, isError, errorMessage } = useGetRequiredDocuments(
    section === SectionEnum.RequiredSteps
  );
  const requiredDocuments = data?.data;

  const documents = requiredDocuments?.documents || [];

  const optionalDoc = documents.filter(
    (item: { isRequired: boolean }) => !item.isRequired
  );

  const reqDocuments = documents.filter(
    (item: { isRequired: boolean }) => item.isRequired
  );

  const requiredOptional = Number(requiredDocuments?.requiredOptional);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
    }
  }, [isError, errorMessage]);

  useEffect(() => {
    if (
      location?.state?.section &&
      Object.values(SectionEnum).includes(location?.state?.section)
    ) {
      setSection(location.state.section as SectionEnum);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section]);

  const next = (id: SectionEnum): void => {
    setSection(id);
    navigate('/register', { state: { section: id } });
  };

  return (
    <div
      className={cn(
        'sm:bg-[#f9fafb] dark:bg-[#171717] h-screen overflow-auto pt-20 pb-20 w-full',
        section !== 'register' && 'flex flex-col items-center justify-center'
      )}
    >
      <div className="flex flex-col items-center justify-center max-w-[459px] w-full gap-3 px-4 sm:p-8 mx-auto rounded-xl  dark:bg-transparent sm:bg-white">
        <Link to="/">
          <Logo type="icon" className="w-10 h-16" />
        </Link>
        {section === 'register' && <CreateAccount next={next} />}
        {section === 'verification' && <Verification next={next} />}
        {section === 'changePassword' && <ChangePassword />}
        {section === 'forgotPassword' && <ForgotPassword />}
        {section === 'requiredSteps' && (
          <RequiredSteps
            next={next}
            isLoading={isLoading}
            optionalDoc={optionalDoc}
            reqDocuments={reqDocuments}
            requiredOptional={requiredOptional}
          />
        )}

        {section === 'profilePicture' && (
          <ProfilePicture next={next} getBase64={getBase64} />
        )}
        {section === 'fileUpload' && (
          <FileUpload next={next} getBase64={getBase64} />
        )}
        {/* <div id="moreSteps" className="section">
          <MoreSteps next={next} />
        </div>
        <div id="selectedAsset" className="section">
          <SelectAsset next={next} />
        </div>
        <div id="selectedAsset" className="section">
          <SelectPrice next={next} />
        </div>
        <div id="selectedAsset" className="section">
          <SelectState next={next} />
        </div> */}
      </div>
    </div>
  );
};

export default Register;
