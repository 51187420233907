import React, { useEffect } from 'react';
import { NetworkErrorIcon } from '../../assets/networkError';
import { useLogout } from '../../helpers/api/useAuth';
import { Button } from './button';
import { ArrowDownIcon, ReloadIcon } from '@radix-ui/react-icons';
import { LogOutIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface ErrorMessageProps {
  message: string;
  refetch: () => void;
  isLogout?: boolean;
  permissionDenied?: boolean;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({
  message,
  refetch,
  isLogout,
  permissionDenied,
}) => {
  const { logoutUser: logout } = useLogout();
  const navigate = useNavigate();
  useEffect(() => {
    if (permissionDenied) {
      const timeoutId = setTimeout(() => {
        logout();
      }, 3000); // 3 seconds in milliseconds

      // Cleanup function to clear timeout on toast close (optional)
      return () => {
        clearTimeout(timeoutId);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionDenied]);

  if (permissionDenied) {
    return (
      <div className="flex flex-col items-center justify-center h-[75vh] gap-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enableBackground="new 0 0 32 32"
          viewBox="0 0 32 32"
          id="view-error"
          className="w-32 h-32"
        >
          <path
            d="M16.857,10.131C16.941,10.407,17,10.695,17,11c0,1.657-1.343,3-3,3s-3-1.343-3-3s1.343-3,3-3
	c0.305,0,0.593,0.059,0.869,0.143C14.357,8.382,14,8.898,14,9.5c0,0.828,0.672,1.5,1.5,1.5C16.102,11,16.618,10.643,16.857,10.131z"
          ></path>
          <path
            d="M17.667,17.494l2.206-3.769C20.322,12.958,21.117,12.5,22,12.5c0.83,0,1.571,0.416,2.032,1.102C25.358,12.199,26,11,26,11
	s-3.692-7-12-7S2,11,2,11s3.692,7,12,7C15.346,18,16.559,17.803,17.667,17.494z M9,11c0-2.761,2.239-5,5-5s5,2.239,5,5s-2.239,5-5,5
	S9,13.761,9,11z"
          ></path>
          <path
            d="M29.86,26.492l-7.027-12.008c-0.377-0.645-1.289-0.645-1.666,0L14.14,26.492C13.751,27.155,14.218,28,14.973,28h14.054
	C29.782,28,30.249,27.155,29.86,26.492z M21.521,17h0.958c0.284,0,0.511,0.237,0.5,0.521l-0.216,5.192
	C22.755,22.873,22.623,23,22.462,23h-0.924c-0.161,0-0.293-0.127-0.3-0.288l-0.216-5.192C21.01,17.237,21.237,17,21.521,17z
	 M22,26.5c-0.69,0-1.25-0.56-1.25-1.25S21.31,24,22,24s1.25,0.56,1.25,1.25S22.69,26.5,22,26.5z"
            fill="#eb1c1c"
          ></path>
        </svg>
        <p className="max-w-[400px] mx-auto text-sm text-center leading-[160%]">
          {
            'Hello, it appears you do not have the required permissions to proceed with this action . We recommend navigating back to the previous page or consulting our Help Center for further guidance.'
          }
        </p>
      </div>
    );
  }
  return (
    <div className="flex flex-col items-center justify-center h-[70vh] gap-4">
      <NetworkErrorIcon />
      <div className="flex flex-col items-center justify-center gap-1">
        <h2 className="text-xl font-medium text-foreground">
          Something went wrong...
        </h2>
        <p className="max-w-sm mx-auto text-center leading-[160%] text-muted-foreground">
          {!message
            ? message
            : 'We had some trouble loading this page. Please refresh the page or see our Help Center for more information.'}
        </p>
      </div>
      <div className="flex items-center gap-3">
        <Button
          onClick={() => navigate(-1)}
          variant="secondary"
          size="lg"
          className="border rounded-xl w-fit"
        >
          <ArrowDownIcon className="w-4 h-4 rotate-90" /> Go Back
        </Button>
        <Button
          onClick={isLogout ? logout : refetch}
          className="text-white rounded-xl bg-primary"
          size="lg"
        >
          {isLogout ? <LogOutIcon /> : <ReloadIcon />}
          {isLogout ? 'Logout' : 'Retry'}
        </Button>
      </div>
    </div>
  );
};

export default ErrorMessage;
