import * as Yup from 'yup';

const DocumentSchema: Yup.ObjectSchema<Document> = Yup.object().shape({
  file: Yup.string().required('File path is required'),
  name: Yup.string().required('Document name is required'),
});

const ImageSchema: Yup.ObjectSchema<Image> = Yup.object().shape({
  isDefaultImage: Yup.boolean().required('Default image status is required'),
  file: Yup.string().required('Image file path is required'),
});

export const NewAssetFormSchema: Yup.ObjectSchema<INewAsset> =
  Yup.object().shape({
    propertyTypeId: Yup.number().required('Property type is required'),
    title: Yup.string().required('Title is required').trim(),
    description: Yup.string()
      .min(100, 'Description must be at least 100 characters long')
      .required('Description is required')
      .trim(),
    moratoriumId: Yup.number(),
    address: Yup.object().shape({
      fullAddress: Yup.string().required('Full address is required'),
      localGovernmentId: Yup.number().required(
        'Local government ID is required'
      ),
      country: Yup.string(),
      state: Yup.string(),
    }),
    totalAssetValue: Yup.number()
      .typeError('Please provide a valid value')
      .required('Total asset value is required'),
    availableUnits: Yup.number()
      .typeError('Please provide a valid value')
      .min(1, 'AvailableUnits cannot be less than 1')
      .required('Available units is required'),
    expectedROI: Yup.number()
      .typeError('Please provide a valid value')
      .min(1, 'Expected cannot be less than 1')
      .required('Expected ROI is required'),
    expectedCompletion: Yup.string(),
    unitPrice: Yup.number()
      .typeError('Please provide a valid value')
      .required('Unit price is required'),
    isInvestimentConvertable: Yup.boolean().default(false),
    documents: Yup.array()
      .of(DocumentSchema)
      .min(1, 'At least one document is required')
      .required('Documents is required'),
    images: Yup.array()
      .of(ImageSchema)
      .min(4, 'At least four images is required')
      .required('Images is required'),
  });

export type NewAssetType = Yup.InferType<typeof NewAssetFormSchema>;

export interface INewAssetPayload {
  propertyTypeId: number;
  title: string;
  description: string;
  moratoriumId?: number;
  address: {
    lga: string;
    lgaId: number;
    localGovernmentId: { label: any; value: any };
    fullAddress: string;
    country?: { label: any; value: any };
    state?: { label: any; value: any };
  };
  totalAssetValue: number;
  availableUnits: number;
  expectedROI: number;
  expectedCompletion?: string | null;
  unitPrice: number;
  isInvestimentConvertable: boolean;
  documents: Document[];
  images: Image[];
}

export interface INewAsset {
  propertyTypeId: number;
  title: string;
  description: string;
  moratoriumId?: number;
  address: Address;
  totalAssetValue: number;
  availableUnits: number;
  expectedROI: number;
  expectedCompletion?: string | null;
  unitPrice: number;
  isInvestimentConvertable: boolean;
  documents: Document[];
  images: Image[];
}

export interface Address {
  country?: string;
  state?: string;
  localGovernmentId: number;
  fullAddress: string;
}

export interface Document {
  file: string;
  name: string;
}

export interface Image {
  isDefaultImage: boolean;
  file: string;
}

export interface INewAssetFirstForm {
  propertyTypeId: number | undefined;
  title: string;
  description: string;
  moratoriumId?: string;
  address: {
    localGovernmentId: { label: any; value: any };
    fullAddress: string;
    country?: { label: any; value: any };
    state?: { label: any; value: any };
  };
  availableUnits: number;
  unitPrice: number;
  roiMutiple: number;
  maximumRoi: number;
  isPropertyConversionAllowed: boolean;
}

export const FirstAssetFormSchema: Yup.ObjectSchema<INewAssetFirstForm> =
  Yup.object().shape({
    propertyTypeId: Yup.number().required('Property type is required'),
    title: Yup.string().required('Title is required').trim(),
    description: Yup.string()
      .min(100, 'Description must be at least 100 characters long')
      // .max(1200, 'Description must not be at greater than 1000 characters long')
      .required('Description is required')
      .trim(),
    moratoriumId: Yup.string(),
    address: Yup.object().shape({
      fullAddress: Yup.string().required('Full address is required'),
      country: Yup.object()
        .shape({
          label: Yup.mixed().required('Country is required'),
          value: Yup.mixed().required('Country is required'),
        })
        .required('Country is required'),
      state: Yup.object()
        .shape({
          label: Yup.mixed().required('State is required'),
          value: Yup.mixed().required('State is required'),
        })
        .required('State is required'),
      localGovernmentId: Yup.object()
        .shape({
          label: Yup.mixed().required('State label is required'),
          value: Yup.mixed().required('State value is required'),
        })
        .required('Local government ID is required'),
    }),
    availableUnits: Yup.number().required('Available units is required'),
    unitPrice: Yup.number().required('Unit price is required'),
    roiMutiple: Yup.number().required('ROI Multiple is required'),
    maximumRoi: Yup.number().required('ROI Max is required'),
    isPropertyConversionAllowed: Yup.boolean().required(''),
  });

export type FirstAssetType = Yup.InferType<typeof FirstAssetFormSchema>;
