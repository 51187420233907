import { Button } from '../../components/ui/button';
import { ArrowLeft } from 'lucide-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Index = () => {
  const router = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen gap-6 px-4">
      <div className="flex flex-col items-center justify-center gap-4 text-center">
        {/* <LogoMark /> */}
        <span>ERROR 404</span>
        <p className=" text-black text-6xl sm:text-6xl tracking-[-2.56px] font-light leading-[72px]">
          This page does <br className="hidden sm:block" /> not exit
        </p>
      </div>

      <Button
        variant="secondary"
        onClick={() => {
          router('/');
        }}
      >
        <ArrowLeft className="w-5 h-5" />
        Or go back home
      </Button>
    </div>
  );
};

export default Index;
