import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AssetOwners = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [, setOwners] = useState([]);

  useEffect(() => {
    if (location.state && location.state.owners) {
      setOwners(() => location.state.owners);
    } else {
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="bodyContainer">
      <div className="back" onClick={goBack}>
        <FontAwesomeIcon icon={faAngleLeft} />
      </div>

      <div className="profilecard">
        <div className="navUser profileInnerCard">
          <p>
            This offer has to be sent to all the different owners of these
            units. Takes 2-3 Days to get response.
          </p>
        </div>
      </div>

      <div className="assetDetailContainer">
        <div className="assetDetailsDescription">
          <div className="assetDetailsHeader">
            <p className="m-0 italic">Status</p>
          </div>
          <hr />
          <p className="italic">list of items</p>
        </div>
      </div>
    </div>
  );
};

export default AssetOwners;
