import React from 'react';
import Footer from './Footer';
import Nav from './Navbar';
import { useEffect } from 'react';

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Nav />
      <main className="flex flex-col app-container ">
        <section className="py-6 space-y-4 sm:space-y-0">
          <h4 className="text-2xl md:text-6xl font-semibold text-[#04093F] md:leading-[80px]">
            Rules to keep things in check...
          </h4>
          <p className="text-[15px] text-[#04093F]">
            Learn about our terms and conditions below.
          </p>
        </section>
        <section className="py-4 space-y-4">
          <div className="flex flex-col gap-3">
            <h2 className="text-2xl font-semibold">Terms and Conditions</h2>
            <p className="text-[15px]">Last Updated: June 22nd, 2024.</p>
            <p className="text-[15px]">
              These Terms and Conditions ("Terms") govern your use of Musha
              Technologies Ltd.'s ("Musha," "we," "our," or "us") website (the
              "Site") and any related services. By accessing or using the Site,
              you agree to comply with these Terms.
            </p>
          </div>
          <div className="space-y-4">
            <div className="space-y-2">
              <h3 className="text-xl font-semibold">User Conduct:</h3>
              <p className="text-[15px]">
                You agree to use the Site and services in accordance with
                applicable laws, regulations, and these Terms. You agree not to
                engage in any conduct that may disrupt or interfere with the
                functioning of the Site or infringe upon the rights of others.
              </p>
            </div>
            <div className="space-y-2">
              <h3 className="text-xl font-semibold">Intellectual Property:</h3>
              <p className="text-[15px]">
                The content, trademarks, logos, and other intellectual property
                on the Site are owned or licensed by Musha and protected by
                copyright and other laws. You may not use, reproduce, modify, or
                distribute any content from the Site without our prior written
                consent.
              </p>
            </div>
            <div className="space-y-2">
              <h3 className="text-xl font-semibold">User Content:</h3>
              <p className="text-[15px]">
                You retain ownership of any content you submit or upload to the
                Site ("User Content"). By submitting User Content, you grant
                Musha a non-exclusive, royalty-free, worldwide license to use,
                reproduce, modify, adapt, publish, translate, distribute, and
                display the User Content for the purposes of operating and
                promoting the Site and services.
              </p>
            </div>
            <div className="space-y-2">
              <h3 className="text-xl font-semibold">Privacy:</h3>
              <p className="text-[15px]">
                Our Privacy Policy governs the collection, use, and disclosure
                of your personal information. By using the Site, you consent to
                the terms of our Privacy Policy.
              </p>
            </div>
            <div className="space-y-2">
              <h3 className="text-xl font-semibold">Third-Party Links:</h3>
              <p className="text-[15px]">
                The Site may contain links to third-party websites or services
                that are not owned or controlled by Musha. We are not
                responsible for the content or practices of any third-party
                sites.
              </p>
            </div>
            <div className="space-y-2">
              <h3 className="text-xl font-semibold">
                Disclaimer of Warranties:
              </h3>
              <p className="text-[15px]">
                The Site and services are provided on an "as is" and "as
                available" basis, without warranties of any kind, express or
                implied. Musha makes no warranties or representations about the
                accuracy or completeness of the content on the Site or the
                reliability of any information obtained through the Site.
              </p>
            </div>
            <div className="space-y-2">
              <h3 className="text-xl font-semibold">
                Limitation of Liability:
              </h3>
              <p className="text-[15px]">
                Musha shall not be liable for any indirect, incidental, special,
                or consequential damages arising out of or in connection with
                your use of the Site or services.
              </p>
            </div>
            <div className="space-y-2">
              <h3 className="text-xl font-semibold"> Indemnification:</h3>
              <p className="text-[15px]">
                You agree to indemnify and hold Musha harmless from any claims,
                damages, losses, or liabilities arising out of your use of the
                Site or services or violation of these Terms.
              </p>
            </div>
            <div className="space-y-2">
              <h3 className="text-xl font-semibold"> Governing Law:</h3>
              <p className="text-[15px]">
                These Terms shall be governed by and construed in accordance
                with the laws of [Jurisdiction], without regard to its conflict
                of law provisions.
              </p>
            </div>
            <div className="space-y-2">
              <h3 className="text-xl font-semibold"> Changes to Terms:</h3>
              <p className="text-[15px]">
                We may update these Terms from time to time by posting the
                revised version on the Site. Your continued use of the Site
                after the posting of any changes constitutes your acceptance of
                such changes.
              </p>
            </div>
            <div className="space-y-2">
              <h3 className="text-xl font-semibold"> Refund Policy:</h3>
              <p className="text-[15px]">
                All transactions are final and non-refundable. Refunds may only
                be issued upon a thorough review of individual cases.
              </p>
            </div>
            <div className="space-y-2">
              <h3 className="text-xl font-semibold">
                {' '}
                Ownerships & Resale Rights:
              </h3>
              <p className="text-[15px]">
                Musha retains ownership of all assets managed on behalf of its
                users until full purchase and relevant documentation are
                completed. Full ownership of a property is granted to the user
                only upon full payment and receipt of all necessary documents
                from Musha. Musha also retains the right to resell properties
                under its management once the designated moratorium period has
                expired.
              </p>
            </div>
            <div className="space-y-2">
              <h2 className="mb-2 text-xl font-semibold">Contact Us</h2>

              <p className="mb-4">
                If you have any questions or concerns about these Terms, please
                contact us. By using the Site, you agree to be bound by these
                Terms.
              </p>

              <p className="text-[15px]">Effective Date: July 1st, 2024.</p>
            </div>
          </div>
        </section>
      </main>{' '}
      <Footer />
    </div>
  );
};

export default Terms;
