import React, { useEffect, useState } from 'react';
import { Progress } from '../../ui/progress';
import ContainerLayout from '../../core/containerLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetAssetById } from '../../../helpers/api/useAsset';
import { Icons } from '../../../assets/icons';
import ErrorMessage from '../../ui/error-fallback';
import StepOne from './stepOne';
import StepTwo from './stepTwo';
import { Button } from '../../ui/button';
import { useQueryClient } from '@tanstack/react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { staticState } from '../../../utils/constants';
import { getStateIdByName } from '../../../utils/helper';
import { IEditAssetPayload } from './editSchema';

export interface ExtendedEditAsset extends IEditAssetPayload {
  roiMutiple: number;
  maximumRoi: number;
  minimumPriceUnit?: number;
  minimumUnit?: number;
  propertyName: string;
  moratoriumName: string;
  isPropertyConversionAllowed: boolean;
}

const EditAsset = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [step, setStep] = useState<Number>(1);
  const [isClear, setClear] = useState<boolean>(false);
  const progress = step === 1 ? 50 : 100;
  const [createAssetData, setCreateAssetData] = useState<
    ExtendedEditAsset | undefined
  >(undefined);
  const params = useParams();
  const [isGoBack, setGoBack] = useState<boolean>(false);
  const { isLoading, isError, data, errorMessage, refetch } = useGetAssetById(
    params?.id as string
  );
  const details = data?.data;
  const key = `editAssetData${params.id}`;
  const localStorageData = localStorage.getItem(key) ?? '';

  let storedData: any;
  if (localStorageData) {
    storedData = JSON.parse(localStorageData);
  } else {
    storedData = null;
  }

  const goBack = () => {
    navigate(-1);
  };

  function separateImagesAndDocuments(data: any[]) {
    const result = data.reduce(
      (acc: { images: any[]; documents: any[] }, item: { isImage: any }) => {
        if (item.isImage) {
          acc.images.push(item);
        } else {
          acc.documents.push(item);
        }
        return acc;
      },
      { images: [], documents: [] }
    );

    return result;
  }

  // Retrieve data from local storage when component mounts
  useEffect(() => {
    if (details) {
      const { images, documents } = separateImagesAndDocuments(
        details?.assetDocuments
      );
      setCreateAssetData({
        assetId: details?.id,
        propertyTypeId: details.propertyTypeId,
        title: details.title,
        description: details.description,
        moratoriumId: details.propertyTypeMoratoriumId,
        address: details?.assetAddresses[0],
        availableUnits: details.availableUnits,
        expectedROI: details.expectedRoi,
        expectedCompletion: details.expectedCompletionDate,
        unitPrice: details.unitPrice,
        isInvestimentConvertable: details.isInvestimentConvertible,
        documents,
        images,
        propertyName: details?.propertyType,
        moratoriumName: details?.moratorium,
        roiMutiple: details.roiMutiple, // Include this property
        maximumRoi: details.maximumRoi, // Include this property
        isPropertyConversionAllowed: details.isPropertyConversionAllowed, // Include this property
        totalAssetValue: details?.unitPrice * details?.availableUnits, // Include this property
      });
    }
  }, [details]);

  useEffect(() => {
    return () => {
      localStorage.removeItem(key);
      setGoBack(false);
    };
  }, [key]);

  const handleResetForm = () => {
    const { images, documents } = separateImagesAndDocuments(
      details?.assetDocuments
    );
    setCreateAssetData({
      assetId: details?.id,
      propertyTypeId: details.propertyTypeId,
      title: details.title,
      description: details.description,
      moratoriumId: details.propertyTypeMoratoriumId,
      address: details?.assetAddresses[0],
      availableUnits: details.availableUnits,
      expectedROI: details.expectedRoi,
      expectedCompletion: details.expectedCompletion,
      unitPrice: details.unitPrice,
      isInvestimentConvertable: details.isInvestimentConvertible,
      documents,
      images,
      propertyName: details?.propertyType,
      moratoriumName: details?.moratorium,
      roiMutiple: details.roiMutiple, // Include this property
      maximumRoi: details.maximumRoi, // Include this property
      isPropertyConversionAllowed: details.isPropertyConversionAllowed, // Include this property
      totalAssetValue: details?.unitPrice * details?.availableUnits, // Include this property
    });
    localStorage.removeItem(key);
    setStep(1);
    setClear(true);
    setGoBack(false);
    queryClient.invalidateQueries({
      queryKey: [
        'get-asset-config',
        details?.propertyTypeId,
        getStateIdByName(data?.assetAddresses?.state, staticState),
      ],
    });
  };

  const handleResetFormWithEditor = () => {
    handleResetForm();
    const element = document.getElementsByClassName('ql-editor');
    element[0].innerHTML = details?.description ?? '';
  };

  if (isLoading) {
    return (
      <div className="flex justify-center mt-10">
        <Icons.Spinner />
      </div>
    );
  }

  if (isError || !details) {
    return <ErrorMessage message={errorMessage} refetch={refetch} />;
  }

  return (
    <>
      <ContainerLayout>
        <Button
          id="top"
          variant="ghost"
          className="p-0 bg-transparent cursor-pointer"
          onClick={goBack}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </Button>
        <div className="w-full">
          <div className="flex justify-center text-center">
            <p className="text-sm font-medium text-steel-gray">{`Step ${step}`}</p>
            <p className="text-sm text-[#D1D1D1]">/2</p>
          </div>
          <div className="flex items-center justify-center py-3">
            <Progress value={progress} className="max-w-[600px] h-1 w-full" />
          </div>
        </div>

        <div className="w-full mx-auto max-w-[742px]">
          {step === 1 && (
            <StepOne
              onFinish={(formData: ExtendedEditAsset) => {
                localStorage.setItem(
                  key,
                  JSON.stringify({
                    ...formData,
                  })
                );
                setStep(2);
                setGoBack(false);
              }}
              isClear={isClear}
              setClear={setClear}
              data={createAssetData}
              triggerDefaultValue={isGoBack}
            />
          )}

          {step === 2 && (
            <StepTwo
              data={storedData}
              setStep={setStep}
              isOpen={false}
              openPreview={() => {}}
              onFinish={setCreateAssetData}
              onCreateAssetAdd={(data: ExtendedEditAsset) => {
                setCreateAssetData({
                  ...createAssetData,
                  ...data,
                });
                setStep(2);
              }}
              setTriggerDefaultValue={setGoBack}
            />
          )}
          {createAssetData && (
            <Button
              variant="ghost"
              className="p-0 text-destructive hover:text-red-400"
              onClick={step === 2 ? handleResetForm : handleResetFormWithEditor}
            >
              Reset form
            </Button>
          )}
        </div>
      </ContainerLayout>
    </>
  );
};

export default EditAsset;
