import { useEffect, useState, useRef, useContext } from 'react';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { GlobalContext, GlobalContextType } from '../../context/GlobalContext';
import Logo from '../core/logo';
import { toast } from 'sonner';
import { Button } from '../ui/button';
import { cn } from '../../lib/utils';
import React from 'react';
import { CurrencyFormat } from '../../utils/helper';
import { Icons } from '../../assets/icons';
import { routes } from '../../utils/route';

interface ProcessorType {
  [x: string]: any;
  id: number;
  name: string;
  lightThemeLogo: string;
  darkThemeLogo: string;
  url: string | null; // Allow URL to be null
  currency: string;
  assetValue: string; // Consider using a number type if appropriate
  paymentCharge: string;
  grandTotalAmount: string;
  isForeignPayment: boolean;
}

const Payment = () => {
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();
  const navigate = useNavigate();
  const [isCheckout, setCheckout] = useState<boolean>(false);
  // Define the type of inputRef.current as an array of HTMLInputElement or a type with an index signature
  const inputRef: React.MutableRefObject<
    HTMLInputElement[] | { [key: number]: HTMLInputElement }
  > = useRef([]);
  const { isDarkmode } = useContext(GlobalContext) as GlobalContextType;
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [orderId, setOrderId] = useState('');
  const [processor, setProcessor] = useState<ProcessorType>(
    {
      id: 0, // Placeholder for non-existent processors
      name: '', // Empty string for initial name
      lightThemeLogo: '', // Empty string for placeholder logo
      darkThemeLogo: '', // Empty string for placeholder logo
      url: null,
      currency: '', // Empty string for currency
      assetValue: 0,
      paymentCharge: 0,
      grandTotalAmount: 0,
      isForeignPayment: false,
    } as unknown as ProcessorType // Ensures type safety
  );

  useEffect(() => {
    if (location.state && location.state.details) {
      setPaymentDetails(() => location.state.details);
      setOrderId(location.state.orderId);
    } else {
      navigate(-1);
    }
  }, [location.state, navigate]);

  const handleCardCheck = (index: number) => {
    if (Array.isArray(inputRef.current)) {
      inputRef.current[index].click();
    }
  };

  const handleSelect = (e: any) => {
    let pickedProcessor = paymentDetails.filter(
      (obj: any) => obj.id === Number(e.target.value)
    )[0];
    setProcessor(() => ({ ...(pickedProcessor as ProcessorType) })); // Type assertion
  };

  const handleCheckout = async (e: any) => {
    e.preventDefault();
    if (!processor.id) {
      return toast.error('Pick a payment processor');
    }
    setCheckout(true);
    try {
      localStorage.setItem('orderId', orderId);
      let body = {
        paymentProcessorId: processor.id,
        orderId: orderId,
      };
      let payment = await axiosPrivate.post(`asset/order-payment`, body);
      let url = payment.data.data.paymentLink;
      setCheckout(false);
      window.open(url, '_self', 'noreferrer');
    } catch (err: any) {
      toast.error(err.response?.data?.responseMessage || 'An error occurred');
      setCheckout(false);
      if (
        err.response?.data?.responseMessage.toLowerCase() === 'order not active'
      ) {
        navigate(`${routes.asset}/${location.state.assetDetails.id}`, {
          state: {
            assetDetails: location.state.assetDetails,
          },
        });
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen max-w-xl p-4 mx-auto space-y-10">
      <div className="hidden " onClick={() => navigate(-1)}>
        <FontAwesomeIcon icon={faAngleLeft} />
      </div>

      <Link to="/" className="">
        <Logo type="icon" />
      </Link>

      <div className="flex items-center justify-center w-full gap-4 ">
        {paymentDetails &&
          paymentDetails.length > 0 &&
          paymentDetails.map((item: any, i: number) => (
            <div
              key={i}
              className="flex flex-col items-center justify-center dark:border-[#3b3a3a] dark:border relative h-[236px] w-full sm:w-[211.56px] rounded-3xl shadow hover: cursor-pointer"
              onClick={() => handleCardCheck(i)}
            >
              <input
                ref={(ref) => (inputRef.current[i] = ref!)}
                id="propertyTypeId"
                type="radio"
                className="hidden"
                value={item.id}
                name="propertyTypeId"
                onClick={(e) => handleSelect(e)}
              />
              <img
                className="h-16"
                src={isDarkmode ? item?.darkThemeLogo : item.lightThemeLogo}
                alt="payment_logo"
              />
              <p
                className={cn(
                  'absolute dark:text-white text-sm sm:text-base right-0 flex items-center justify-center bottom-0 p-4 h-fit sm:rounded-t-none rounded-b-3xl w-full text-center border-t dark:border-t-[#3b3a3a] text-black',
                  {
                    ' bg-primary  text-white': processor.id === item.id,
                  }
                )}
              >
                {item.currency}
              </p>
            </div>
          ))}
      </div>

      <div className="flex flex-col w-full gap-10 ">
        <div className="rounded-lg flex text-sm sm:text-base flex-col w-full max-w-lg mx-auto text-light-grey bg-[#F4F4F4] dark:bg-transparent dark:border-[#3b3a3a] dark:border gap-5 p-3">
          {processor.isForeignPayment && (
            <div className="grid grid-cols-2">
              <p>Converted Amount</p>
              <p className="place-self-end">
                {processor.currency}{' '}
                <CurrencyFormat
                  value={processor?.assetValue || 0}
                  currency={processor?.currency === 'NGN' ? 'NGN' : 'USD'}
                />
              </p>
            </div>
          )}
          <div className="grid grid-cols-2">
            <p>Payment Charge</p>
            <p className="place-self-end">
              <CurrencyFormat
                value={processor?.paymentCharge || 0}
                currency={processor?.currency === 'NGN' ? 'NGN' : 'USD'}
              />
            </p>
          </div>
          <div className="grid grid-cols-2">
            <h5>Total</h5>
            <h5 className="place-self-end">
              <CurrencyFormat
                value={processor?.grandTotalAmount || 0}
                currency={processor?.currency === 'NGN' ? 'NGN' : 'USD'}
              />
            </h5>
          </div>
        </div>

        <div className="max-w-[335px] w-full mx-auto flex items-center gap-4 justify-center">
          <Button
            variant="secondary"
            onClick={() => navigate(-1)}
            className="p-6 w-72 rounded-xl"
            disabled={isCheckout}
          >
            <FontAwesomeIcon icon={faAngleLeft} className="mr-2" /> Go back
          </Button>
          <Button
            onClick={(e) => handleCheckout(e)}
            className="p-6 w-72 rounded-xl"
            disabled={isCheckout}
          >
            {isCheckout && (
              <Icons.Spinner className="w-5 h-5 mr-2 animate-spin" />
            )}
            Checkout{' '}
            <FontAwesomeIcon icon={faAngleLeft} className="ml-2 rotate-180" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Payment;
