import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useRefreshToken from './useRefreshToken';
import { getTokens } from '../utils/token';
import useAxiosWithInterceptor from '../helpers/axiosConfig';

const BASE = process.env.REACT_APP_URL;
const BASE_URL = `${BASE}/api/v1/`;

const useAxiosPrivate = () => {
  const navigate = useNavigate();
  const refresh = useRefreshToken();
  const axiosInstance = useAxiosWithInterceptor(BASE_URL);

  useEffect(() => {
    const requestIntercept = axiosInstance.interceptors.request.use(
      (config) => {
        if (!config.headers['Authorization']) {
          const { accessToken } = getTokens();
          config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            const newAccessToken = await refresh();
            originalRequest.headers[
              'Authorization'
            ] = `Bearer ${newAccessToken}`;
            return axiosInstance(originalRequest);
          } catch (refreshError) {
            navigate('/login');
            return Promise.reject(refreshError);
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosInstance.interceptors.request.eject(requestIntercept);
      axiosInstance.interceptors.response.eject(responseIntercept);
    };
  }, [navigate, refresh]);

  return axiosInstance;
};

export default useAxiosPrivate;
