import React, { useState } from 'react';
import SavedAsset from '../../components/dashboard/SavedAsset';
import ErrorMessage from '../../components/ui/error-fallback';
import { Button } from '../../components/ui/button';
import { useNavigate } from 'react-router-dom';
import { useSavedAssets } from '../../helpers/api/useAsset';

const SavedAssetPage = () => {
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState<number>(0);
  const { loadingAsset, isError, savedAssets, refetch, errorMessage } =
    useSavedAssets(pageNumber);

  if (isError) {
    return <ErrorMessage message={errorMessage} refetch={refetch} />;
  }

  if (!loadingAsset && !savedAssets?.length) {
    return (
      <div className="flex flex-col items-center justify-center h-[75vh] gap-3">
        <p className="-mt-5 text-base">{'There are no saved assets'}</p>
        <Button
          variant="default"
          className=""
          onClick={() => {
            navigate(-1);
          }}
        >
          Go Back
        </Button>
      </div>
    );
  }

  return (
    <div className="">
      <SavedAsset assets={savedAssets || []} isLoading={loadingAsset} />
      <button
        onClick={() => setPageNumber((prev) => prev + 1)}
        className="hidden"
      >
        Next Page
      </button>
    </div>
  );
};

export default SavedAssetPage;
