import React, { useEffect, useState } from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from 'recharts';

interface CurrencyFormatProps {
  value: string | number;
}
export const CurrencyFormat: React.FC<CurrencyFormatProps> = ({ value }) => {
  // Assuming you want to format the value with 2 decimal places and commas for thousands separator
  const formattedValue = new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
    minimumFractionDigits: 2,
  }).format(Number(value));

  return <span className="bg-transparent">{formattedValue}</span>;
};

export const useIsServerSide = () => {
  const [isServerSide, setIsServerSide] = useState(true);

  useEffect(() => {
    setIsServerSide(false);
  }, [setIsServerSide]);

  return isServerSide;
};

interface AssetPriceLineChartProps {
  data: {
    id: number;
    assetId: number;
    price: number;
    isCurrentPrice: boolean;
    createdBy: string;
    dateCreated: string;
  }[];
  isLoading: boolean;
}
interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
  label?: string | number;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-[#0B2253] rounded-lg p-3 text-white">
        <p className="label">
          Amount: <CurrencyFormat value={payload[0].value || 0} />
        </p>
        <p className="intro">Date: {payload[0]?.payload?.date}</p>
      </div>
    );
  }

  return null;
};

const AssetPriceLineChart: React.FC<AssetPriceLineChartProps> = ({
  data,
  isLoading,
}) => {
  const getDayOfWeekFromDate = (dateString: string) => {
    const date = new Date(dateString);
    const dayOfWeekNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return dayOfWeekNames[date.getDay()];
  };

  const getDate = (dateString: string): Date => {
    return new Date(dateString);
  };

  // Transform the response into the expected format for the chart
  const chartData = data?.map((item) => ({
    name: getDayOfWeekFromDate(item.dateCreated),
    date: getDate(item.dateCreated).toDateString(),
    price: item.price,
  }));

  // // Define a custom comparator function to sort by day of the week
  // const compareByDayOfWeek = (a: { name: string }, b: { name: string }) => {
  //   const dayOfWeekNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  //   return dayOfWeekNames.indexOf(a.name) - dayOfWeekNames.indexOf(b.name);
  // };

  // // Sort the chartData array by day of the week
  // chartData.sort(compareByDayOfWeek);

  if (isLoading) {
    return (
      <div className="relative flex flex-col w-full gap-4 ">
        <h1 className="text-lg font-semibold text-foreground">
          Asset Activity
        </h1>{' '}
        <div className="flex items-center justify-center w-full h-96">
          <span>Loading activity...</span>
        </div>
      </div>
    );
  }

  if (data?.length === 0) {
    return (
      <div className="relative flex flex-col w-full gap-4">
        <h1 className="text-lg font-semibold text-foreground">
          Asset Activity
        </h1>{' '}
        <div className="flex items-center justify-center w-full h-96">
          <span>No asset activity</span>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full gap-4 py-10">
      <h1 className="text-lg font-semibold text-foreground">Asset Activity</h1>
      <ResponsiveContainer width="100%" height={350}>
        <AreaChart
          data={chartData}
          syncId="anyId"
          margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" tick={{ fontSize: 14 }} />
          <YAxis
            tickFormatter={(value) =>
              new Intl.NumberFormat('en-US', {
                notation: 'compact',
                compactDisplay: 'short',
              }).format(value)
            }
            tick={{ fontSize: 12 }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Area
            type="monotone"
            dataKey="price"
            stroke="#2563EB"
            fill="#B9CFF9"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AssetPriceLineChart;
