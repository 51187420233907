import React from 'react';
import Footer from './Footer';
import Nav from './Navbar';
import { useEffect } from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../../components/ui/accordion';
import { faqs } from '../../utils/constants';

const FAQ = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Nav />
      <main className="flex flex-col pb-10 app-container">
        <section className="py-6 space-y-4 sm:space-y-0">
          <h4 className="text-2xl md:text-6xl font-bold text-[#04093F] md:leading-[80px]">
            Frequently asked questions...
          </h4>
          <p className="text-[15px] text-[#04093F]">
            We got tired of answering these questions, so we made a note.
          </p>
        </section>
        <div className="w-full mx-auto">
          <Accordion type="single" collapsible className="w-full">
            {faqs.map(
              (faq: { question: string; answer: string }, index: number) => (
                <AccordionItem key={index} value={`item-${index + 1}`}>
                  <AccordionTrigger className="text-left text-base sm:text-[18px]">
                    {faq.question}
                  </AccordionTrigger>
                  <AccordionContent className="text-sm sm:text-[15px] ">
                    {faq.answer}
                  </AccordionContent>
                </AccordionItem>
              )
            )}
          </Accordion>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default FAQ;
