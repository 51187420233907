import React from 'react';
import { useState } from 'react';

const WelcomeInfoSection = () => {
  const [slideNumber, setSlideNumber] = useState<number>(0);

  let slides = [
    {
      hero: '/Receipts.png',
      headerText: 'No more hard copy documents',
      subText: 'Buy and sell real estate properties tracking them on you app.',
      arrow: '/assets/images/button-next.png',
    },
    {
      hero: '/Chart.png',
      headerText: 'Track your real estate investments',
      subText: 'Buy and sell real estate properties tracking them on your app',
      arrow: '/assets/images/button-next-1.png',
    },
    {
      hero: '/Location.png',
      headerText: 'Buy real estate assets from any location',
      subText: 'Buy and sell real estate properties tracking them on you app.',
      arrow: '/assets/images/button-next-2.png',
    },
  ];

  const handleNext = (i: number) => {
    if (i < 2) {
      setSlideNumber(i + 1);
    } else {
      return;
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen gap-4 text-center bg-primary">
      <div className="w-auto h-[150px]">
        <img src={slides[slideNumber].hero} alt="" />
      </div>
      <div className="space-y-2 text-white">
        <h4 className="text-2xl font-semibold">
          {slides[slideNumber].headerText}
        </h4>
        <p className="text-lg">{slides[slideNumber].subText}</p>
      </div>
      <div className="arrow">
        <img
          onClick={() => handleNext(slideNumber)}
          src={slides[slideNumber].arrow}
          alt=""
        />
      </div>
    </div>
  );
};

export default WelcomeInfoSection;
