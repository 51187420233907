import React, { useEffect } from 'react';
import Verify from '../../components/auth/VerifyAccount';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from '../../utils/route';

const VerifyAccount: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (!state?.email) {
      return navigate(routes.dashboard);
    }
  }, [navigate, state?.email]);

  return (
    <div className="flex justify-center items-center h-screen sm:bg-[#f9fafb]">
      <Verify />
    </div>
  );
};

export default VerifyAccount;
