'use client';

import { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { getInitials } from '../../lib/utils';
import React from 'react';
import { useLogout } from '../../helpers/api/useAuth';
import { getAuthToken } from '../../helpers/axiosConfig';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '../../components/ui/avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../../components/ui/dropdown-menu';
import { ChevronDownIcon, HamburgerMenuIcon } from '@radix-ui/react-icons';
import { useGetUser } from '../../helpers/api/useAsset';
import { routes } from '../../utils/route';

export default function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const token = getAuthToken();
  const { logoutUser: logout } = useLogout();
  const { payload } = useGetUser();
  const user = payload;
  const profilePicture = user?.profilePicture
    ? user?.profilePicture
    : '/assets/images/profileImageplaceholder.png';
  const user_name = (user?.firstname ?? '') + ' ' + (user?.lastname ?? '');

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      // You can adjust the scroll threshold based on your design
      const scrollThreshold = 50;

      setIsScrolled(scrollY > scrollThreshold);
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // const navLinkCssClasses = ({ isActive }: { isActive: boolean }): string => {
  //   return `flex group-hover:text-primary-700 group-hover:bg-primary-50 text-gray-700 font-semibold text-base px-3 py-2 rounded-md ${
  //     isActive ? 'text-primary-700 bg-primary-50' : ''
  //   }`;
  // };

  return (
    <div
      className={`bg-white sticky top-0 left-0 py-2 right-0 z-50 w-full ${
        isScrolled ? 'shadow' : '' // Add shadow class when scrolled
      }`}
    >
      <nav className="flex justify-between app-container">
        <Link to="/" className="flex items-center space-x-3 ">
          <img className="w-10 h-16" src="/LOGO.png" alt="" />
        </Link>

        <div className="flex items-center space-x-5">
          {token ? (
            <div className="flex items-center gap-4">
              <DropdownMenu>
                <DropdownMenuTrigger
                  className="outline-none cursor-pointer"
                  asChild
                >
                  <div className="flex items-center gap-2">
                    <Avatar className="flex-shrink-0 object-cover w-12 h-12">
                      <AvatarImage
                        src={profilePicture || ''}
                        alt={user_name ?? 'User'}
                        className="object-cover"
                      />

                      <AvatarFallback>
                        {getInitials(user_name ?? 'User')}
                      </AvatarFallback>
                    </Avatar>
                    <div className="flex items-center gap-2 space-y-1">
                      <p className="hidden max-w-[95px] truncate text-sm font-semibold capitalize leading-none sm:block">
                        {(user?.firstname ?? '') + ' ' + (user?.lastname ?? '')}
                      </p>
                      <ChevronDownIcon className="h-[14px] w-[14px]" />
                    </div>
                  </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-56 mt-4">
                  <DropdownMenuLabel>My Account</DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <DropdownMenuGroup>
                    <Link to={routes.dashboard}>
                      <DropdownMenuItem>Dashboard</DropdownMenuItem>
                    </Link>
                    <NavLink to={routes.market}>
                      <DropdownMenuItem>Market</DropdownMenuItem>
                    </NavLink>
                    <Link to={routes.profile}>
                      <DropdownMenuItem>Profile</DropdownMenuItem>
                    </Link>
                  </DropdownMenuGroup>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem
                    className="text-destructive"
                    onClick={logout}
                  >
                    Sign out
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          ) : (
            <>
              <DropdownMenu>
                <DropdownMenuTrigger
                  className="outline-none cursor-pointer md:hidden"
                  asChild
                >
                  <HamburgerMenuIcon className="w-5 h-5" />
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-56 mt-4">
                  <DropdownMenuGroup>
                    <Link to={'/login'}>
                      <DropdownMenuItem>Log In</DropdownMenuItem>
                    </Link>
                    <Link to={'/welcome'}>
                      <DropdownMenuItem>Create Account</DropdownMenuItem>
                    </Link>
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
              <div className="items-center hidden gap-4 md:flex">
                <Link
                  to="/login"
                  className=" bg-white p-3 text-[15px] font-semibold leading-6 text-primary"
                >
                  Log In
                </Link>
                <Link
                  to="/welcome"
                  className="rounded-lg text-center border border-white bg-primary p-3 text-[15px] w-fit font-semibold leading-6 text-white"
                >
                  Create Account
                </Link>
              </div>
            </>
          )}
        </div>
      </nav>
    </div>
  );
}
