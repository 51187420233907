import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import ReactPlayer from 'react-player';

const Video = () => {
  const playerRef = useRef(null);
  const [pause, setPause] = useState<boolean>(false);
  const handlePlay = () => {
    setPause(true);
  };
  const handlePause = () => {
    setPause(false);
  };
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const [hasWindow, setHasWindow] = useState(false);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasWindow(true);
    }
  }, []);

  useEffect(() => {
    if (!inView) {
      handlePause();
    }
  }, [inView]);

  return (
    <div className="py-10">
      {!hasWindow ? (
        <div className="bg-zinc-100 rounded-lg animate-pulse w-full h-96 md:h-[600px] lg:h-[800px]">
          Loading...
        </div>
      ) : (
        <div
          ref={ref}
          className="w-full app-container h-96 md:h-[600px] lg:h-[800px]"
        >
          <ReactPlayer
            playing={pause}
            controls={false}
            ref={playerRef}
            stopOnUnmount={true}
            width="100%"
            height="100%"
            url="https://youtu.be/M_01gPS5qJY"
            youtubeplayerVars={{ autoplay: 1, controls: 0, loop: 1 }}
            onPlay={handlePlay}
            onPause={handlePause}
          />
        </div>
      )}
    </div>
  );
};

export default Video;
