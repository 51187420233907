import { useNavigate } from 'react-router-dom';
import Logo from '../core/logo';
import { Button } from '../ui/button';
import React from 'react';

const WelcomeHeroSection = () => {
  const navigate = useNavigate();
  let today = new Date();
  const goToRegister = () => {
    navigate('/register');
  };
  const goToLogin = () => {
    navigate('/login');
  };

  return (
    <div className="flex flex-col flex-wrap items-center justify-center w-full h-screen max-w-sm gap-6 mx-auto text-center">
      <div
        onClick={() => navigate('/')}
        className="flex flex-col items-center justify-center gap-4"
      >
        <Logo type="icon" />
        <p className="text-base text-[#8C93B4]">
          The Perfect Investment Choice for your Future.
        </p>
      </div>
      <div className="flex flex-col w-full gap-4">
        <Button variant="outline" className="h-12" onClick={goToLogin}>
          Login
        </Button>{' '}
        <Button onClick={goToRegister} className="h-12 ">
          Get Started
        </Button>
      </div>
      <div className="text-sm text-primary">
        <p>Copyright© {today.getFullYear()}</p>
      </div>
    </div>
  );
};

export default WelcomeHeroSection;
