import Login from '../../components/auth/Login';
import { useNavigate } from 'react-router-dom';

import { getAuthToken } from '../../helpers/axiosConfig';
import { useEffect } from 'react';
import React from 'react';
import { routes } from '../../utils/route';

const LoginPage = () => {
  const token = getAuthToken();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      return navigate(routes.dashboard);
    }
  }, [navigate, token]);

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen sm:bg-[#f9fafb] dark:bg-[#171717]">
      <Login />
    </div>
  );
};

export default LoginPage;
