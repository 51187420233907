import { createContext, useReducer } from 'react';

let initial = {theme: 'light'}

export const ThemeContext = createContext(initial);

const ThemeReducer = (state, action) => {
    switch (action.type) {
        case "SET_THEME":
            return {
                theme:action.payload
            };
        default:
            return state;
    }
}


export const ThemeContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(ThemeReducer, initial);

    return (
        <ThemeContext.Provider
            value ={{
                theme: state.theme,
                dispatch
            }}
        >
            {children}
        </ThemeContext.Provider>
    )
}