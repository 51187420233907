import React from 'react';
import { errorConnection } from '../helpers/api/useAuth';
import { IErrorResponseType } from '../interface/common/error';
import Constants from './constants';
import { routes } from './route';

export const numberToString = (value: number) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formattedStringToNumber = (value: string) => {
  if (typeof value !== 'string') return;
  return parseFloat(value.replaceAll(',', ''));
};

export const toSentenceCase = (str: string) => {
  return str.trim().toLowerCase().charAt(0).toUpperCase() + str.slice(1);
};

type Country = {
  id: string;
  name: string;
};

type State = {
  id: string;
  name: string;
};

// Function to format countries and states into objects with label and value properties
export const formatOptions = (options: (Country | State)[]) => {
  return options?.map((option) => ({
    value: `${option.name} - ${option.id}`,
    label: option.name,
  }));
};

export const getBase64 = (
  file: Blob,
  cb: {
    (base64: any): Promise<void>;
    (base64: any): Promise<void>;
    (arg0: string | ArrayBuffer | null): void;
  }
) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    console.error('Base64: ', error);
  };
};

export function ensureNumber(value: any) {
  // Check if the value is a number
  if (typeof value === 'number' && !isNaN(value)) {
    return value; // Return the value as is
  }

  // Try to convert the value to a number
  const numberValue = parseFloat(value);

  // Check if the converted value is a valid number
  if (!isNaN(numberValue)) {
    return numberValue; // Return the converted value
  } else {
    return 0; // Return 0 if the value cannot be converted to a number
  }
}

export const removeHtmlTags = (html: string) => {
  // Remove all HTML tags
  const newString = html?.replace(/(<([^>]+)>)/gi, '');

  // If the new string is empty, return 'nil'
  return newString === '' ? '_' : newString;
};

export const toDataURL = (
  url: string,
  callback: (dataUrl: string | null) => void
) => {
  const xhr = new XMLHttpRequest();
  xhr.onload = () => {
    if (xhr.status === 200) {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result as string);
      };
      reader.readAsDataURL(xhr.response);
    } else {
      callback(null); // Error handling
    }
  };
  xhr.open('GET', `http://localhost:4000/proxy?url=${encodeURIComponent(url)}`);
  xhr.responseType = 'blob';
  xhr.send();
};

export const getStateIdByName = (
  stateName: any,
  states: { id: number; name: string }[]
): { label: string; value: number | undefined } => {
  const state = states?.find(
    (s) => s?.name.toLowerCase() === stateName?.toLowerCase()
  );
  return state
    ? { label: state?.name, value: state?.id }
    : { label: stateName, value: undefined };
};

export function getErrorMessage(
  err: unknown,
  defaultMsg: string = errorConnection
): string {
  const typedError = err as IErrorResponseType;
  const errorObject = typedError?.response?.data?.errors;
  const errorKeys = errorObject && Object.keys(errorObject)[0];

  // Get the first error message
  const firstError =
    errorKeys && typeof errorObject === 'object' && errorObject !== null
      ? errorObject?.[errorKeys][0]
      : null;

  // If errors exist, show the first one
  const errorMessage = typedError?.response?.data?.errors
    ? firstError
    : typedError?.response?.data?.responseMessage || defaultMsg;

  return errorMessage;
}

interface CurrencyFormatProps {
  value: number | string;
  currency: 'NGN' | 'USD';
}

export const formatMoney = (
  value: number,
  currency: 'NGN' | 'USD'
): string | null => {
  let exp,
    suffixes = ['K', 'M', 'B', 'T', 'P', 'E'];
  const currencySymbol = currency === 'NGN' ? '₦' : '$';
  const locale = currency === 'NGN' ? 'en-NG' : 'en-US';

  if (Number.isNaN(value)) {
    return null;
  }
  if (value < 1000000) {
    return value.toLocaleString(locale, { style: 'currency', currency });
  }
  exp = Math.floor(Math.log(value) / Math.log(1000));
  return (
    currencySymbol +
    (value / Math.pow(1000, exp)).toFixed(2) +
    suffixes[exp - 1]
  );
};

export const CurrencyFormat: React.FC<CurrencyFormatProps> = ({
  value,
  currency,
}) => {
  // Convert the value to a number
  const numericValue =
    typeof value === 'string' ? Number(value.replace(/,/g, '')) : value;

  // Format the value using the formatMoney function for large values
  const formattedValue = formatMoney(numericValue, currency);

  return <span>{formattedValue}</span>;
};
export const redirectHelper = (data: any, navigate: Function) => {
  switch (data.accountStatus) {
    case Constants.pendingEmailAndPhoneConfirmation:
      navigate('../register', {
        state: {
          section: 'verification',
          subsection: data.isPhoneConfirmed ? 'email' : 'phone',
        },
      });
      break;
    case Constants.pendingIdentityVerification:
    case Constants.identityVerificationDeclined:
      navigate(routes.register, {
        state: {
          section: 'requiredSteps',
        },
      });
      break;
    case Constants.completeCorporateOnboarding:
      navigate(routes.register, {
        state: {
          section: 'register',
          subsection: 'contactInfo',
        },
      });
      break;
    case Constants.firstTimeLogin:
      navigate(routes.register, {
        state: {
          section: 'changePassword',
        },
      });
      break;
    case Constants.awaitingVerificationApproval:
      navigate(routes.dashboard);
      break;
    case Constants.active:
      navigate(routes.dashboard, { replace: true });
      break;
    default:
      navigate('/', { replace: true });
  }
};

export const maskEmail = (email: string | undefined) => {
  if (!email) {
    return ''; // Return an empty string or a fallback value when the email is undefined
  }
  const [localPart, domain] = email.split('@');
  const maskedLocalPart = localPart.slice(0, 2) + '***' + localPart.slice(-1);
  return `${maskedLocalPart}@${domain}`;
};
