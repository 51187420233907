import { useQuery } from '@tanstack/react-query';
import useAxiosWithInterceptor from '../axiosConfig';
import { useUrls } from '../useUrls';
import { AxiosResponse } from 'axios';
import { ReferralSummary } from '../../utils/interface';

export const useReferralSummary = () => {
  const { user } = useUrls();
  const axiosInstance = useAxiosWithInterceptor();
  const { isPending, isError, error, data, refetch } = useQuery({
    queryKey: ['referral-summary'],
    queryFn: async () => {
      const response = await axiosInstance.get<AxiosResponse<ReferralSummary>>(
        `${user}/referral-summary`
      );
      return response.data;
    },
  });

  return {
    isLoading: isPending,
    isError,
    data: data?.data,
    refetch,
    errorMessage: error instanceof Error ? error.message : 'An error occurred',
  };
};
