'use client';

import React from 'react';
import { CheckCircle, Clock, UserX, Phone, Mail, LogIn } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '../ui/alert';
import { Icons } from '../../assets/icons';

type VerificationStatus =
  | 'Active'
  | 'Banned'
  | 'Blocked'
  | 'CompleteCorporateOnboarding'
  | 'PendingIdentityVerification'
  | 'IdentityVerificationDeclined'
  | 'AwaitingVerificationApproval'
  | 'PendingEmailAndPhoneConfirmation'
  | 'PendingPhoneConfirmation'
  | 'FirstTimeLogin';

interface StatusConfig {
  title: string;
  description: string;
  icon: React.ReactNode;
  color: 'success' | 'warning' | 'error' | 'hint';
}

const statusConfigs: Record<VerificationStatus, StatusConfig> = {
  Active: {
    title: 'Account Active',
    description: 'Your account is fully verified and active.',
    icon: <CheckCircle className="w-4 h-4" />,
    color: 'success',
  },
  Banned: {
    title: 'Account Banned',
    description: 'Your account has been banned. Please contact support.',
    icon: <Icons.HintIcon className="w-4 h-4" />,
    color: 'error',
  },
  Blocked: {
    title: 'Account Blocked',
    description: 'Your account is currently blocked. Please contact support.',
    icon: <Icons.HintIcon className="w-4 h-4" />,
    color: 'error',
  },
  CompleteCorporateOnboarding: {
    title: 'Complete Corporate Onboarding',
    description: 'Please complete the corporate onboarding process.',
    icon: <Icons.HintIcon className="w-4 h-4" />,
    color: 'hint',
  },
  PendingIdentityVerification: {
    title: 'Pending Identity Verification',
    description: 'Your identity verification is in progress.',
    icon: <Icons.HintIcon className="w-4 h-4 " />,
    color: 'warning',
  },
  IdentityVerificationDeclined: {
    title: 'Identity Verification Declined',
    description: 'Your identity verification was declined. Please try again.',
    icon: <UserX className="w-4 h-4" />,
    color: 'error',
  },
  AwaitingVerificationApproval: {
    title: 'Awaiting Verification Approval',
    description: 'Your verification is awaiting approval.',
    icon: <Clock className="w-4 h-4" />,
    color: 'warning',
  },
  PendingEmailAndPhoneConfirmation: {
    title: 'Pending Email and Phone Confirmation',
    description: 'Please confirm your email and phone number.',
    icon: <Mail className="w-4 h-4" />,
    color: 'warning',
  },
  PendingPhoneConfirmation: {
    title: 'Pending Phone Confirmation',
    description: 'Please confirm your phone number.',
    icon: <Phone className="w-4 h-4" />,
    color: 'warning',
  },
  FirstTimeLogin: {
    title: 'First Time Login',
    description: 'Welcome! Please complete your profile setup.',
    icon: <LogIn className="w-4 h-4" />,
    color: 'hint',
  },
};

const statusCategories = {
  success: ['Active'],
  warning: [
    'PendingIdentityVerification',
    'AwaitingVerificationApproval',
    'PendingEmailAndPhoneConfirmation',
    'PendingPhoneConfirmation',
  ],
  error: ['Banned', 'Blocked', 'IdentityVerificationDeclined'],
  hint: ['CompleteCorporateOnboarding', 'FirstTimeLogin'],
};

interface AccountVerificationBannerProps {
  status: VerificationStatus;
  placement: 'header' | 'sidebar';
}

export const AccountVerificationBanner: React.FC<
  AccountVerificationBannerProps
> = ({ status, placement }) => {
  if (status?.toLocaleLowerCase() === 'active') {
    return null; // Do not render the banner for 'Active' status
  }
  const config = statusConfigs[status];
  const bannerClass =
    placement === 'header'
      ? 'w-full rounded-none md:rounded-xl block md:hidden border-x-0'
      : 'rounded-xl border';

  const getAlertVariant = (color: StatusConfig['color']) => {
    switch (color) {
      case 'success':
        return 'bg-[#E8F5E9] border-[#43A047] text-[#1B5E20]';
      case 'warning':
        return 'bg-[#FFF3E0] border-[#FF9800] text-[#E65100]';
      case 'error':
        return 'bg-[#FFEBEE] border-[#E53935] text-[#B71C1C]';
      case 'hint':
        return 'bg-[#E3F2FD] border-[#2196F3] text-[#0D47A1]';
      default:
        return '';
    }
  };

  return (
    <Alert className={`${bannerClass} ${getAlertVariant(config.color)}`}>
      <div className="flex items-center">
        {config.icon}
        <AlertTitle className="mt-1 ml-2 text-sm font-medium text-[#263238]">
          {config.title}
        </AlertTitle>
      </div>
      <AlertDescription className="mt-1 text-sm text-[#546E7A] hidden">
        {config.description}
      </AlertDescription>
    </Alert>
  );
};

export function AccountVerificationDemo() {
  return (
    <div className="space-y-4">
      {(
        Object.keys(statusCategories) as Array<keyof typeof statusCategories>
      ).map((category) => (
        <div key={category} className="space-y-2">
          <h2 className="text-lg font-semibold capitalize">{category}</h2>
          {statusCategories[category].map((status) => (
            <AccountVerificationBanner
              key={status}
              status={status as VerificationStatus}
              placement="header"
            />
          ))}
        </div>
      ))}
    </div>
  );
}
