import AssetOwners from '../../components/dashboard/AssetOwners';
import React from 'react';

const OwnerPage = () => {
  return (
    <div id="assetDetails" className="body">
      <AssetOwners />
    </div>
  );
};

export default OwnerPage;
