import { OptionProps, PropsValue } from 'react-select';
import { StylesConfig } from 'react-select';
import { cn } from './utils';
import React from 'react';

export interface SelectOptions {
  readonly value: string | object | PropsValue<SelectOptions> | undefined;
  readonly label: string;
  readonly color?: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
  readonly icon?: React.ReactElement;
  readonly activeIcon?: React.ReactElement;
}

export const borderedStyles: StylesConfig<SelectOptions, true> = {
  control: (css) => ({
    ...css,
    fontWeight: 400,
    padding: '4px !important',
    background: 'transparent',
    outline: 'none !important',
    height: '100%',
    cursor: 'pointer',
    fontSize: '16px',
    border: 'rgb(161 161 170)',
    borderRadius: '0px',
    '&::placeholder': {
      fontWeight: 400,
      textAlign: 'start',
    },
    width: '100%',
    boxShadow: 'none',
    '&:focus': {
      border: '1px solid #000000',
    },
    '&:hover': {
      border: 'none',
    },
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: '14px',
    color: '#868297',
    textDecoration: 'capitalize',
    fontWeight: 400,
  }),
  menu: (base) => ({
    ...base,
    boxShadow: '0px 5px 40px 0px #0000001A',
    maxWidth: '100%',
    borderRadius: '12px',
    padding: '0',
    fontSize: '14px',
    position: 'absolute',
    top: '42px',
    overflowY: 'auto',
    zIndex: '99',
    overflow: 'hidden',
  }),
  menuList: (base) => ({
    ...base,
    fontSize: '14px',
    '::-webkit-scrollbar': {
      width: '8px',
      height: 'auto',
    },
    '::-webkit-scrollbar-track': {
      background: 'white',
      borderRadius: '12px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#F4F8F9',
      borderRadius: '12px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#F4F8F9',
    },
    overflowX: 'hidden',
  }),
};

export const styles: StylesConfig<SelectOptions, true> = {
  control: (css) => ({
    ...css,
    fontWeight: 400,
    padding: '0px !important',
    background: 'transparent',
    outline: 'none !important',
    height: '100%',
    cursor: 'pointer',
    fontSize: '16px',
    border: 'rgb(161 161 170)',
    borderRadius: '0',
    '&::placeholder': {
      fontWeight: 400,
      textAlign: 'start',
    },
    width: '100%',
    boxShadow: 'none',
    '&:focus': {
      border: '1px solid #000000',
    },
    '&:hover': {
      border: 'none',
    },
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: '14px',
    color: '#868297',
    fontWeight: 400,
  }),
  menu: (base) => ({
    ...base,
    boxShadow: '0px 5px 40px 0px #0000001A',
    maxWidth: '100%',
    borderRadius: '12px',
    padding: '0',
    fontSize: '16px',
    position: 'absolute',
    top: '40px',
    overflowY: 'auto',
    zIndex: '99',
    overflow: 'hidden',
  }),
  menuList: (base) => ({
    ...base,
    fontSize: '16px',
    '::-webkit-scrollbar': {
      width: '8px',
      height: 'auto',
    },
    '::-webkit-scrollbar-track': {
      background: 'white',
      borderRadius: '12px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#F4F8F9',
      borderRadius: '12px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#F4F8F9',
    },
    overflowX: 'hidden',
  }),
};

export const CustomOption = ({
  innerProps,
  label,
  data,
  isSelected,
}: OptionProps<SelectOptions>) => {
  return (
    <div
      className={cn(
        'flex w-full max-w-[100%] text-[#1D1641] px-3 cursor-pointer gap-3 py-3 items-center justify-start',
        { 'bg-[#E1ECFE] ': isSelected },
        { 'hover:bg-[#F7F7F7]': !isSelected }
      )}
      {...innerProps}
    >
      {data.icon}
      <span className="ml-1">{label}</span>
    </div>
  );
};
