import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { Button } from '../../components/ui/button';
import Carousel from '../../components/ui/global-carousel';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../components/ui/dialog';
import { Input } from '../../components/ui/input';
import { Label } from '../../components/ui/label';
import { cn } from '../../lib/utils';
import { Icons } from '../../assets/icons';
import { toast } from 'sonner';
import {
  useCancelOrder,
  useGetAssetByCategory,
  useGetAssetPrices,
  useGetMyAsset,
} from '../../helpers/api/useAsset';
import SkeletonCard from '../../components/ui/skeleton-card';
import AssetCard from '../../components/ui/asset-card';
import React from 'react';
import { useCookies } from 'react-cookie';
import { EditIcon } from 'lucide-react';
import parse from 'html-react-parser';
import { CurrencyFormat } from '../../utils/helper';
import { routes } from '../../utils/route';
import AssetPriceLineChart from '../../components/core/lineChart';
import ContainerLayout from '../../components/core/containerLayout';
import ImageGrid from '../../components/dashboard/assetDetails/ImageGrid';
import Like from '../../components/dashboard/Like';

interface Document {
  id: number;
  fileUploadId: number;
  isDefaultImage: boolean;
  documentLink: string;
  isImage: boolean;
  assetId: number;
  dateCreated: string;
  fileUpload: null | any; // Update the type accordingly
}

const AssetDetails = ({
  assetDetails,
  refetch,
}: {
  assetDetails: any;
  isLoading: boolean;
  refetch: () => void;
}) => {
  const cookies = useCookies()[0];
  const params = useParams();
  const [imageDocuments, setImageDocuments] = useState<Document[]>([]);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [purchaseForm, setPurchaseForm] = useState<{
    amount: number | undefined;
    quantity: number | undefined;
  }>({
    amount: 0,
    quantity: undefined,
  });
  const { isSuccess } = useCancelOrder(location.state?.OrderId as string);
  function roundToDecimalPlaces(value: number, decimalPlaces: number) {
    return (
      Math.round(value * Math.pow(10, decimalPlaces)) /
      Math.pow(10, decimalPlaces)
    );
  }
  const { prices, isPriceLoading } = useGetAssetPrices(params?.id as string);
  const category = location.state?.route;

  // const myAsset = users?.email === assetDetails?.user?.email;
  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const { isLoading: dealsLoading, data, mutate } = useGetAssetByCategory();
  const categoryDeals = data?.data?.data;
  const {
    isLoading: myAssetLoading,
    data: payload,
    mutate: viewMyAsset,
  } = useGetMyAsset();
  const myAssets = payload?.data?.data;

  const assets = category?.name === 'My Assets' ? myAssets : categoryDeals;

  useEffect(() => {
    switch (category?.name) {
      case 'Market':
        mutate({ category: Number(category?.categoryValue) });
        break;
      case 'Home':
        mutate({ category: Number(category?.categoryValue) });
        break;
      case 'My Assets':
        viewMyAsset({ category: Number(category?.categoryValue) });
        break;
      default:
        return mutate({ category: Number(1) });
    }
  }, [category, mutate, viewMyAsset]);

  const isSubmitDisabled =
    purchaseForm.amount === 0 ||
    purchaseForm.quantity === 0 ||
    !purchaseForm.amount ||
    !purchaseForm.quantity;

  const goBack = () => {
    navigate(-1);
  };

  const handlePurchase = (e: any) => {
    let amount = e.target.value * assetDetails.unitPrice;
    setPurchaseForm((prevState) => ({
      ...prevState,
      amount,
      [e.target.id]: e.target.value,
    }));
  };

  const selectAll = () => {
    let quantity = assetDetails.availableUnits;
    let amount = quantity * assetDetails.unitPrice;
    setPurchaseForm(() => ({ amount, quantity }));
  };

  const proceed = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      let payload = {
        assetId: assetDetails.id,
        units: purchaseForm.quantity,
      };
      // Check if purchaseForm.quantity is greater than assetDetails.availableUnits
      if ((purchaseForm.quantity as number) > assetDetails.availableUnits) {
        setLoading(false);
        toast.error('Quantity exceeds available units');
        return; // Return without proceeding further
      }

      let checkoutDetails = await axiosPrivate.post(`asset/checkout`, payload);
      setLoading(false);
      navigate(routes.checkout, {
        state: {
          details: checkoutDetails.data.data,
          fallbackImg: selectedImage,
        },
      });
    } catch (err: any) {
      setLoading(false);
      toast.error(err.response?.data?.responseMessage || 'An error occurred');
    }
  };

  useEffect(() => {
    const filteredDocuments = assetDetails?.assetDocuments.filter(
      (document: { isImage: boolean }) => document.isImage === true
    );

    const sortedData = filteredDocuments.sort(
      (a: { isDefaultImage: any }, b: any) => (a.isDefaultImage ? -1 : 1)
    );

    // Check if there's at least one image and none of them are default
    if (
      sortedData.length > 0 &&
      !sortedData.some((doc: { isDefaultImage: any }) => doc.isDefaultImage)
    ) {
      // Set the first image as default
      sortedData[0].isDefaultImage = true;
    }
    // Find the default image [15px]d on isDefaultImage property
    const defaultImage = sortedData.find(
      (document: { isDefaultImage: any }) => document.isDefaultImage
    );

    // If there is a default image, set it as selectedImage
    if (defaultImage) {
      setSelectedImage(defaultImage.documentLink);
    }
    setImageDocuments(filteredDocuments);
  }, [assetDetails?.assetDocuments]);

  const filteredAssets = assets?.filter(
    (asset: { id: any }) => asset.id !== assetDetails.id
  );

  const isUserOwner = assetDetails?.user?.id === cookies?.musha_userId;
  const hasPurchasedUnits = assetDetails?.purchasedUnit > 0;
  const isStatusDeclined = assetDetails?.status.toLowerCase() === 'declined';
  const soldOutUnits =
    Number(assetDetails?.units) - Number(assetDetails?.availableUnits);
  return (
    <ContainerLayout className="gap-0">
      <div className="w-full px-4 pb-2 md:px-0">
        <Button
          id="top"
          variant="ghost"
          className="p-0 text-sm md:text-base"
          onClick={goBack}
        >
          <FontAwesomeIcon icon={faAngleLeft} /> Back
        </Button>
      </div>
      <div className="relative flex flex-col w-full gap-4">
        <ImageGrid images={imageDocuments ?? []} />
        <div className="flex flex-col w-full gap-4 px-4 mx-auto md:px-0">
          <div className="flex flex-col items-start w-full gap-3">
            <div className="flex flex-col w-full gap-4">
              <h1 className="text-2xl font-semibold text-black">
                {assetDetails?.title}
              </h1>
              <div className="flex flex-col items-start justify-between w-full gap-4 md:items-center md:flex-row">
                <div className="flex items-center gap-3">
                  <button
                    type="button"
                    className="bg-[#4489C60F] p-4 rounded-lg"
                  >
                    <Icons.AssetHome />
                  </button>
                  <div className="flex flex-col gap-1 font-semibold text-steel-gray dark:text-white">
                    <p className="leading-[90%] text-xl">
                      <span>{assetDetails?.availableUnits}</span> of{' '}
                      {assetDetails?.units}
                    </p>
                    <span className="text-[15px]">Units Available</span>
                  </div>
                </div>
                <>
                  {hasPurchasedUnits && (
                    <span className="py-3 px-5 text-[15px] font-normal text-white bg-primary rounded-full">
                      You own {assetDetails?.purchasedUnit} units
                    </span>
                  )}

                  {isUserOwner && !isStatusDeclined && !hasPurchasedUnits && (
                    <Button className="text-[#00CB6A] bg-[#E1FFED] hover:bg-[#E1FFED]/80 shadow-none rounded-2xl">
                      {soldOutUnits} Units sold out
                    </Button>
                  )}

                  {!isUserOwner && !hasPurchasedUnits && (
                    <Button
                      onClick={() => setShow(true)}
                      className="p-5 rounded-full"
                    >
                      Buy Asset
                    </Button>
                  )}

                  {isUserOwner && isStatusDeclined && (
                    <Button
                      onClick={() =>
                        navigate(`/${routes.editAsset}/${assetDetails?.id}`)
                      }
                      className="p-5 rounded-full"
                    >
                      <EditIcon className="w-4 h-4 mr-2" /> Edit
                    </Button>
                  )}
                </>
              </div>
            </div>
            <div className="flex flex-row items-center justify-between w-full gap-4">
              <div className="truncate flex-shrink-0 flex items-center text-light-grey text-[11px] sm:text-[13px] gap-2">
                <p>{(assetDetails?.lga && assetDetails?.lga + ',') ?? ''}</p>
                <p>{assetDetails?.state ?? ''}</p>
              </div>
              <Like
                isLiked={assetDetails?.isFavoriteAsset}
                disabled={false}
                assetId={assetDetails?.id}
              />
            </div>
          </div>
          <hr className="bg-[#dee3e6]" />
          <p className="font-normal text-light-grey">
            <span className="text-xl font-semibold text-steel-gray dark:text-white">
              <CurrencyFormat
                value={assetDetails.unitPrice || 0}
                currency="NGN"
              />
            </span>
            /<span className="text-[12px]">Unit</span>
          </p>
        </div>
        <div className="w-full space-y-4">
          <div className="w-full space-y-4">
            <div className="p-3 bg-[#F4F5F6] w-full">
              <p className="text-steel-gray dark:text-black bg-[#F4F5F6]">
                Description
              </p>
            </div>
            <div className="space-y-1 text-[15px] list-style w-full max-w-[700px] px-4 md:px-0">
              {parse(assetDetails?.description ?? '')}
            </div>
          </div>
          <hr className="bg-[#dee3e6]" />

          <div className="w-full space-y-4 text-[15px]">
            <div className="p-3 bg-[#F4F5F6] ">
              <p className="text-steel-gray dark:text-black bg-[#F4F5F6]">
                Investment Details
              </p>
            </div>
            <p className="text-steel-gray dark:text-white font-medium px-4 max-w-[700px]">
              Expected ROI:{' '}
              <span className="font-semibold text-steel-gray dark:text-white">
                {assetDetails?.expectedRoi}%
              </span>
            </p>

            <p className="px-4 font-medium text-steel-gray dark:text-white">
              Investment Conversion Allowed?{' '}
              <span className="font-semibold text-steel-gray dark:text-white">
                {assetDetails?.isInvestmentConvertible ? 'Yes' : 'No'}
              </span>
            </p>
          </div>
          <div className="mt-4 ">
            {assetDetails?.purchasedUnit ? (
              <Button
                onClick={() => setShow(true)}
                className="p-5 rounded-full"
              >
                Buy More
              </Button>
            ) : null}
          </div>
        </div>
        <AssetPriceLineChart
          data={prices?.data ?? []}
          isLoading={isPriceLoading}
        />
        <div className="px-4 md:px-0">
          {dealsLoading || myAssetLoading ? (
            <div className="relative flex flex-col w-full gap-4">
              <div className="flex items-center justify-between">
                <div className="w-32 h-3 bg-gray-200 rounded-lg animate-pulse" />
                <div className="w-24 h-3 bg-gray-200 rounded-lg animate-pulse" />
              </div>
              <div className="flex gap-4 overflow-x-hidden scroll">
                {Array(8)
                  .fill(0)
                  .map((_, i) => (
                    <SkeletonCard key={i} />
                  ))}
              </div>
            </div>
          ) : (
            filteredAssets &&
            filteredAssets.length > 0 && (
              <div className="relative w-full mt-4">
                <div className="flex items-center justify-between py-3">
                  <h4 className="text-[15px] text-steel-gray dark:text-white font-semibold">
                    {!!category?.categoryValue
                      ? 'Similar assets'
                      : 'Best deals'}
                  </h4>
                  <span
                    className="text-steel-gray dark:text-white text-[15px] cursor-pointer"
                    onClick={() => {
                      navigate(`${routes.assetCategory}/${1}`, {
                        state: {
                          name: 'Best deals',
                        },
                      });
                    }}
                  >
                    See All
                  </span>
                </div>
                <Carousel>
                  {filteredAssets.map((asset: any, i: number) => (
                    <AssetCard
                      asset={asset ?? []}
                      key={i}
                      showLike={category?.name !== 'My Assets'}
                    />
                  ))}
                </Carousel>
              </div>
            )
          )}
        </div>
      </div>

      {/* --------Modal---------- */}
      <Dialog
        open={show as boolean}
        onOpenChange={() => {
          setShow(false);
        }}
      >
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Buy Asset</DialogTitle>
          </DialogHeader>
          <form>
            <div className="mb-2">
              <Label htmlFor="title">Quantity</Label>
              <Input
                id="quantity"
                type="number"
                value={purchaseForm.quantity}
                max={assetDetails.availableUnits}
                onChange={(e) => handlePurchase(e)}
                placeholder="Enter your quantity"
                className="h-11"
                endIcon={
                  <p className="text-sm cursor-pointer" onClick={selectAll}>
                    All
                  </p>
                }
              />
            </div>
            <div>
              <Label htmlFor="title">Amount</Label>
              <Input
                className="cursor-not-allowed"
                id="amount"
                readOnly
                type="text"
                value={roundToDecimalPlaces(purchaseForm.amount as number, 2)}
              />
            </div>
          </form>
          <DialogFooter className="flex items-center justify-between w-full gap-3 mt-auto">
            <Button
              className="p-3.5 h-12 text-[15px] rounded-lg w-full font-normal"
              disabled={loading}
              variant="secondary"
              type="button"
              onClick={(e) => setShow(false)}
            >
              Close
            </Button>
            <Button
              disabled={(loading as boolean) || isSubmitDisabled}
              className={cn(
                'rounded-lg text-[15px] w-full font-medium disabled:opacity-50 outline-none p-3.5 h-12 flex items-center justify-center'
              )}
              type="button"
              onClick={(e) => proceed(e)}
            >
              {loading ? (
                <Icons.Spinner className="w-6 h-6 animate-spin" />
              ) : (
                'Proceed'
              )}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </ContainerLayout>
  );
};

export default AssetDetails;
