import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../ui/form';
import { useForm } from 'react-hook-form';
import PhoneNumber from '../telInput';
import { Icons } from '../../../../assets/icons';
import { Button } from '../../../ui/button';
import { TOKEN } from '../../../../utils/token';
import { Input } from '../../../ui/input';
import { useCookies } from 'react-cookie';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { toast } from 'sonner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetOtp, useGetTimeZones } from '../../../../helpers/api/useAuth';
import {
  GlobalContext,
  GlobalContextType,
} from '../../../../context/GlobalContext';
import { Checkbox } from '../../../ui/checkbox';
import { Label } from '../../../ui/label';
import PasswordChecklist from 'react-password-checklist';
import { SectionEnum } from '../../../../pages/register/register';
import { getErrorMessage } from '../../../../utils/helper';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useLocation } from 'react-router-dom';

interface Zone {
  id: string;
}

type Props = {
  setLoading: (arg0: boolean) => void;
  isLoading: boolean;
  next: (arg0: SectionEnum) => void;
};

export interface IUserAccount {
  firstname: string;
  lastname: string;
  pushNotificationId: string;
  email: string;
  password: string;
  defaultCurrencyId: number;
}

const UserForm = (props: Props) => {
  const [agree, setAgree] = useState<boolean>(false);
  const { dispatch: dispatchGlobal } = useContext(
    GlobalContext
  ) as GlobalContextType;
  const location = useLocation(); // Access the URL
  const searchParams = new URLSearchParams(location.search); // Parse the query string
  const referralCode = searchParams.get('ref'); // Get the "referral" parameter
  const { isLoading, setLoading, next } = props;
  const setCookie = useCookies()[1];
  const zonesRef = useRef<Zone[] | null>(null);
  const { data } = useGetTimeZones();
  const timezone = data?.data;
  const axiosPrivate = useAxiosPrivate();
  const getOtp = useGetOtp();

  const getTimeZone = useCallback(async () => {
    const timezoneOffset = new Date().getTimezoneOffset();
    let userTimeZone: string | number = timezoneOffset / 60;
    if (timezoneOffset === 0) {
      userTimeZone = `00:00:00`;
    } else if (userTimeZone < 0 && timezoneOffset !== 0) {
      userTimeZone = `0${Math.abs(userTimeZone)}:00:00`;
    } else if (userTimeZone > 0 && timezoneOffset !== 0) {
      userTimeZone = `-0${userTimeZone}:00:00`;
    }
    if (timezone && timezone && timezone.length) {
      const filteredZones = timezone.filter(
        (zone: { baseUTCOffSet: string }) =>
          zone.baseUTCOffSet === userTimeZone.toString()
      );
      zonesRef.current = filteredZones; // Update zonesRef with filtered zones
      // Now you can use zonesRef.current to access the filtered zones
    }
  }, [timezone]);

  useEffect(() => {
    getTimeZone();
  }, [getTimeZone]);

  const defaultValues: IUserAccount = {
    firstname: '',
    lastname: '',
    pushNotificationId: '',
    email: '',
    password: '',
    defaultCurrencyId: 1,
  };

  const form = useForm({
    defaultValues: defaultValues,
  });

  const {
    formState: { errors },
  } = form;

  const [passwordShown, setPasswordShown] = useState<boolean>(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const getCode = async (email: string) => {
    if (process.env.REACT_APP_ENV === 'development') {
      try {
        await axiosPrivate.get(`/user/temp-otp/${email}`);
      } catch (err: any) {
        console.error(
          err.response?.data?.responseMessage ?? 'An error occurred'
        );
      }
    }
  };

  const handleRegisterUser = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    form.handleSubmit(async (data) => {
      const regexPattern =
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])(?!.*\s).{8,}$/;
      if (!regexPattern.test(data.password)) {
        toast.warning('Password too weak');
        return;
      }
      setLoading(true);
      let payload = {
        ...data,
        accountType: 'User',
        referredBy: referralCode || '',
        timeZone: zonesRef.current && zonesRef.current[0]?.id,
      };
      try {
        const res = await axiosPrivate.post(`user/register`, payload);
        if (res && res.data) {
          setCookie(TOKEN.TOKENEXP, res.data.data.jwtTokenExpiry);
          setCookie(TOKEN.ACCESS, res.data.data.jwtToken);
          setCookie(TOKEN.ID, res.data.data.user?.id);
          setCookie(TOKEN.EMAIL, res.data.data.user?.email);
          dispatchGlobal({ type: 'SET_USER', payload: res.data.data.user });

          // Call getCode for development OTP
          await getCode(res.data.data.user?.email);

          // Call getOtp for production OTP
          await getOtp.mutateAsync({ otpType: 'Phone' });

          toast.success('Registration successful');
          next(SectionEnum.Verification);
        }
      } catch (err: any) {
        const message = getErrorMessage(err);
        toast.error(message);
      } finally {
        setLoading(false);
      }
    })();
  };
  const password = form.watch('password');

  return (
    <>
      <Form {...form}>
        <form
          onSubmit={handleRegisterUser}
          className="flex flex-col w-full gap-2"
        >
          <div className="grid grid-cols-2 gap-4">
            <FormField
              name="firstname"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: 'First name is required',
                },
                validate: (value) =>
                  value.trim() !== '' || 'First name cannot be empty',
              }}
              render={({ field }) => (
                <FormItem className="relative ">
                  <FormLabel className="font-normal">First Name</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type="text"
                      placeholder="Enter your first name"
                      autoComplete="off"
                      error={Boolean(errors.firstname)}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="lastname"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: 'Last name is required',
                },
                validate: (value) =>
                  value.trim() !== '' || 'Last name cannot be empty',
              }}
              render={({ field }) => (
                <FormItem className="relative ">
                  <FormLabel className="font-normal">Last Name</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type="text"
                      placeholder="Enter your last name"
                      autoComplete="off"
                      error={Boolean(errors.lastname)}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <FormField
            name="email"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: 'Email is required',
              },
            }}
            render={({ field }) => (
              <FormItem className="relative ">
                <FormLabel className="font-normal">Email</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="email"
                    placeholder="Enter your email"
                    autoComplete="off"
                    error={Boolean(errors.email)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <PhoneNumber form={form} />
          <FormField
            name="password"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: 'Password is required',
              },
              validate: (value) =>
                value.trim() !== '' || 'Password cannot be empty',
            }}
            render={({ field }) => (
              <FormItem className="relative ">
                <FormLabel className="font-normal">Password</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type={passwordShown ? 'text' : 'password'}
                    placeholder="Enter password"
                    error={Boolean(errors.password)}
                    className="h-12"
                    endIcon={
                      <span onClick={togglePassword}>
                        {!passwordShown ? (
                          <FontAwesomeIcon icon={faEye} />
                        ) : (
                          <FontAwesomeIcon icon={faEyeSlash} />
                        )}
                      </span>
                    }
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <PasswordChecklist
            rules={['minLength', 'specialChar', 'number', 'capital']}
            minLength={8}
            value={password}
            onChange={() => {
              form.setValue('password', password);
            }}
            iconSize={10}
          />
          <div className="flex flex-col gap-2 mt-2">
            <h1 className="text-sm font-normal">
              Do you agree to our Terms and Conditions?
            </h1>
            <div className="flex items-center space-x-3 space-y-0">
              <Checkbox
                checked={agree}
                onCheckedChange={(checked) => setAgree(checked === true)}
              />
              <div className="leading-none">
                <Label onClick={() => setAgree((prev) => !prev)}>
                  I have read and agree to the Terms and Conditions.
                </Label>
              </div>
            </div>
          </div>

          <Button className="mt-4" disabled={isLoading || !agree}>
            {isLoading ? (
              <Icons.Spinner className="w-5 h-5 animate-spin" />
            ) : (
              'Create Account'
            )}
          </Button>
        </form>
      </Form>
    </>
  );
};
export default UserForm;
