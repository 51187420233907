import React from 'react';
import { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';

interface QuillEditorProps {
  error?: boolean;
  setValue?: (name: string, value: string) => void;
  defaultValue: string;
  triggerDefaultValue?: boolean;
}

const modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
];
const QuillWrapper = React.lazy(() =>
  import('react-quill').then((module) => ({ default: module.default }))
);

const QuillEditor: React.FC<QuillEditorProps> = ({
  error,
  setValue,
  defaultValue,
  triggerDefaultValue,
}) => {
  const [editorHtml, setEditorHtml] = useState<string>(defaultValue);

  const handleChange = (html: string) => {
    setEditorHtml(html);
    setValue && setValue('description', html);
  };

  const handleSetEditorValue = (value: string) => {
    setEditorHtml(value);
  };

  useEffect(() => {
    if (defaultValue) {
      handleSetEditorValue(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerDefaultValue]);
  // console.log(defaultValue);
  return (
    <QuillWrapper
      theme="snow"
      style={
        error
          ? { border: '1px solid red', borderRadius: '9px', color: '#111827' }
          : { color: '#111827' }
      }
      placeholder={'Enter property description'}
      onChange={handleChange}
      value={editorHtml}
      modules={modules}
      formats={formats}
    />
  );
};

export default QuillEditor;
