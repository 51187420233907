import { useState, useEffect } from 'react';
import axios, { type AxiosError } from 'axios';
import { type SubmitHandler } from 'react-hook-form';
import { useUrls } from '../useUrls';

const useNewsletterSubscription = (ref: string) => {
  const { user } = useUrls();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<{ status: boolean; message: string }>({
    status: false,
    message: '',
  });
  const [errorMessage, setErrorMessage] = useState<string>('');

  const onSubmit: SubmitHandler<{ email: string }> = async (data: {
    email: string;
  }) => {
    try {
      setLoading(true);
      setError(false);
      setErrorMessage('');

      await axios.post(
        `${user}/newsletter-subscription${
          ref !== null && ref !== '' ? `?refBy=${ref}` : ''
        }`,
        data
      );

      // Handle successful response (you might want to do something with the response data)
      setSuccess({ status: true, message: 'Subscription successful' });

      // Reset the form
      // You may want to reset the form here if needed
    } catch (error: any) {
      // Handle errors
      const axiosError = error as AxiosError;
      const errorMessage: string =
        (axiosError.response?.data as { responseMessage?: string } | undefined)
          ?.responseMessage ?? 'An error occurred';

      setError(true);
      setErrorMessage(String(errorMessage) ?? 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Clear success and error messages after a delay (e.g., 5 seconds)
    const timer = setTimeout(() => {
      setSuccess({ status: false, message: '' });
      setError(false);
      setErrorMessage('');
    }, 5000);

    return () => {
      clearTimeout(timer);
    }; // Clear the timer on unmount or whenever useEffect is re-run
  }, [success, error]);

  return {
    loading,
    error,
    success,
    errorMessage,
    onSubmit,
  };
};

export default useNewsletterSubscription;
