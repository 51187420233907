import React, { useState, useEffect, useContext } from 'react';
import OtpInput from 'react-otp-input';
import { GlobalContext, GlobalContextType } from '../../context/GlobalContext';

interface OtpInputComponentProps {
  otp: string;
  setOtp: (otp: string) => void;
  inputMode?: 'numeric' | 'text';
}

const OtpInputComponent: React.FC<OtpInputComponentProps> = ({
  otp,
  setOtp,
  inputMode = 'numeric',
}) => {
  const [inputSize, setInputSize] = useState(60);
  const { isDarkmode } = useContext(GlobalContext) as GlobalContextType;

  useEffect(() => {
    const handleResize = () => {
      setInputSize(window.innerWidth >= 640 ? 60 : 50);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Define colors based on dark mode
  const backgroundColor = isDarkmode ? '#333333' : '#F1F5F9';
  const textColor = isDarkmode ? '#FFFFFF' : '#000000';
  const borderColor = isDarkmode ? '#555555' : 'transparent';

  return (
    <OtpInput
      value={otp}
      onChange={setOtp}
      numInputs={6}
      containerStyle={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}
      inputType={inputMode === 'numeric' ? 'number' : 'text'}
      shouldAutoFocus={true}
      inputStyle={{
        border: `1px solid ${borderColor}`,
        outline: 'none',
        borderRadius: '12px',
        width: `${inputSize}px`,
        height: `${inputSize}px`,
        fontSize: '16px',
        color: textColor,
        fontWeight: '500',
        caretColor: textColor,
        backgroundColor: backgroundColor,
      }}
      renderSeparator={<span></span>}
      renderInput={(props) => <input {...props} inputMode={inputMode} />}
    />
  );
};

export default OtpInputComponent;
