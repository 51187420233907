import { RouteObject } from 'react-router-dom';
import Landing from '../pages/welcomePage';
import Register from '../pages/register/register';
import LoginPage from '../pages/login/Login';
import Website from '../pages/home';
import About from '../pages/home/About';
import Contact from '../pages/home/Contact';
import Terms from '../pages/home/Terms';
import Privacy from '../pages/home/Privacy';
import AppHome from '../pages/appHome/appHome';
import HomePage from '../pages/dashboard';
import MarketPage from '../pages/marketPage/marketPage';
import ProfilePage from '../pages/profile/profilePage';
import OrderHistoryPage from '../pages/profile/orderHistoryPage';
import AssetDetailsPage from '../pages/assetDetails/AssetDetailsPage';
import OwnerPage from '../pages/ownerPage/ownerPage';
import CheckoutPage from '../pages/checkoutPage/CheckoutPage';
import PaymentPage from '../pages/paymentPage/paymentPage';
import ExtPaymentPage from '../pages/externalPaymentPage/ExtPaymentPage';
import PasswordReset from '../pages/resetPassword/passwordReset';
import RequireAuth from '../components/RequireAuth';
import MyAssetPage from '../pages/myAssetPage/myAssetPage';
import SavedAssetPage from '../pages/savedAsset/SavedAssetPage';
import NotFound from '../pages/notFoundPage';
import AssetCategory from '../pages/assetCategory';
import AddAssets from '../pages/addAssets';
import EditAssets from '../pages/editAsset';
import FAQ from '../pages/home/FAQ';
import { Suspense } from 'react';
import { Icons } from '../assets/icons';
import React from 'react';
import VerifyAccount from '../pages/verify';
import ProfileUpdate from '../components/dashboard/profile/profileUpdate';
import ReferralDashboard from '../components/dashboard/profile/ReferralDashboard';
import RefundPolicy from '../pages/home/RefundPolicy';

export const routes = {
  // Public routes
  home: '/',
  about: '/about',
  contactUs: '/contact-us',
  termsAndConditions: '/terms-and-conditions',
  faq: '/frequently-asked-questions',
  privacyPolicy: '/privacy-policy',
  refundPolicy: '/refund-policy',
  careers: '#',
  welcome: '/welcome',
  // Auth routes
  register: '/register',
  login: '/login',

  confirmPayment: '/confirm-payment',
  verifyAccount: '/verify',
  passwordReset: '/password-reset',
  // Protected routes
  dashboard: '/home',
  market: '/market',
  profile: '/profile',
  profileUpdate: '/profile/update',
  referralDashboard: '/profile/referral-dashboard',
  myAsset: '/my-asset',
  savedAsset: '/saved-asset',
  orderHistory: '/order-history',
  addAsset: '/asset/add',
  editAsset: '/asset/edit',
  assetCategory: '/asset/category',
  asset: '/asset',
  checkout: '/checkout',
  assetOwners: '/asset',
  payment: '/payment',
};

export const appRoutes: RouteObject[] = [
  { path: '*', element: <NotFound /> },
  { path: routes.home, element: <Website /> },
  { path: routes.about, element: <About /> },
  { path: routes.contactUs, element: <Contact /> },
  { path: routes.termsAndConditions, element: <Terms /> },
  { path: routes.faq, element: <FAQ /> },
  { path: routes.privacyPolicy, element: <Privacy /> },
  { path: routes.refundPolicy, element: <RefundPolicy /> },
  { path: routes.welcome, element: <Landing /> },
  { path: routes.register, element: <Register /> },
  { path: routes.login, element: <LoginPage /> },
  { path: `${routes.passwordReset}/:id/:email`, element: <PasswordReset /> },
  { path: routes.verifyAccount, element: <VerifyAccount /> },
  { path: routes.confirmPayment, element: <ExtPaymentPage /> },
  {
    element: <RequireAuth />,
    children: [
      {
        path: routes.home,
        element: <AppHome />,
        children: [
          { path: routes.dashboard, element: <HomePage /> },
          { path: routes.market, element: <MarketPage /> },
          { path: routes.profile, element: <ProfilePage /> },
          { path: routes.profileUpdate, element: <ProfileUpdate /> },
          { path: routes.referralDashboard, element: <ReferralDashboard /> },
          { path: routes.myAsset, element: <MyAssetPage /> },
          { path: routes.savedAsset, element: <SavedAssetPage /> },
          { path: routes.orderHistory, element: <OrderHistoryPage /> },
          {
            path: routes.addAsset,
            element: (
              <Suspense fallback={<Icons.Spinner />}>
                <AddAssets />
              </Suspense>
            ),
          },
          { path: `${routes.editAsset}/:id`, element: <EditAssets /> },
          { path: `${routes.assetCategory}/:id`, element: <AssetCategory /> },
          { path: `${routes.asset}/:id`, element: <AssetDetailsPage /> },
          { path: routes.checkout, element: <CheckoutPage /> },
          { path: `${routes.assetOwners}/:id/owners`, element: <OwnerPage /> },
        ],
      },
      { path: routes.payment, element: <PaymentPage /> },
    ],
  },
];
