import React, { useEffect, useState } from 'react';
import { Button } from '../../ui/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '../../ui/carousel';
import Autoplay from 'embla-carousel-autoplay';
import { Icons } from '../../../assets/icons';
import { type CarouselApi } from '../../ui/carousel';
import { cn } from '../../../lib/utils';

interface Image {
  documentLink: string;
}

interface ImageGridProps {
  images: Image[];
}

const ImageGrid: React.FC<ImageGridProps> = ({ images }) => {
  const [showAll, setShowAll] = useState<boolean>(false);
  const [showCarousel, setShowCarousel] = useState<boolean>(false);
  const [api, setApi] = React.useState<CarouselApi>();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [imagesLoaded, setImagesLoaded] = useState<boolean[]>([]);
  const gridImages = images.slice(1, 5);
  const [current, setCurrent] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [startIndex, setStartIndex] = useState<number>(0);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 640);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  useEffect(() => {
    setImagesLoaded(new Array(images.length).fill(false));
  }, [images]);

  useEffect(() => {
    if (!api) return;
    setCount(api.scrollSnapList().length);
    setCurrent(api.selectedScrollSnap() + 1);
    api.on('select', () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  useEffect(() => {
    if (api && showCarousel) {
      api.scrollTo(startIndex);
    }
  }, [api, showCarousel, startIndex]);

  const handleImageLoad = (index: number) => {
    setImagesLoaded((prev) => {
      const newState = [...prev];
      newState[index] = true;
      return newState;
    });
  };

  const handleImageClick = (index: number) => {
    setStartIndex(index);
    setShowCarousel(true);
  };

  const renderMobileCarousel = () => (
    <Carousel
      setApi={setApi}
      plugins={[Autoplay({ playOnInit: false, delay: 7000 })]}
      className="w-full h-[300px] sm:h-[400px] relative"
    >
      <CarouselContent>
        {images?.map((image, index) => (
          <CarouselItem key={index} className="w-full h-[300px] sm:h-[400px]">
            {!imagesLoaded[index] && (
              <div className="w-full h-full bg-muted animate-pulse" />
            )}
            <img
              src={image?.documentLink}
              alt={`Slide ${index + 1}`}
              className={`object-cover w-full h-full cursor-pointer ${
                imagesLoaded[index] ? 'block' : 'hidden'
              }`}
              onLoad={() => handleImageLoad(index)}
              onClick={() => setShowAll(true)}
            />
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselPrevious className="left-2" />
      <CarouselNext className="right-2" />
      <div className="absolute px-4 py-2 text-sm text-foreground bg-background bottom-4 right-4 rounded-2xl">
        {current} of {count}
      </div>
    </Carousel>
  );

  const renderDesktopGrid = () => (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-1 h-[300px] sm:h-[400px] xl:h-[500px] w-full">
      <div className="w-full col-span-1 row-span-1 overflow-hidden sm:col-span-2 sm:row-span-2">
        {!imagesLoaded[0] && (
          <div className="animate-pulse bg-muted w-full h-[300px] sm:h-[400px] xl:h-[500px] md:rounded-r-none rounded-2xl md:rounded-l-2xl" />
        )}
        <img
          src={images[0]?.documentLink}
          alt="Main"
          className={`object-cover w-full h-[300px] sm:h-[400px] xl:h-[500px] md:rounded-r-none cursor-pointer rounded-2xl md:rounded-l-2xl ${
            imagesLoaded[0] ? 'block' : 'hidden'
          }`}
          onClick={() => handleImageClick(0)}
          onLoad={() => handleImageLoad(0)}
        />
      </div>
      {gridImages.map((image, index: number) => (
        <div
          key={index}
          className={`relative overflow-hidden bg-muted md:block w-full h-full hidden ${
            index === 1
              ? 'sm:rounded-tr-2xl'
              : gridImages.length === 4 &&
                index === gridImages.length - 1 &&
                'rounded-br-2xl'
          }`}
        >
          {!imagesLoaded[index + 1] && (
            <div
              className={cn(
                'w-full h-full bg-muted animate-pulse max-h-[260px]',
                { 'sm:rounded-tr-2xl': index === 1 },
                {
                  'rounded-br-2xl':
                    gridImages.length === 4 && index === gridImages.length - 1,
                }
              )}
            />
          )}
          <img
            src={image?.documentLink}
            alt={`Grid ${index + 1}`}
            onClick={() => handleImageClick(index + 1)}
            className={`object-cover w-full h-full cursor-pointer max-h-[260px] ${
              imagesLoaded[index + 1] ? 'block' : 'hidden'
            }`}
            onLoad={() => handleImageLoad(index + 1)}
          />
        </div>
      ))}
      {images.length > 5 && (
        <Button
          className="absolute px-4 py-2 text-sm text-black bg-background rounded-2xl bottom-4 right-4"
          onClick={() => setShowAll(true)}
        >
          Show all photos
        </Button>
      )}
    </div>
  );

  return (
    <div className="relative">
      {isMobile ? renderMobileCarousel() : renderDesktopGrid()}

      {showAll && (
        <div className="fixed inset-0 z-[9999] overflow-auto transition-all duration-500 ease-in-out bg-background">
          <button
            className="absolute w-10 h-10 text-sm rounded-full top-4 left-4 shrink-0 bg-muted"
            onClick={() => setShowAll(false)}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
          <div className="md:grid grid-cols-2 columns-2 gap-4 mt-16 w-full max-w-[800px] mx-auto p-4">
            {images?.map((image, index: number) => (
              <img
                key={index}
                src={image?.documentLink}
                onClick={() => handleImageClick(index)}
                alt={`Full ${index + 1}`}
                className={`object-cover w-full mb-4 rounded-2xl cursor-pointer ${
                  index % 3 === 0
                    ? 'md:col-span-2 h-auto md:h-[500px]'
                    : 'md:h-[250px] h-auto'
                }`}
              />
            ))}
          </div>
        </div>
      )}
      {showCarousel && (
        <div className="fixed inset-0 z-[9999] flex flex-col items-center justify-center space-y-5 overflow-auto transition-all duration-500 ease-in-out bg-black">
          <button
            className="absolute flex items-center justify-center w-10 h-10 gap-4 text-sm text-white rounded-full top-4 left-4 bg-muted"
            onClick={() => setShowCarousel(false)}
          >
            <Icons.CloseIcon className="w-3.5 h-3.5 text-white" />
          </button>
          <div className="w-full max-w-5xl mx-auto ">
            <Carousel
              setApi={setApi}
              plugins={[Autoplay({ playOnInit: false, delay: 7000 })]}
              className="w-full"
            >
              <CarouselContent>
                {images &&
                  images.length > 0 &&
                  images?.map((_, index) => (
                    <CarouselItem
                      className="w-full h-auto rounded-xl"
                      key={index}
                    >
                      <img
                        className="object-contain w-full h-full max-h-[700px] max-w-full"
                        src={
                          _.documentLink ??
                          '/assets/images/default-fallback-image.png'
                        }
                        alt=""
                      />
                    </CarouselItem>
                  ))}
              </CarouselContent>
              {images && images?.length > 0 && (
                <>
                  <CarouselPrevious className="ml-14" />
                  <CarouselNext className="mr-16" />
                </>
              )}
            </Carousel>
            <div className="py-2 text-sm text-center text-white">
              {current} of {count}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageGrid;
