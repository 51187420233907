import React, { useEffect } from 'react';
import { CheckCircle2, AlertCircle, X } from 'lucide-react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '../../lib/utils';

export interface FormStatus {
  type: 'success' | 'error' | null;
  message: string;
}

const formAlertVariants = cva(
  'flex items-center gap-2 rounded-[10px] text-sm',
  {
    variants: {
      variant: {
        default: 'p-4 mb-6 border',
        minimal: 'p-0 mb-2 justify-center',
      },
      status: {
        success: '',
        error: '',
      },
    },
    compoundVariants: [
      {
        variant: 'default',
        status: 'success',
        class:
          'bg-green-50 dark:bg-green-900/20 text-green-700 dark:text-green-300 border-green-200 dark:border-green-800',
      },
      {
        variant: 'default',
        status: 'error',
        class:
          'bg-red-50 dark:bg-red-900/20 text-red-700 dark:text-red-300 border-red-200 dark:border-red-800',
      },
      {
        variant: 'minimal',
        status: 'success',
        class: 'text-green-700 dark:text-green-300 text-center',
      },
      {
        variant: 'minimal',
        status: 'error',
        class: 'text-red-700 dark:text-red-300 text-center',
      },
    ],
    defaultVariants: {
      variant: 'default',
      status: 'success',
    },
  }
);

interface FormAlertProps
  extends Omit<VariantProps<typeof formAlertVariants>, 'status'> {
  status: FormStatus;
  onClose: () => void;
  className?: string;
}

export const FormAlert: React.FC<FormAlertProps> = ({
  status,
  onClose,
  variant = 'default',
  className,
}) => {
  useEffect(() => {
    // Only set timer if there's an actual status
    if (status.type) {
      const timer = setTimeout(() => {
        onClose();
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [status.type, status.message, onClose]);

  if (!status.type) return null;

  const Icon = status.type === 'success' ? CheckCircle2 : AlertCircle;

  return (
    <div
      className={cn(
        formAlertVariants({ variant, status: status.type }),
        className
      )}
      role="alert"
    >
      <div
        className={cn('flex items-center flex-grow gap-2', {
          'justify-center': variant === 'minimal',
        })}
      >
        <Icon className="flex-shrink-0 w-5 h-5" aria-hidden="true" />
        <p>{status.message}</p>
      </div>
      {variant === 'default' && (
        <button
          onClick={onClose}
          className="transition-colors text-muted-foreground hover:text-foreground"
          aria-label="Close alert"
        >
          <X className="w-4 h-4" />
        </button>
      )}
    </div>
  );
};
