import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { faFileLines } from '@fortawesome/free-regular-svg-icons';
import {
  faAngleRight,
  faCheck,
  faAngleDown,
  faAngleUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import {
  OnboardContext,
  OnboardContextType,
} from '../../../context/OnboardContext';
import { toast } from 'sonner';
import { Icons } from '../../../assets/icons';
import React from 'react';
import { cn } from '../../../lib/utils';
import { useCookies } from 'react-cookie';
import { useQueryClient } from '@tanstack/react-query';
import { IUserResponse } from '../../../interface/user';
import { SectionEnum } from '../../../pages/register/register';
import { routes } from '../../../utils/route';
import { Button } from '../../ui/button';
// import { useCookies } from 'react-cookie';
// import { useQueryClient } from '@tanstack/react-query';

type RequiredStepsProps = {
  next: (arg0: SectionEnum) => void;
  optionalDoc: {
    id: number;
    isIdentityCard: boolean;
    name: string;
    isRequired: boolean;
  }[];
  reqDocuments: {
    id: number;
    isIdentityCard: boolean;
    name: string;
    isRequired: boolean;
  }[];
  isLoading: boolean;
  requiredOptional: number;
};

const RequiredSteps = ({
  next,
  optionalDoc,
  reqDocuments,
  isLoading,
  requiredOptional,
}: RequiredStepsProps) => {
  const navigate = useNavigate();
  const { filesUploaded, documentsUploaded, dispatch, clearUploads } =
    useContext(OnboardContext) as OnboardContextType;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const cookies = useCookies()[0];
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  const [isVisible, setVisibility] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const dropDownList = () => {
    setVisibility(!isVisible);
  };

  const navigateToPage = (doc: any) => {
    dispatch({ type: 'SET_FILE', payload: doc });
    if (!doc.isIdentityCard && doc.name === 'Profile Picture') {
      next(SectionEnum.ProfilePicture);
    } else {
      next(SectionEnum.FileUpload);
    }
  };

  const uploaded = (id: any) => {
    let found = filesUploaded.filter((item: any) => item?.documentId === id);
    if (found && found.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const submitDoc = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (!filesUploaded.length) {
      toast.warning('Please upload a document');
      return;
    }
    // Check if all required documents are present in filesUploaded
    const requiredDocumentsPresent = reqDocuments.every((requiredDoc: any) =>
      filesUploaded.some((file: any) => file.documentId === requiredDoc.id)
    );

    if (!requiredDocumentsPresent) {
      toast.warning('Upload all required documents');
      return;
    }

    // Get the number of optional documents
    const optionalCount = documentsUploaded.filter(
      (doc: any) => !doc.isRequired
    ).length;

    // Check if the number of optional documents is sufficient
    if (optionalCount < requiredOptional) {
      toast.warning(`Upload ${requiredOptional} optional documents`);
      return;
    }

    setLoading(true);

    try {
      const payload = {
        documents: [...filesUploaded], // Ensure filesUploaded is in the correct format expected by the API
      };

      // Assuming axiosPrivate is an axios instance with private settings
      await axiosPrivate.post('user/verification-document-upload', payload);
      clearUploads();
      navigate(routes.dashboard);
      queryClient.cancelQueries({
        queryKey: ['get-user-details', cookies?.musha_userId],
      });

      // Get the current user data from the query cache
      const currentUserData = queryClient.getQueryData<IUserResponse>([
        'get-user-details',
        cookies?.musha_userId,
      ]);

      if (currentUserData) {
        // Update the accountStatus to 'AwaitingApproval'
        const updatedUserData = {
          ...currentUserData,
          data: {
            ...currentUserData?.data,
            accountStatus: 'AwaitingVerificationApproval',
          },
        };

        // Set the updated user data in the query cache
        queryClient.setQueryData(
          ['get-user-details', cookies?.musha_userId],
          updatedUserData
        );
        toast.success('Documents uploaded successfully');
      }
      setLoading(false);
    } catch (err: any) {
      toast.warning(
        err?.response?.data?.responseMessage || 'An error occurred'
      );
    } finally {
      setLoading(false); // Set loading to false after all operations are completed
    }
  };

  return (
    <div className="w-full max-w-[459px] mx-auto">
      <div className="flex flex-col w-full gap-3 text-center">
        <h4 className="text-2xl font-medium text-foreground">Required Steps</h4>
        <p className="text-sm text-light-grey">
          Here's what you need to do to set up your account.
        </p>
      </div>
      {isLoading ? (
        <div className="flex items-center justify-center py-10">
          <Icons.Spinner className="w-7 h-7 animate-spin" />
        </div>
      ) : (
        <>
          <div className="w-full py-5">
            {!!reqDocuments.length &&
              reqDocuments.map((list: any, i: number) => (
                <div key={i}>
                  <div
                    key={i}
                    className="flex items-center justify-between py-4 cursor-pointer"
                    onClick={() => navigateToPage(list)}
                  >
                    <div className="flex items-center justify-center gap-5">
                      <Icons.ImageIcon />
                      <div className="flex flex-col ">
                        <h4 className="font-medium text-foreground">
                          {list.name}
                        </h4>
                        <p className="text-xs text-primary">
                          This is a required field
                        </p>
                      </div>
                    </div>

                    {uploaded(list.id) ? (
                      <FontAwesomeIcon icon={faCheck} />
                    ) : (
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="text-light-grey"
                      />
                    )}
                  </div>
                  <hr key={i + 1} className="text-[#D1D1D1]" />
                </div>
              ))}
            <div
              className="flex items-center justify-between px-1 py-4 cursor-pointer"
              onClick={dropDownList}
            >
              <div className="flex items-center justify-center gap-5">
                <FontAwesomeIcon
                  icon={faFileLines}
                  size="lg"
                  className="text-foreground"
                />
                <div className="listText">
                  <h4 className="font-medium text-foreground">KYC Document</h4>
                  <p className="text-xs text-primary">
                    Upload at least {requiredOptional} document from the list
                    below
                  </p>
                </div>
              </div>

              {isVisible ? (
                <FontAwesomeIcon
                  icon={faAngleUp}
                  className="text-light-grey dark:text-foreground"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className="text-light-grey"
                />
              )}
            </div>
            <hr className={cn('hidden', { block: isVisible })} />
            <div
              id="pdDropdown"
              className="cursor-pointer dropdown"
              style={{ display: isVisible ? 'block' : 'none' }}
            >
              {!!optionalDoc.length &&
                optionalDoc.map((list: any, i: number) => (
                  <div key={i}>
                    <div
                      className="flex items-center justify-between gap-5 py-4 ml-8"
                      onClick={() => navigateToPage(list)}
                    >
                      <h4 className="font-medium text-foreground">
                        {list.name}
                      </h4>
                      {uploaded(list.id) ? (
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-light-grey dark:text-foreground"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          className="text-light-grey dark:text-foreground"
                        />
                      )}
                    </div>
                    <hr
                      key={i + 1}
                      style={{
                        display:
                          i !== optionalDoc.length - 1 ? 'block' : 'none',
                      }}
                    />
                  </div>
                ))}
            </div>
            <hr />
          </div>
          <div className="w-full mt-4">
            <Button
              onClick={(e) => submitDoc(e)}
              disabled={loading}
              className="w-full"
            >
              {!loading ? (
                'Complete SignUp'
              ) : (
                <Icons.Spinner className="w-6 h-6 animate-spin" />
              )}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default RequiredSteps;
