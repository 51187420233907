import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@radix-ui/themes/styles.css';
import { Theme } from '@radix-ui/themes';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthContextProvider } from './context/AuthContext';
import { OnboardContextProvider } from './context/OnboardContext';
import { GlobalContextProvider } from './context/GlobalContext';
import { ThemeContextProvider } from './context/ThemeContext';
import { Toaster } from 'sonner';
import Providers from './helpers/providers';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Providers>
      <AuthContextProvider>
        <OnboardContextProvider>
          <GlobalContextProvider>
            <ThemeContextProvider>
              <Toaster
                position="top-right"
                expand={false}
                richColors
                closeButton
                visibleToasts={3}
                toastOptions={{
                  duration: 3000,
                  style: {
                    borderRadius: '16px',
                    fontSize: '12px',
                  },
                }}
              />
              <Theme>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </Theme>
            </ThemeContextProvider>
          </GlobalContextProvider>
        </OnboardContextProvider>
      </AuthContextProvider>
    </Providers>
  </React.StrictMode>
);

reportWebVitals();
