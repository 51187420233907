import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import OtpInputComponent from './otpInputComponent';
import { Button } from '../ui/button';
import { Icons } from '../../assets/icons';
import { cn } from '../../lib/utils';
import { useResendOTP, useVerifyAccount } from '../../helpers/api/useAuth';
import { maskEmail, redirectHelper } from '../../utils/helper';
import Logo from '../core/logo';

const Verify = () => {
  const [otp, setOtp] = useState<string>('');
  const navigate = useNavigate();
  const [resendCountdown, setResendCountdown] = useState<number>(0);
  const { state } = useLocation();
  const {
    mutate: verifyAccount,
    isLoading: isVerifying,
    isSuccess: isVerified,
    isError: isVerifyError,
    errorMessage: verifyError,
    data: otpRes,
  } = useVerifyAccount();
  const { mutateAsync: resendOTP, isResendLoading } = useResendOTP(
    state?.email
  );

  const maskedEmail = maskEmail(state?.email);

  const handleVerify = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    verifyAccount({ email: state?.email, otp: otp });
  };

  const handleResend = async () => {
    try {
      await resendOTP({ email: state?.email });
      setResendCountdown(30);
    } catch (error) {
      toast.error('Resend OTP Error', {
        description: `Failed to resend OTP: ${error}`,
      });
    }
  };
  const redirect = useCallback(
    (data: any) => {
      redirectHelper(data, navigate);
    },
    [navigate]
  );

  useEffect(() => {
    if (isVerified) {
      toast.success('Verification', {
        description: 'Verification successful',
      });
      redirect(otpRes);
      // Clear the location state by navigating to the same route without state
      navigate(window.location.pathname, { replace: true, state: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVerified, otpRes, redirect]);

  useEffect(() => {
    if (isVerifyError) {
      toast.error('Verification Error', {
        description: `Verification failed ${verifyError}`,
      });
    }
  }, [isVerifyError, verifyError]);

  useEffect(() => {
    let countdown: ReturnType<typeof setTimeout> | null = null;
    if (resendCountdown > 0) {
      countdown = setTimeout(() => {
        setResendCountdown(resendCountdown - 1);
      }, 1000);
    }
    return () => {
      if (countdown) {
        clearTimeout(countdown);
      }
    };
  }, [resendCountdown]);

  return (
    <div className="max-w-md px-4 py-10 mx-auto space-y-6 text-center dark:bg-transparent sm:bg-white sm:px-8 rounded-xl">
      <header className="flex flex-col items-center justify-center gap-4">
        <Link to="/">
          <Logo type="icon" className="w-10 h-14" />
        </Link>
        <div className="flex flex-col items-center justify-center gap-2">
          <h1 className="text-2xl font-bold">Authenticate User</h1>
          <p className="text-[15px] text-muted-foreground">
            Enter the 6-digit verification code that was sent to {maskedEmail}
          </p>
        </div>
      </header>
      <form
        onSubmit={handleVerify}
        className="flex flex-col items-center justify-center w-full gap-6"
      >
        <OtpInputComponent otp={otp} setOtp={setOtp} />

        <div className="flex flex-col w-full space-y-5">
          <Button
            size="lg"
            type="submit"
            className={cn('text-sm w-full h-12 ')}
            disabled={otp.length < 6 || isVerifying || isVerified}
          >
            {isVerifying ? (
              <Icons.Spinner className="w-5 h-5 animate-spin" />
            ) : (
              ' Verify Account'
            )}
          </Button>

          <div className="flex items-center justify-center space-x-1 text-sm font-medium text-center text-muted-foreground">
            <p>Didn't receive code?</p>
            <button
              type="button"
              disabled={resendCountdown > 0 || isResendLoading}
              className={cn(
                'text-blue-600 bg-transparent border-none cursor-pointer',
                isResendLoading && 'cursor-not-allowed'
              )}
              onClick={handleResend}
            >
              {resendCountdown > 0
                ? `Resend in ${resendCountdown}s`
                : isResendLoading
                ? 'Resending...'
                : 'Resend'}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Verify;
