import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import React, { useEffect } from 'react';
import { Controller, type SubmitHandler, useForm } from 'react-hook-form';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import useNewsletterSubscription from '../../helpers/api/useSubscription';
import { cn } from '../../lib/utils';
import { Icons } from '../../assets/icons';
import {
  InstagramLogoIcon,
  LinkedInLogoIcon,
  TwitterLogoIcon,
} from '@radix-ui/react-icons';
import Logo from '../../components/core/logo';
import { routes } from '../../utils/route';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
});

interface FormValues {
  email: string;
}

const Footer: React.FC = () => {
  const footerLinks = {
    company: [
      { name: 'About Us', href: routes.about },
      { name: 'Careers', href: routes.careers },
      { name: 'FAQs', href: routes.faq },
      { name: 'Contact Us', href: routes.contactUs },
      { name: 'Terms & Conditions', href: routes.termsAndConditions },
      { name: 'Privacy Policy', href: routes.privacyPolicy },
      { name: 'Refund Policy', href: routes.refundPolicy },
    ],
    location: [
      { name: 'Lagos', href: '#' },
      { name: 'Abuja', href: '#' },
      { name: 'Rivers', href: '#' },
      { name: 'Enugu', href: '#' },
      { name: 'Anambra', href: '#' },
      { name: 'Delta', href: '#' },
    ],
    socials: [
      {
        name: 'Instagram',
        href: 'https://www.instagram.com/musha_realestate/',
      },
      {
        name: 'Facebook',
        href: 'https://www.facebook.com/profile.php?id=100085961174055',
      },
      {
        name: 'LinkedIn',
        href: 'https://www.linkedin.com/company/mushaafrica/',
      },
      { name: 'Twitter', href: 'http://twitter.com/musharealestate' },
      { name: 'Blog', href: 'https://blog.musha.com.ng/' },
    ],
  };
  const currentYear = new Date().getFullYear();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  let refValue = searchParams.get('ref');
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({ resolver: yupResolver(validationSchema) });

  const {
    loading,
    error,
    success,
    errorMessage,
    onSubmit: mutate,
  } = useNewsletterSubscription(refValue != null ? String(refValue) : '');

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    mutate(data);
  };

  useEffect(() => {
    if (success.status) {
      reset({
        email: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success.status]);

  return (
    <>
      <div className="mb-10 sm:p-4">
        <div className="footer-card  app-container px-6 pt-10 mx-auto bg-[#6E7DAB] flex md:flex-row flex-col gap-10 md:gap-6 items-center justify-center text-center md:text-start md:justify-between sm:rounded-3xl lg:px-20 ">
          <div className="flex flex-col gap-4 text-white">
            <h4 className="text-3xl font-bold leading-[48px]">
              Leading Real Estate <br /> into the future…
            </h4>
            <p className="text-[15px]">
              Musha is available on iOS, Android and Web.
            </p>
            <div className="flex items-center gap-3">
              <img src="/AppStore.png" alt="" className="h-auto w-28" />
              <img src="/PlayStore.png" alt="" className="h-auto w-28" />
            </div>
          </div>
          <div className="">
            <img src="/mobileMockup.png" alt="mockup" />
          </div>
        </div>
      </div>

      {location.pathname === '/' && (
        <div className="grid items-center grid-cols-1 py-10 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-3 app-container">
          <div className="h-auto max-w-4xl col-span-2">
            {' '}
            <img
              src="/MS_Startups_FoundersHub.png"
              alt="mockup"
              className="w-full h-full rounded-3xl"
            />
          </div>

          <h1 className="text-3xl sm:4xl lg:text-6xl text-steel-gray">
            Proud to be part of the Microsoft for Startups Founders Program!
          </h1>
        </div>
      )}

      <div className="relative overflow-hidden">
        <img
          src="/newsletter.jpg"
          alt=""
          className="absolute inset-0 object-cover object-right w-full h-full -z-10 md:object-center"
        />
        <div className="w-full h-full py-24 bg-gray-900 bg-opacity-80 sm:py-32">
          <div className="px-6 mx-auto max-w-7xl lg:px-8 ">
            <div className="max-w-2xl gap-6 mx-auto mt-10 space-y-10 lg:mx-0 lg:max-w-none">
              <h1 className="text-4xl font-semibold leading-9 text-center text-white">
                Subscribe to our Beta Community
              </h1>
              <p className="max-w-xl mx-auto mt-6 text-base leading-normal text-center text-white">
                Sign up with your email address to gain exclusive perks and
                rewards, receive offers, and get updates prior to launch.
              </p>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col items-center w-full gap-4 mx-auto sm:flex-row lg:w-5/12"
              >
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <>
                      <input
                        className={cn(
                          'bg-white text-base w-full font-medium leading-none p-4 focus:outline-none rounded-lg',
                          {
                            'border-red-600 focus:border-red-500 bg-white':
                              errors.email,
                          }
                        )}
                        type="email"
                        {...field}
                        placeholder="Enter email address"
                      />
                    </>
                  )}
                />
                <button
                  type="submit"
                  disabled={loading}
                  className="items-center text-center justify-center flex w-40 hover:bg-[#8897c5] bg-[#6E7DAB] rounded-lg text-base leading-none text-white py-[18px] px-6 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#7185c0]"
                >
                  {loading ? (
                    <Icons.Spinner className="w-5 h-5 animate-spin" />
                  ) : (
                    'Join us'
                  )}
                </button>
              </form>
              <div className="text-center">
                {errors?.email != null && !error && (
                  <span className="text-sm text-red-600">
                    {errors?.email?.message}
                  </span>
                )}
                {error != null && (
                  <span className="text-sm text-red-600">
                    {errorMessage ??
                      'There was an error subscribing to the newsletter.'}
                  </span>
                )}
                {success?.status && (
                  <span className="text-sm text-green-600">
                    {success?.message}
                  </span>
                )}
              </div>

              <div className="flex items-center justify-center space-x-5">
                <a
                  href="https://www.linkedin.com/company/mushaafrica/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedInLogoIcon className="w-5 h-5 text-white" />
                </a>
                <a
                  href="http://twitter.com/musharealestate"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TwitterLogoIcon className="w-5 h-5 text-white" />
                </a>
                <a
                  href="https://www.instagram.com/musha_realestate/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramLogoIcon className="w-5 h-5 text-white" />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=100085961174055"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {' '}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    id="facebook"
                    className="w-5 h-5 fill-white"
                  >
                    <path
                      fill="none"
                      stroke="#ffffff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                      d="M7.5 15.5H2c-.83 0-1.5-.67-1.5-1.5V2C.5 1.17 1.17.5 2 .5h12c.83 0 1.5.67 1.5 1.5v12c0 .83-.67 1.5-1.5 1.5h-3.5V10H12l1-2.5h-2.5v-2c0-.55.45-.5 1-.5h1V2.5h-2c-.83 0-1.58.34-2.12.88S7.5 4.67 7.5 5.5v2h-2V10h2v5.5z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="relative px-4 py-12 bg-white text-primary-100 sm:px-6 lg:px-8">
        <div className="pb-28 app-container">
          <div className="grid grid-cols-2 gap-8 md:grid-cols-4">
            <div className="col-span-2 md:col-span-1">
              <Logo type="icon" className="w-auto h-16 mb-4" />
              <div className="flex flex-col items-start gap-3 mt-4">
                <img
                  src="/AppStoreBlack.png"
                  alt="Download on App Store"
                  className="h-auto w-28"
                />
                <img
                  src="/PlayStoreBlack.png"
                  alt="Get it on Google Play"
                  className="h-auto w-28"
                />
              </div>
            </div>
            {Object.entries(footerLinks).map(([category, links]) => (
              <div key={category}>
                <h3 className="mb-4 text-lg font-medium text-[#04093F] capitalize">
                  {category}
                </h3>
                <ul className="space-y-2">
                  {links.map((link) => (
                    <li key={link.name}>
                      <Link
                        to={link.href}
                        className="transition-colors text-primary-100 hover:underline"
                      >
                        {link.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        <div className="absolute bottom-0 left-0 w-full text-center text-primary-100 border-t border-[#E6E6EC] py-7">
          <p> Copyright, Musha {currentYear}</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
