import React, { createContext, useReducer, Dispatch, ReactNode } from 'react';

export interface OnboardFile {
  name: string;
  size: number;
  type: string;
  lastModified: number;
}

interface OnboardState {
  fileToBeUploaded: OnboardFile | null;
  filesUploaded: OnboardFile[];
  documentsUploaded: string[];
  isRefetch: boolean;
  triggerReload: boolean;
  clearDocument: boolean;
}

const INITIAL_STATE: OnboardState = {
  fileToBeUploaded: null,
  filesUploaded: [],
  documentsUploaded: [],
  isRefetch: false,
  triggerReload: false,
  clearDocument: false,
};

type OnboardAction =
  | { type: 'SET_FILE'; payload: OnboardFile }
  | { type: 'UPDATE_DOC'; payload: OnboardFile }
  | { type: 'DOCS_UPLOADED'; payload: string }
  | { type: 'REFETCH_USER_DETAILS'; payload: boolean }
  | { type: 'TRIGGER_USER_RELOAD'; payload: boolean }
  | { type: 'CLEAR_DOCUMENT_LOCAL_STATE'; payload: boolean }
  | { type: 'CLEAR_UPLOADS' };

export interface OnboardContextType {
  fileToBeUploaded: OnboardFile | null;
  filesUploaded: OnboardFile[];
  documentsUploaded: string[];
  isRefetch: boolean;
  dispatch: Dispatch<OnboardAction>;
  clearUploads: () => void; // Add clearUploads to the context type
  triggerReload: boolean;
  clearDocument: boolean;
}

export const OnboardContext = createContext<OnboardContextType | null>(null);

const OnboardReducer = (
  state: OnboardState,
  action: OnboardAction
): OnboardState => {
  switch (action.type) {
    case 'SET_FILE':
      return {
        ...state,
        fileToBeUploaded: action.payload,
      };
    case 'UPDATE_DOC':
      return {
        ...state,
        filesUploaded: [...state.filesUploaded, action.payload],
      };
    case 'DOCS_UPLOADED':
      return {
        ...state,
        documentsUploaded: [...state.documentsUploaded, action.payload],
      };
    case 'REFETCH_USER_DETAILS':
      return {
        ...state,
        isRefetch: action.payload,
      };
    case 'TRIGGER_USER_RELOAD':
      return {
        ...state,
        triggerReload: action.payload,
      };
    case 'CLEAR_DOCUMENT_LOCAL_STATE':
      return {
        ...state,
        triggerReload: action.payload,
      };
    case 'CLEAR_UPLOADS':
      return {
        ...state,
        fileToBeUploaded: null,
        filesUploaded: [],
        documentsUploaded: [],
        clearDocument: true,
      };
    default:
      return state;
  }
};

interface OnboardContextProviderProps {
  children: ReactNode;
}

export const OnboardContextProvider = ({
  children,
}: OnboardContextProviderProps) => {
  const [state, dispatch] = useReducer(OnboardReducer, INITIAL_STATE);
  const clearUploads = () => {
    dispatch({ type: 'CLEAR_UPLOADS' });
  };
  return (
    <OnboardContext.Provider
      value={{
        fileToBeUploaded: state.fileToBeUploaded,
        filesUploaded: state.filesUploaded,
        documentsUploaded: state.documentsUploaded,
        isRefetch: state.isRefetch,
        triggerReload: state.triggerReload,
        clearDocument: state.clearDocument,
        clearUploads,
        dispatch,
      }}
    >
      {children}
    </OnboardContext.Provider>
  );
};
