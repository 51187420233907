import React from 'react';
import Footer from './Footer';
import Nav from './Navbar';
import { useEffect } from 'react';

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Nav />
      <div className="flex flex-col gap-6 app-container">
        <section className="py-6 space-y-4 sm:space-y-0">
          <h4 className="text-4xl md:text-6xl font-bold text-[#04093F] md:leading-[80px]">
            Help is just a click away...
          </h4>
          <p className="text-[15px] text-[#04093F]">
            Get in touch with us using the channels below.
          </p>
        </section>
        <section className="flex flex-col items-center justify-between gap-4 py-4 mb-10 sm:gap-10 sm:flex-row border-y ">
          <div className="flex items-center gap-4">
            <img src="/email.png" alt="email_logo" />
            <div className="space-y-1">
              <h4 className="text-2xl md:text-[40px] text-primary">
                Send us an Email
              </h4>
              <p className="text-sm sm:text-base">
                Send us your queries via email and we will respond as soon as we
                can.
              </p>
            </div>
          </div>
          <a
            href="mailto:support@mushaapp.com"
            className="text-center w-full sm:w-44 hover:bg-[#8897c5] bg-[#6E7DAB] rounded-lg leading-none text-white p-4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#7185c0]"
          >
            Message
          </a>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
