import React, { useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMarketAssets } from '../../helpers/api/useAsset';
import ErrorMessage from '../../components/ui/error-fallback';
import Market from '../../components/dashboard/Market';
import { GlobalContext, GlobalContextType } from '../../context/GlobalContext';
import Pagination from '../../components/ui/pagination';

const MarketPage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { likedAssets } = useContext(GlobalContext) as GlobalContextType;

  const page = parseInt(searchParams.get('page') || '1', 10);
  const query = searchParams.get('query') || '';
  const category = searchParams.get('category') || '';

  const {
    loadingAssets,
    isError,
    marketAssets,
    refetch,
    errorMessage,
    totalPages,
  } = useMarketAssets(likedAssets, page, query, category as any);

  useEffect(() => {
    if (query || category) {
      const timer = setTimeout(() => {
        refetch();
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [query, category, refetch]);

  const handlePageChange = (newPage: number) => {
    setSearchParams({
      page: newPage.toString(),
      query,
      category,
    });
  };

  const handleClearResult = () => {
    setSearchParams({
      page: '1',
      query: '',
      category: '',
    });
  };

  if (isError) {
    return <ErrorMessage message={errorMessage} refetch={refetch} />;
  }

  return (
    <>
      <Market
        assets={marketAssets || []}
        search={!!query || !!category}
        clearResult={handleClearResult}
        isLoading={loadingAssets}
      />
      <Pagination
        currentPage={page}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default MarketPage;
