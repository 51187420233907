import React, { FC } from 'react';

const SkeletonCard: FC<any> = () => {
  return (
    <div className="max-w-[250px] flex-shrink-0 rounded-[20px] p-4 border w-full flex flex-col items-center justify-center gap-2 sm:gap-3">
      <div className="animate-pulse w-full h-[160px] bg-muted rounded-xl" />
      <div className="flex items-center w-full gap-3">
        <div className="w-16 h-10 bg-muted rounded-xl animate-pulse" />
        <div className="flex flex-col gap-1.5 w-full">
          <div className="w-full h-3 bg-muted rounded-xl animate-pulse" />
          <div className="w-full h-3 bg-muted rounded-xl animate-pulse" />
        </div>
      </div>
      <div className="flex flex-col items-start justify-between w-full gap-2">
        <hr className="bg-muted" />
        <div
          className="rounded-lg bg-muted animate-pulse "
          style={{ width: '120px', height: '14px' }}
        />
      </div>
    </div>
  );
};

export default SkeletonCard;
