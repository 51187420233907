import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

import { cva } from 'class-variance-authority';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const InputVariants = cva('relative', {
  variants: {
    iconPosition: {
      left: ' absolute left-3 top-1/2 -translate-y-1/2 transform text-muted-foreground',
      right:
        ' absolute left-auto right-2 top-1/2 -translate-y-1/2 transform text-muted-foreground',
    },
  },
  defaultVariants: {
    iconPosition: 'left',
  },
});

export const getInitials = (name: string) => {
  return `${name?.charAt(0)}${name?.charAt(1)}`.toUpperCase();
};
