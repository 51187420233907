import React from 'react';
import { cn } from '../../lib/utils';

const Logo = ({ type, className }: { type: string; className?: string }) => {
  if (type === 'icon') {
    return (
      <img className={cn('w-10 h-16', className)} src="/LOGO.png" alt="" />
    );
  } else if (type === 'light') {
    return (
      <img
        className={cn('w-12 h-16', className)}
        src="/musha-light-logo.png"
        alt="light-logo"
      />
    );
  } else {
    return (
      <div className="text-lg text-primary">
        <h2>Musha</h2>
      </div>
    );
  }
};

export default Logo;
