'use client';

import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import React from 'react';

const images = [
  '/heroImage.png',
  '/heroImage2.png',
  '/heroImage3.png',
  '/heroImage4.png',
];

const delay = 2500;

export default function Hero() {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsLoading(false); // You can handle errors separately if needed
  };

  function Slideshow() {
    const [index, setIndex] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        setIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, delay);

      return () => {
        clearInterval(interval);
      };
    }, []);

    return (
      <div className="relative overflow-hidden">
        {isLoading && (
          <div className="bg-gray-100 w-full sm:w-[656px] h-full sm:h-[596px] rounded-[35px] animate-pulse" />
        )}
        <div className=" w-full max-w-[656px] rounded-[35px]">
          {images.map((image: string, i: number) => (
            <img
              key={i}
              src={image}
              alt={`Slide ${i}`}
              className={` w-full sm:w-[656px] h-full sm:h-[596px] rounded-[35px]  transition ease-in-out duration-300
                ${i === index ? 'block' : 'hidden'}`}
              onLoad={handleImageLoad}
              onError={handleImageError}
              style={isLoading ? { display: 'none' } : {}}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="relative overflow-hidden bg-white">
      <div className="py-24">
        <div className="relative flex flex-col items-center justify-between gap-5 app-container lg:flex-row">
          <div className="sm:max-w-lg text-start sm:text-center lg:text-start">
            <h1 className="text-5xl font-bold leading-[60px] sm:leading-[80px] text-primary sm:text-6xl">
              Build your Real Estate portfolio a unit at a time.
            </h1>
            <p className="mt-4 text-base sm:text-xl text-primary">
              Buy part, invest and co-own real estate properties according to
              your financial strength.
            </p>
            <div className="flex flex-col gap-4 mt-6">
              <Link
                to="/login"
                className="px-6 py-3 font-semibold leading-6 text-center text-white border border-white rounded-xl bg-primary w-fit"
              >
                Get started
              </Link>
            </div>
          </div>
          <Slideshow />
        </div>
      </div>
    </div>
  );
}
