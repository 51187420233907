import HomeGallery from '../../components/dashboard/HomeGallery';
import { useGetAssets } from '../../helpers/api/useAsset';
import ErrorMessage from '../../components/ui/error-fallback';
import React from 'react';

const HomePage = () => {
  const { isLoading, isError, data, errorMessage, refetch } = useGetAssets();
  const assets = data?.data;

  if (isError) {
    return <ErrorMessage message={errorMessage} refetch={refetch} />;
  }

  return <HomeGallery assets={assets} isLoading={isLoading} />;
};

export default HomePage;
