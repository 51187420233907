import React, { useEffect, useState } from 'react';
import { Progress } from '../../ui/progress';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '../../ui/sheet';
import useDisclosure from '../../../hooks/useDisclosure';
import StepOne from './stepOne';
import StepTwo from './stepTwo';
import { INewAssetPayload } from './schema';
import { Button } from '../../ui/button';
import { Avatar, AvatarFallback, AvatarImage } from '../../ui/avatar';
import { getInitials } from '../../../lib/utils';
import { Icons } from '../../../assets/icons';
import { useGetUser } from '../../../helpers/api/useAsset';
import ContainerLayout from '../../core/containerLayout';
import parse from 'html-react-parser';
import { CurrencyFormat } from '../../../utils/helper';

export interface ExtendedNewAsset extends INewAssetPayload {
  roiMutiple: number;
  maximumRoi: number;
  minimumPriceUnit?: number;
  minimumUnit?: number;
  propertyName: string;
  moratoriumName: string;
  isPropertyConversionAllowed: boolean;
}

const NewAsset = () => {
  const { payload: user } = useGetUser();
  let profilePicture =
    user && user.profilePicture
      ? user.profilePicture
      : '/assets/images/profileImagePlaceholder.png';
  const user_name = (user?.firstname || '') + ' ' + (user?.lastname || '');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [step, setStep] = useState<number>(1);
  const [isClear, setClear] = useState<boolean>(false);
  const [isGoBack, setGoBack] = useState<boolean>(false);
  const progress = step === 1 ? 50 : 100;
  const [createAssetData, setCreateAssetData] = useState<
    ExtendedNewAsset | undefined
  >(undefined);

  const key = 'createAssetData';

  // Retrieve data from local storage when component mounts
  useEffect(() => {
    const storedData = localStorage.getItem(key);
    if (storedData) {
      setCreateAssetData(JSON.parse(storedData));
    }
  }, []);

  // Update local storage whenever createAssetData changes
  useEffect(() => {
    if (createAssetData) {
      localStorage.setItem(key, JSON.stringify(createAssetData));
    }
  }, [createAssetData]);

  const firstName = user?.firstname;
  const lastName = user?.lastname;
  const fullName =
    firstName && lastName ? `${firstName} ${lastName}` : firstName || lastName;
  const description = createAssetData?.description ?? '';
  return (
    <>
      <ContainerLayout className="gap-4 px-4 md:px-5">
        <div className="w-full">
          <div className="flex justify-center text-center">
            <p className="text-sm font-medium text-steel-gray">{`Step ${step}`}</p>
            <p className="text-sm text-[#D1D1D1]">/2</p>
          </div>
          <div className="flex items-center justify-center py-3">
            <Progress value={progress} className="max-w-[600px] h-1 w-full" />
          </div>
        </div>

        <div className="w-full mx-auto max-w-[742px]">
          {step === 1 && (
            <StepOne
              onFinish={(data: ExtendedNewAsset) => {
                setCreateAssetData({
                  ...createAssetData,
                  ...data,
                });
                setStep(2);
                setGoBack(false);
              }}
              isClear={isClear}
              setClear={setClear}
              data={createAssetData}
              triggerDefaultValue={isGoBack}
            />
          )}

          {step === 2 && (
            <StepTwo
              data={createAssetData}
              setStep={setStep}
              isOpen={isOpen}
              openPreview={onOpen}
              onFinish={setCreateAssetData}
              onCreateAssetAdd={(data: ExtendedNewAsset) => {
                setCreateAssetData({
                  ...createAssetData,
                  ...data,
                });
                setStep(2);
              }}
            />
          )}
          {createAssetData && (
            <Button
              variant="ghost"
              className="p-0 text-destructive hover:text-red-400"
              onClick={() => {
                setCreateAssetData(undefined);
                localStorage.removeItem(key);
                setStep(1);
                setClear(true);
              }}
            >
              Clear form
            </Button>
          )}
        </div>
      </ContainerLayout>

      <Sheet open={isOpen} onOpenChange={onClose}>
        <SheetContent>
          <SheetHeader>
            <SheetTitle>Asset preview</SheetTitle>
            <SheetDescription className="flex items-center justify-between">
              <span className="text-sm">Up for Sale</span>{' '}
              <Button
                variant="ghost"
                onClick={() => {
                  setStep(1);
                  onClose();
                  setGoBack(true);
                }}
              >
                Edit <Icons.EditIcon className="ml-2" />
              </Button>
            </SheetDescription>
          </SheetHeader>{' '}
          <div className="flex flex-col h-screen gap-4 pb-40 mt-2 overflow-y-auto">
            <div className="relative w-full p-4 rounded-lg bg-primary dark:bg-[#6E7DAA]">
              <div className="flex items-center justify-start gap-3 bg-inherit">
                <Avatar>
                  <AvatarImage src={profilePicture} className="object-cover " />
                  <AvatarFallback>
                    {getInitials(user_name || '')}
                  </AvatarFallback>
                </Avatar>
                <div className="text-white bg-inherit">
                  <h3 className="font-bold bg-inherit">
                    {user?.accountType.toLowerCase() === 'corporate'
                      ? user?.companyName || 'Not Available'
                      : fullName || 'Not Available'}
                  </h3>
                  <p className="text-sm text-[#D7D7D7] bg-inherit">
                    {user?.accountType || 'Not Available'}
                  </p>
                </div>
              </div>
              <span className="absolute bottom-2 right-2 rounded-full p-2 text-[#F08626] bg-[#FFF8E5] text-xs">
                Owner
              </span>
            </div>
            <div className="flex flex-col gap-4">
              <h1 className="font-semibold text-steel-gray">
                {createAssetData?.title || ''}
              </h1>
              <div className="text-[13px] list-style space-y-1">
                {parse(description)}
              </div>
            </div>
            <hr />
            <div className="space-y-4">
              <p className="font-normal text-light-grey">
                <span className="text-base font-semibold text-steel-gray dark:text-white">
                  <CurrencyFormat
                    value={createAssetData?.unitPrice || 0}
                    currency="NGN"
                  />
                </span>
                /<span className="text-[12px]">Unit</span>{' '}
              </p>
              <div className="flex flex-col gap-1">
                <span className="text-light-gray text-xs">Property type</span>
                <span className="capitalize">
                  {createAssetData?.propertyName}
                </span>
              </div>
              <div className="flex flex-col gap-1">
                <span className="text-light-gray text-xs capitalize">
                  Moratorium
                </span>
                <span className="capitalize">
                  {createAssetData?.moratoriumName}
                </span>
              </div>
              <div className="flex flex-row items-center gap-2">
                <svg
                  width="15"
                  height="20"
                  viewBox="0 0 15 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_1455_12381)">
                    <path
                      d="M6.72917 19.6C1.05333 11.3683 0 10.5242 0 7.5C0 3.3575 3.3575 0 7.5 0C11.6425 0 15 3.3575 15 7.5C15 10.5242 13.9467 11.3667 8.27083 19.5967C8.18464 19.7208 8.06973 19.8223 7.93589 19.8925C7.80206 19.9627 7.65325 19.9995 7.50212 19.9998C7.35099 20.0001 7.20202 19.964 7.06788 19.8944C6.93374 19.8247 6.8184 19.7238 6.73167 19.6L6.73 19.5967L6.72917 19.6ZM7.5 10.625C8.3288 10.625 9.12366 10.2958 9.70971 9.70971C10.2958 9.12366 10.625 8.3288 10.625 7.5C10.625 6.6712 10.2958 5.87634 9.70971 5.29029C9.12366 4.70424 8.3288 4.375 7.5 4.375C6.6712 4.375 5.87634 4.70424 5.29029 5.29029C4.70424 5.87634 4.375 6.6712 4.375 7.5C4.375 8.3288 4.70424 9.12366 5.29029 9.70971C5.87634 10.2958 6.6712 10.625 7.5 10.625Z"
                      fill="#4D5D6A"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1455_12381">
                      <rect width="15" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                <span className="text-light-gray text-sm">
                  {createAssetData?.address?.fullAddress}
                </span>
              </div>
            </div>
          </div>
        </SheetContent>
      </Sheet>
    </>
  );
};

export default NewAsset;
