import { useLocation, useNavigate } from 'react-router-dom';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../ui/button';
import SkeletonCard from '../ui/skeleton-card';
import AssetCard from '../ui/asset-card';
import React from 'react';
import ContainerLayout from '../core/containerLayout';

const AssetCategory = ({
  assetCategory,
  isLoading,
}: {
  assetCategory: any;
  isLoading: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const goBack = () => {
    navigate(-1);
  };
  const asset = assetCategory?.data;

  return (
    <ContainerLayout className="w-full gap-0 md:gap-2">
      <div className="w-full">
        <Button variant="ghost" className="p-0" onClick={goBack}>
          <FontAwesomeIcon icon={faAngleLeft} />{' '}
          <span className="text-sm md:text-base">{location?.state?.name}</span>
        </Button>
      </div>
      <div className="flex flex-col w-full gap-4">
        {isLoading ? (
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-4">
              <div className="grid grid-cols-2 gap-4 xl:grid-cols-4 2xl:grid-cols-5 lg:grid-cols-3">
                {Array(15)
                  .fill(0)
                  .map((_, i) => (
                    <SkeletonCard key={i} />
                  ))}
              </div>
            </div>
          </div>
        ) : asset?.length > 0 ? (
          <div className="grid grid-cols-2 gap-4 xl:grid-cols-4 2xl:grid-cols-5 lg:grid-cols-3">
            {asset?.map((asset: any, j: number) => (
              <AssetCard key={j} asset={asset ?? []} />
            ))}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-[75vh] gap-3">
            <p className="-mt-5 text-base">{'No assets here'}</p>
          </div>
        )}
      </div>
    </ContainerLayout>
  );
};

export default AssetCategory;
