import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { Icons } from '../../assets/icons';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Button } from '../ui/button';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { errorConnection } from '../../helpers/api/useAuth';
import { toast } from 'sonner';

type ResetPasswordProps = {
  resetToken: string;
  emailAddress: string;
  isValid: boolean;
};

const ResetPassword = ({
  resetToken,
  emailAddress,
  isValid,
}: ResetPasswordProps) => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [loading, setLoading] = useState<boolean>(false);
  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const [passwordShown2, setPasswordShown2] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(false);
  const [credential, setCredential] = useState({
    emailAddress,
    resetToken,
    newPassword: '',
    confirmPassword: '',
  });
  const [errorMessage, setErrorMessage] = useState<string>('');

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const togglePassword2 = () => {
    setPasswordShown2(!passwordShown2);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCredential((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  useEffect(() => {
    const isPasswordValid = credential.newPassword.length >= 6;
    const doPasswordsMatch =
      credential.newPassword === credential.confirmPassword;
    if (credential.confirmPassword && !doPasswordsMatch) {
      setErrorMessage('Confirm password does not match the new password');
    } else {
      setErrorMessage('');
    }
    setValid(isPasswordValid && doPasswordsMatch);
  }, [credential]);

  const HandleChangePassword = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await axiosPrivate.post(
        `user/complete-reset-password`,
        credential
      );
      if (res && res.data) {
        toast.success('Password change successful');
        setLoading(false);
        navigate('../login');
      }
    } catch (err: any) {
      setLoading(false);
      const errorObject = err?.response?.data?.errors;
      const errorKeys = errorObject && Object.keys(errorObject)[0];

      // Get the first error message
      const firstError =
        errorKeys && typeof errorObject === 'object' && errorObject !== null
          ? errorObject?.[errorKeys][0]
          : null;

      // If errors exist, show the first one
      const errorString = err?.response?.data?.errors
        ? firstError
        : err?.response?.data?.responseMessage || errorConnection;
      toast.error(errorString);
    }
  };

  return (
    <div className="w-full space-y-4">
      <div className="text-center">
        <h4 className="text-2xl font-semibold">Setup Your New Password</h4>
        <p>Let's get you a new password</p>
      </div>
      {!isValid && (
        <h2 className="text-sm text-center text-destructive">
          Your reset token has expired
        </h2>
      )}
      <form onSubmit={HandleChangePassword} className="w-full space-y-4">
        <div className="space-y-3">
          <div className="flex flex-col w-full gap-1">
            <Label>New Password</Label>
            <Input
              id="newPassword"
              onChange={(e) => handleChange(e)}
              placeholder="Enter your new password"
              type={passwordShown ? 'text' : 'password'}
              endIcon={
                <span onClick={togglePassword}>
                  {!passwordShown ? (
                    <FontAwesomeIcon className="passwordIcon" icon={faEye} />
                  ) : (
                    <FontAwesomeIcon
                      className="passwordIcon"
                      icon={faEyeSlash}
                    />
                  )}
                </span>
              }
            />
          </div>
          <div className="flex flex-col w-full gap-1">
            <Label>Confirm Password</Label>
            <Input
              id="confirmPassword"
              placeholder="Confirm your new password"
              onChange={(e) => handleChange(e)}
              type={passwordShown2 ? 'text' : 'password'}
              endIcon={
                <span onClick={togglePassword2}>
                  {!passwordShown2 ? (
                    <FontAwesomeIcon className="passwordIcon" icon={faEye} />
                  ) : (
                    <FontAwesomeIcon
                      className="passwordIcon"
                      icon={faEyeSlash}
                    />
                  )}
                </span>
              }
            />
            {errorMessage && (
              <p className="text-sm text-destructive">{errorMessage}</p>
            )}
          </div>{' '}
        </div>

        <Button
          type="submit"
          size="lg"
          className="w-full"
          disabled={!valid || !isValid}
        >
          {!loading ? (
            'Submit'
          ) : (
            <Icons.Spinner className="w-6 h-6 bg-transparent animate-spin" />
          )}
        </Button>
      </form>
    </div>
  );
};

export default ResetPassword;
