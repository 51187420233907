import React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../ui/form';
import { Input } from '../../ui/input';
import { IUser } from '../../../interface/auth/api';
import FieldDisplay from './fieldDisplay';
import { Icons } from '../../../assets/icons';
import { ResetPassword } from './ResetPassword';

const UserProfileForm: React.FC<{
  user: IUser;
  onOpenPhoneDialog: () => void;
}> = ({ user, onOpenPhoneDialog }) => {
  const form = useForm({
    defaultValues: {
      firstname: user?.firstname,
      lastname: user?.lastname,
      email: user?.email,
      phone: user?.phone,
    },
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSubmit(async (data) => {
      console.log('Corporate profile update:', data);
    })();
  };
  return (
    <div className="flex flex-col items-start justify-start w-full gap-6">
      <Form {...form}>
        <form
          className="flex flex-col items-center justify-center w-full gap-4"
          onSubmit={onSubmit}
        >
          <div className="grid w-full grid-cols-1 gap-4 sm:grid-cols-2">
            <FormField
              name="firstname"
              control={control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>First Name</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder="First Name"
                      error={!!errors.firstname}
                      disabled
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              name="lastname"
              control={control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Last Name</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder="Last Name"
                      error={!!errors.lastname}
                      disabled
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <FormField
            name="email"
            control={control}
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    placeholder="Email"
                    error={!!errors.email}
                    className="w-full"
                    disabled
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex items-end justify-between w-full gap-2">
            <FieldDisplay label="Company Phone" value={`+${user?.phone}`} />
            <Button
              className="mb-1 hover:bg-muted"
              variant="ghost"
              type="button"
              onClick={onOpenPhoneDialog}
            >
              <Icons.EditIcon className="w-5 h-5 text-primary" />
            </Button>
          </div>
        </form>
      </Form>

      <div className="flex flex-col items-start justify-center w-full gap-5">
        <h1 className="text-2xl font-semibold text-foreground">Password</h1>
        <ResetPassword user={user} />
      </div>
    </div>
  );
};

export default UserProfileForm;
