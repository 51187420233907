import useAxiosWithInterceptor from '../helpers/axiosConfig';
import { setTokens, getTokens } from '../utils/token';

const useRefreshToken = () => {
  const axiosInstance = useAxiosWithInterceptor();
  const refresh = async () => {
    const { refreshToken } = getTokens();

    try {
      const response = await axiosInstance.post('/api/v1/User/refresh-token', {
        refreshToken,
        jwtToken: getTokens().accessToken,
      });

      if (response.data?.data) {
        const {
          jwtToken,
          refreshToken: newRefreshToken,
          jwtTokenExpiry,
        } = response.data.data;
        setTokens(jwtToken, newRefreshToken, jwtTokenExpiry);
        return jwtToken;
      }
    } catch (error) {
      console.error('Error refreshing token:', error);
      throw error;
    }
  };

  return refresh;
};

export default useRefreshToken;
