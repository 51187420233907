import React, { useState } from 'react';
import { useGetUser } from '../../../helpers/api/useAsset';
import ContainerLayout from '../../core/containerLayout';
import { Avatar, AvatarFallback, AvatarImage } from '../../ui/avatar';
import { Icons } from '../../../assets/icons';
import ErrorMessage from '../../ui/error-fallback';
import UpdatePhoneNumberDialog from './updatePhoneNumberDialog';
import UserProfileForm from './userProfileForm';
import CorporateProfileForm from './corporateProfileForm';
import { IUser } from '../../../interface/auth/api';

const ProfileHeader: React.FC<{ user: IUser }> = ({ user }) => {
  const profilePicture = user?.profilePicture;
  const firstName = user?.firstname;
  const lastName = user?.lastname;
  const fullName =
    firstName && lastName ? `${firstName} ${lastName}` : firstName || lastName;
  const displayName =
    user?.accountType.toLowerCase() === 'corporate'
      ? user?.companyName
      : fullName;

  return (
    <div className="w-full p-4 rounded-xl bg-primary dark:bg-[#6E7DAA]">
      <div className="flex items-center gap-3 sm:justify-center bg-inherit">
        <Avatar className="w-20 h-20">
          <AvatarImage src={profilePicture} className="object-cover" />
          <AvatarFallback>
            {displayName?.charAt(0) || 'Not Available'}
          </AvatarFallback>
        </Avatar>
        <div className="text-white bg-inherit">
          <h3 className="font-bold bg-inherit">
            {displayName || 'Not Available'}
          </h3>
          <p className="text-sm text-[#D7D7D7] bg-inherit">
            {user?.accountType || 'Not Available'}
          </p>
        </div>
      </div>
    </div>
  );
};

const ProfileUpdate = () => {
  const {
    payload: user,
    isLoading,
    isError,
    errorMessage,
    refetch,
  } = useGetUser();
  const [isPhoneDialogOpen, setIsPhoneDialogOpen] = useState<boolean>(false);

  if (isLoading) {
    return (
      <div className="flex justify-center mt-10">
        <Icons.Spinner />
      </div>
    );
  }

  if (isError) {
    return <ErrorMessage message={errorMessage} refetch={refetch} />;
  }

  const isCorporateAccount = user?.accountType.toLowerCase() === 'corporate';

  return (
    <ContainerLayout className="min-h-screen gap-4 p-4 md:px-5 ">
      <ProfileHeader user={user} />
      <div className="max-w-[600px] flex flex-col items-center justify-center gap-6 w-full mx-auto sm:p-6">
        {isCorporateAccount ? (
          <CorporateProfileForm
            user={user}
            onOpenPhoneDialog={() => setIsPhoneDialogOpen(true)}
          />
        ) : (
          <UserProfileForm
            user={user}
            onOpenPhoneDialog={() => setIsPhoneDialogOpen(true)}
          />
        )}
      </div>
      {/* <EncryptedForm /> */}
      <UpdatePhoneNumberDialog
        user={user}
        isOpen={isPhoneDialogOpen}
        onOpen={setIsPhoneDialogOpen}
      />
    </ContainerLayout>
  );
};

export default ProfileUpdate;
