import React from 'react';
import WelcomeHeroSection from '../../components/welcome/hero';
import WelcomeInfoSection from '../../components/welcome/infoSection';

const HomePage = () => {
  return (
    <div className="flex flex-row h-screen">
      <div className="w-full px-4 md:w-2/4">
        <WelcomeHeroSection />
      </div>
      <div className="hidden w-2/4 md:block">
        <WelcomeInfoSection />
      </div>
    </div>
  );
};

export default HomePage;
