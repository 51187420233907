import { Icons } from '../../assets/icons';
import React from 'react';

const AnalyticsEmpty = ({ message }: { message: string }) => {
  return (
    <div className="absolute z-20 flex justify-center w-full h-full">
      <div className="absolute blur-[22px] bg-white dark:bg-[#171717]/80 h-full w-3/4 mx-auto " />
      <div className="z-30 flex flex-col items-center justify-center bg-white dark:bg-transparent ">
        <Icons.AnalyticsGraph />
        <p className="text-s text-grey-300 ">{message ?? 'No data found'}</p>
      </div>
    </div>
  );
};

export default AnalyticsEmpty;
