import { Outlet } from 'react-router-dom';
import Header from '../../components/core/nav/Header';
import Sidebar from '../../components/core/nav/Sidebar';
import { useGetUser } from '../../helpers/api/useAsset';
import React from 'react';
import { AccountVerificationBanner } from '../../components/core/accountVerificationBanner';

const AppHome = () => {
  const { payload } = useGetUser();
  const user = payload;

  return (
    <div className="flex h-screen mx-auto overflow-hidden max-w-[1800px] bg-background dark:bg-[#171717]">
      {/* <!-- ===== Sidebar Start ===== --> */}
      <Sidebar user={user} />
      {/* <!-- ===== Sidebar End ===== --> */}

      {/* <!-- ===== Content Area Start ===== --> */}
      <div className="relative flex flex-col flex-1 gap-4 overflow-x-hidden ">
        {/* <!-- ===== Header Start ===== --> */}
        <AccountVerificationBanner
          status={user?.accountStatus}
          placement="header"
        />
        <Header user={user} />

        {/* <!-- ===== Header End ===== --> */}

        {/* <!-- ===== Main Content Start ===== --> */}
        <div className="w-full pb-40 mx-auto md:pb-28">
          <Outlet />
        </div>
        {/* <!-- ===== Main Content End ===== --> */}
      </div>
      {/* <!-- ===== Content Area End ===== --> */}
    </div>
  );
};

export default AppHome;
