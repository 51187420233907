import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { toast } from 'sonner';
import { Icons } from '../../../assets/icons';
import { Button } from '../../ui/button';
import { Input } from '../../ui/input';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState({
    emailAddress: '',
  });

  const handleChange = (e) => {
    setEmail((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };
  const HandleChangePassword = async (e) => {
    e.preventDefault();
    if (email.emailAddress === '') return;
    setLoading(true);
    try {
      const res = await axiosPrivate.post(`user/reset-password`, email);
      if (res && res.data) {
        setLoading(false);
        toast.success(res.data.data || 'A reset email has been sent to you');
        navigate('../login');
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.response?.data?.responseMessage || 'An error occurred');
    }
  };
  return (
    <form onSubmit={HandleChangePassword} className="w-full max-w-sm mx-auto">
      <div className="text-center">
        <h4 className="text-2xl font-semibold">Forgot Your Password?</h4>
        <p>Let's get you a new one</p>
      </div>
      <div className="py-4">
        <label
          htmlFor="emailAddress"
          className="text-[13px] text-[#A2A1A8] font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          Email Address
        </label>
        <Input
          id="emailAddress"
          name="emailAddress"
          placeholder="Enter your email address"
          type="email"
          autoComplete="off"
          autoCapitalize="off"
          onChange={(e) => handleChange(e)}
        />
      </div>
      <Button
        disabled={email.emailAddress === '' || loading}
        className="rounded-lg text-sm w-full font-medium disabled:opacity-50 outline-none p-3.5 h-12 flex items-center justify-center"
      >
        {!loading ? (
          'Submit'
        ) : (
          <Icons.Spinner className="w-6 h-6 animate-spin" />
        )}
      </Button>
    </form>
  );
};

export default ForgotPassword;
