import React from 'react';
import { SVGProps } from 'react';

export const NetworkErrorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="119"
    height="80"
    viewBox="0 0 119 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M80.18 28.63C71.82 28.63 63.32 28.3 55.36 26.08C47.4 23.86 40.42 19.71 34 14.85C29.81 11.68 26 9.16997 20.57 9.54997C15.2572 9.83024 10.1762 11.815 6.08004 15.21C-0.829964 21.21 0.220035 32.44 2.98004 40.31C7.13004 52.16 19.77 60.39 30.51 65.76C42.92 71.96 56.51 75.56 70.22 77.63C82.22 79.45 97.59 80.77 107.97 72.95C117.51 65.76 120.13 49.35 117.79 38.26C117.223 34.9856 115.481 32.0306 112.89 29.95C106.19 25.06 96.2 28.33 88.67 28.49C85.87 28.55 83 28.62 80.18 28.63Z"
      fill="#F2F2F2"
    />
    <path
      d="M106.45 53.3V57.6"
      stroke="#BABABA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M104.3 55.45H108.6"
      stroke="#BABABA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.22 28.9V33.2"
      stroke="#BABABA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.0701 31.05H33.3701"
      stroke="#BABABA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M57.8201 10.65C58.3613 10.65 58.8001 10.2112 58.8001 9.67C58.8001 9.12876 58.3613 8.69 57.8201 8.69C57.2789 8.69 56.8401 9.12876 56.8401 9.67C56.8401 10.2112 57.2789 10.65 57.8201 10.65Z"
      fill="#CFCFCF"
    />
    <path
      d="M21.28 6.55003C21.8213 6.55003 22.26 6.11127 22.26 5.57003C22.26 5.02879 21.8213 4.59003 21.28 4.59003C20.7388 4.59003 20.3 5.02879 20.3 5.57003C20.3 6.11127 20.7388 6.55003 21.28 6.55003Z"
      fill="#CFCFCF"
    />
    <path d="M48.83 31.4H45.02V57.74H48.83V31.4Z" fill="#D2D2D2" />
    <path
      d="M44.9447 33.3184L46.7598 36.6683L68.7846 24.7347L66.9695 21.3848L44.9447 33.3184Z"
      fill="#D2D2D2"
    />
    <path
      d="M43.8001 55.46H49.6301C52.6032 55.46 55.4545 56.6411 57.5568 58.7434C59.659 60.8456 60.8401 63.6969 60.8401 66.67V67.49H32.5901V66.67C32.5901 63.6969 33.7711 60.8456 35.8734 58.7434C37.9757 56.6411 40.827 55.46 43.8001 55.46Z"
      fill="white"
      stroke="#BABABA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M64.69 67.62H28.74"
      stroke="#BABABA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.16 41.1C50.8107 41.1 53.77 38.1406 53.77 34.49C53.77 30.8394 50.8107 27.88 47.16 27.88C43.5094 27.88 40.55 30.8394 40.55 34.49C40.55 38.1406 43.5094 41.1 47.16 41.1Z"
      fill="white"
      stroke="#BABABA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M83.93 59.68C83.76 59.62 82.48 59.74 82.32 59.68C81.17 59.25 80.76 60.68 79.7 60.09C72.9488 56.6157 67.7023 50.7909 64.9502 43.7144C62.1982 36.638 62.1309 28.799 64.761 21.6763C67.3911 14.5536 72.5368 8.63961 79.2274 5.04989C85.918 1.46016 93.691 0.442907 101.08 2.19001C102.342 2.49512 103.584 2.87581 104.8 3.33001C105.03 3.41001 104.47 4.65001 104.69 4.73001L83.93 59.68Z"
      fill="white"
      stroke="#BABABA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M103.82 36C99.0001 48.91 91.8801 58.73 86.1101 61.25C85.3375 61.6142 84.5027 61.828 83.6501 61.88C83.0049 61.9234 82.3577 61.8314 81.7501 61.61C81.2717 61.4322 80.8282 61.1715 80.4401 60.84C80.2207 60.6641 80.0197 60.4664 79.8401 60.25C75.8401 55.68 76.4901 42.25 81.8401 27.85C87.1401 13.67 95.2601 3.18 101.27 2.1C101.642 2.02339 102.021 1.98984 102.4 2.00001C102.909 2.01084 103.412 2.10544 103.89 2.28C104.572 2.54016 105.185 2.95425 105.68 3.49001C106.215 4.05925 106.66 4.70668 107 5.41C109.69 11.1 108.62 23.18 103.82 36Z"
      fill="#D2D2D2"
    />
    <path
      d="M82.4299 27.24L113.67 38.65"
      stroke="#BABABA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M111.87 42.1C114.278 42.1 116.23 40.148 116.23 37.74C116.23 35.332 114.278 33.38 111.87 33.38C109.462 33.38 107.51 35.332 107.51 37.74C107.51 40.148 109.462 42.1 111.87 42.1Z"
      fill="white"
      stroke="#BABABA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.8399 14.55C38.8399 15.0998 38.7314 15.6441 38.5207 16.1519C38.3101 16.6597 38.0013 17.121 37.6121 17.5092C37.2228 17.8975 36.7609 18.2052 36.2526 18.4147C35.7443 18.6242 35.1996 18.7313 34.6499 18.73H24.3799C23.5842 18.73 22.8212 18.4139 22.2586 17.8513C21.696 17.2887 21.3799 16.5256 21.3799 15.73C21.3799 14.9343 21.696 14.1713 22.2586 13.6087C22.8212 13.0461 23.5842 12.73 24.3799 12.73C24.7736 12.7306 25.1637 12.8052 25.5299 12.95C25.4896 12.7559 25.4695 12.5582 25.4699 12.36C25.477 11.5802 25.7642 10.829 26.2792 10.2433C26.7941 9.65774 27.5025 9.27683 28.275 9.17011C29.0475 9.06339 29.8326 9.23799 30.487 9.66205C31.1415 10.0861 31.6216 10.7313 31.8399 11.48C32.601 10.7668 33.6069 10.373 34.6499 10.38C35.1988 10.3787 35.7426 10.4855 36.2503 10.6943C36.7579 10.9032 37.2194 11.21 37.6085 11.5972C37.9976 11.9844 38.3066 12.4445 38.5179 12.9511C38.7292 13.4578 38.8386 14.0011 38.8399 14.55Z"
      fill="white"
      stroke="#BABABA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
