import * as Yup from 'yup';
import { Address } from '../addAsset/schema';

const DocumentSchema: Yup.ObjectSchema<IEditDocument> = Yup.object().shape({
  file: Yup.string().required('File path is required'),
  name: Yup.string().required('Document name is required'),
  assetDocumentId: Yup.number(),
  removeDocument: Yup.boolean().default(false),
});

const ImageSchema: Yup.ObjectSchema<IEditImage> = Yup.object().shape({
  isDefaultImage: Yup.boolean().required('Default image status is required'),
  file: Yup.string().required('Image file path is required'),
  imageId: Yup.number(),
  removeImage: Yup.boolean().default(false),
});

export const EditAssetFormSchema: Yup.ObjectSchema<IEditAsset> =
  Yup.object().shape({
    assetId: Yup.number().required(),
    propertyTypeId: Yup.number().required('Property type is required'),
    title: Yup.string().required('Title is required').trim(),
    description: Yup.string()
      .min(100, 'Description must be at least 100 characters long')
      .required('Description is required')
      .trim(),
    moratoriumId: Yup.number(),
    address: Yup.object().shape({
      fullAddress: Yup.string().required('Full address is required'),
      localGovernmentId: Yup.number().required(
        'Local government ID is required'
      ),
      country: Yup.string(),
      state: Yup.string(),
    }),
    totalAssetValue: Yup.number()
      .typeError('Please provide a valid value')
      .required('Total asset value is required'),
    availableUnits: Yup.number()
      .typeError('Please provide a valid value')
      .min(1, 'AvailableUnits cannot be less than 1')
      .required('Available units is required'),
    expectedROI: Yup.number()
      .typeError('Please provide a valid value')
      .min(1, 'Expected cannot be less than 1')
      .required('Expected ROI is required'),
    expectedCompletion: Yup.string(),
    unitPrice: Yup.number()
      .typeError('Please provide a valid value')
      .required('Unit price is required'),
    isInvestimentConvertable: Yup.boolean().default(false),
    documents: Yup.array()
      .of(DocumentSchema)
      .min(1, 'At least one document is required')
      .required('Documents is required'),
    images: Yup.array()
      .of(ImageSchema)
      .min(4, 'At least four images is required')
      .required('Images is required'),
  });

export type EditAssetType = Yup.InferType<typeof EditAssetFormSchema>;

export interface IEditAssetPayload {
  assetId: number;
  propertyTypeId: number;
  title: string;
  description: string;
  moratoriumId?: number;
  address: {
    lga: string;
    lgaId: number;
    localGovernmentId: { label: any; value: any };
    fullAddress: string;
    country?: { label: any; value: any };
    state?: { label: any; value: any };
  };
  totalAssetValue: number;
  availableUnits: number;
  expectedROI: number;
  expectedCompletion?: string | null;
  unitPrice: number;
  isInvestimentConvertable: boolean;
  documents: IEditDocument[];
  images: IEditImage[];
}

export interface IEditAsset {
  assetId: number;
  propertyTypeId: number;
  title: string;
  description: string;
  moratoriumId?: number;
  address: Address;
  totalAssetValue: number;
  availableUnits: number;
  expectedROI: number;
  expectedCompletion?: string | undefined;
  unitPrice: number;
  isInvestimentConvertable: boolean;
  documents: IEditDocument[];
  images: IEditImage[];
}

export interface IEditDocument {
  file: string;
  name: string;
  removeDocument: boolean;
  assetDocumentId?: number | undefined;
}

export interface IEditImage {
  isDefaultImage: boolean;
  file: string;
  removeImage: boolean;
  imageId?: number | undefined;
}
