export const BASE_URL = process.env.REACT_APP_URL;

export function useUrls() {
  const loginUrl = `${BASE_URL}/api/v1/user/login`;
  const user = `${BASE_URL}/api/v1/user`;
  const asset = `${BASE_URL}/api/v1/asset`;
  const admin = `${BASE_URL}/api/v1/Admin`;
  const otpUrl = `${BASE_URL}/api/v1/user/login-otp`;
  const requiredDocuments = `${BASE_URL}/api/v1/user/verification-required-documents`;

  return {
    admin,
    otpUrl,
    user,
    asset,
    loginUrl,
    requiredDocuments,
  };
}
