import React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../ui/button';
import FieldDisplay from './fieldDisplay';
import { Icons } from '../../../assets/icons';
import { IUser } from '../../../interface/auth/api';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../ui/form';
import { Input } from '../../ui/input';
import { ResetPassword } from './ResetPassword';

const CorporateProfileForm: React.FC<{
  user: IUser;
  onOpenPhoneDialog: () => void;
}> = ({ user, onOpenPhoneDialog }) => {
  const form = useForm({
    defaultValues: {
      firstname: user?.firstname,
      lastname: user?.lastname,
      email: user?.email,
      phone: user?.phone,
    },
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // const formData = new FormData(event.currentTarget);
    // const data = Object.fromEntries(formData.entries());

    handleSubmit(async (data) => {
      console.log('Corporate profile update:', data);
    })();
  };

  return (
    <div className="w-full">
      <div className="flex flex-col items-start justify-start w-full gap-6">
        <div>
          <h3 className="text-2xl font-semibold text-steel-gray">
            Company Details
          </h3>
        </div>
        <div className="flex flex-col items-center justify-center w-full gap-4">
          <FieldDisplay
            label="Company Name"
            value={user?.companyName}
            isDisabled
          />
          <FieldDisplay label="Company Email" value={user?.email} isDisabled />
          <div className="flex items-end justify-between w-full gap-2">
            <FieldDisplay label="Company Phone" value={`+${user?.phone}`} />
            <Button
              className="mb-1 hover:bg-slate-100"
              variant="ghost"
              onClick={onOpenPhoneDialog}
            >
              <Icons.EditIcon className="w-5 h-5 text-primary" />
            </Button>
          </div>
          <div className="flex flex-col items-start w-full gap-2">
            <h1 className="text-2xl font-semibold text-steel-gray">Password</h1>
            <ResetPassword user={user} />
          </div>
        </div>
        <span className="-mt-4 text-sm sm:text-base text-destructive">
          To update your company name or email, please contact us.
        </span>
      </div>
      <div className="flex flex-col items-start justify-start w-full gap-6 mt-8">
        <div>
          <h3 className="text-2xl font-semibold text-steel-gray">
            Company Representative Details
          </h3>
        </div>
        <Form {...form}>
          <form
            className="flex flex-col items-center justify-center w-full gap-4"
            onSubmit={onSubmit}
          >
            <div className="grid w-full grid-cols-1 gap-4 sm:grid-cols-2">
              <FormField
                name="firstname"
                control={control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>First Name</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder="First Name"
                        error={!!errors.firstname}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                name="lastname"
                control={control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Last Name</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder="Last Name"
                        error={!!errors.lastname}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              name="email"
              control={control}
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder="Email"
                      error={!!errors.email}
                      className="w-full"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              name="phone"
              control={control}
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Phone</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder="Phone"
                      className="w-full"
                      error={!!errors.phone}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              className="w-full"
              size="lg"
              variant="default"
              type="submit"
            >
              Update Representative Details
            </Button>
          </form>
        </Form>
      </div>
    </div>
  );
};

export default CorporateProfileForm;
