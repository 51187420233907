import { useGetAssetByCategory } from '../../helpers/api/useAsset';
import { useParams } from 'react-router-dom';
import ErrorMessage from '../../components/ui/error-fallback';
import AssetCategory from '../../components/dashboard/AssetCategory';
import { useEffect } from 'react';
import React from 'react';

const AssetDetailsPage = () => {
  const params = useParams();
  const { isLoading, isError, data, errorMessage, mutate } =
    useGetAssetByCategory();
  const details = data?.data;

  useEffect(() => {
    mutate({ category: Number(params?.id) });
  }, [mutate, params?.id]);

  if (isError) {
    return <ErrorMessage message={errorMessage} refetch={() => {}} />;
  }
  return <AssetCategory assetCategory={details} isLoading={isLoading} />;
};

export default AssetDetailsPage;
