import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { BASE_URL } from './useUrls';
import { getTokens, setTokens, TOKEN } from '../utils/token';
import { toast } from 'sonner';
import { useLogout } from './api/useAuth';
import { useRef } from 'react';

// Extend the InternalAxiosRequestConfig to include _retry
interface ExtendedAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean;
}

export const setAuthToken = (token: string) => {
  if (token) {
    // Apply to every request
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    // Delete auth header
    delete axios.defaults.headers.common['Authorization'];
  }
};

export const getAuthToken = () => {
  const getCookie = (name: string): string | null => {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.trim().split('=');
      if (cookieName === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null;
  };
  if (typeof window !== 'undefined') {
    return getCookie(TOKEN.ACCESS);
  }
};

export const getAuthorizationHeader = () => `Bearer ${getAuthToken()}`;

const useAxiosWithInterceptor = (baseUrl?: string) => {
  const { logoutUser: logout } = useLogout();
  const hasToastShown = useRef(false);

  const axiosInstance = axios.create({
    baseURL: baseUrl ? baseUrl : (BASE_URL as string),
    timeout: 100000,
    timeoutErrorMessage:
      'Your request timed out, please check your internet connection',
    headers: {
      Accept: 'application/json',
      'content-type': 'application/json',
      common: {
        Authorization: getAuthorizationHeader(),
      },
    },
  });

  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
      const originalRequest = error.config as ExtendedAxiosRequestConfig;

      if (
        error.response?.status === 401 &&
        originalRequest &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;

        try {
          const { refreshToken, accessToken } = getTokens();

          if (!refreshToken || !accessToken) {
            throw new Error('No refresh token available');
          }

          const response = await axios.post(
            `${BASE_URL}/api/v1/User/refresh-token`,
            {
              refreshToken,
              jwtToken: accessToken,
            }
          );
          if (response.data?.data) {
            const {
              jwtToken,
              refreshToken: newRefreshToken,
              jwtTokenExpiry,
            } = response.data.data;

            // Update tokens using the setTokens function
            setTokens(jwtToken, newRefreshToken, jwtTokenExpiry);

            // Update Authorization header
            setAuthToken(jwtToken);

            // Update the original request's Authorization header
            originalRequest.headers.Authorization = `Bearer ${jwtToken}`;

            return axiosInstance(originalRequest);
          }
        } catch (refreshError) {
          if (!hasToastShown.current) {
            hasToastShown.current = true;
            toast.error(
              'Unauthorized access. Please log in again or contact support.'
            );
            setTimeout(() => {
              hasToastShown.current = false;
            }, 3000);
          }
          logout();
          return Promise.reject(refreshError);
        }
      }

      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.request.use(
    async (config: any) => {
      config.headers.Authorization = getAuthorizationHeader();
      return config;
    },
    (error: any) => {
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export const cancelTokenSource = axios.CancelToken.source();

export default useAxiosWithInterceptor;
