import React, { useContext, useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { getAuthToken } from '../helpers/axiosConfig';
import { toast } from 'sonner';
import { useGetUser } from '../helpers/api/useAsset';
import Logo from './core/logo';
import ErrorMessage from './ui/error-fallback';
import { OnboardContext, OnboardContextType } from '../context/OnboardContext';
import { useCookies } from 'react-cookie';
import { GlobalContext, GlobalContextType } from '../context/GlobalContext';

const RequireAuth = () => {
  const { triggerReload, dispatch } = useContext(
    OnboardContext
  ) as OnboardContextType;
  const {
    payload,
    isLoading,
    isError,
    errorMessage,
    refetch,
    isNotAuthorize,
    isFetching,
    isFetched,
  } = useGetUser();

  const { dispatch: dispatchGlobal } = useContext(
    GlobalContext
  ) as GlobalContextType;

  const cookies = useCookies()[0];
  const user = payload;
  const token = getAuthToken();

  useEffect(() => {
    if (!isLoading && user && !isFetching) {
      if (
        user?.accountStatus === 'PendingIdentityVerification' ||
        user?.accountStatus === 'IdentityVerificationDeclined'
      ) {
        toast.error('Your account requires further verification.');
      } else if (!user?.isEmailConfirmed || !user?.isPhoneNumberConfirmed) {
        // Redirect to login if email or phone is not confirmed
        toast.error('Email or phone not confirmed');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    refetch();
    if (isFetched) {
      dispatchGlobal({ type: 'SET_USER', payload: user });
      dispatch({ type: 'TRIGGER_USER_RELOAD', payload: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerReload]);

  // If not authenticated, redirect to login
  if (!token) {
    return <Navigate to="/login" />;
  }

  // If loading, display loading state
  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen p-4 bg-background dark:bg-[#171717]">
        <Logo type={'text'} />
        ...
      </div>
    );
  }

  if (!cookies?.musha_userId) {
    return (
      <div className="flex items-center justify-center h-screen p-4 bg-background dark:bg-[#171717]">
        <ErrorMessage
          message={
            'Oops! It seems that there was an error loading the application. Please try logging out and then logging back in again.'
          }
          refetch={() => {}}
          isLogout
        />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex items-center justify-center h-screen p-4">
        <ErrorMessage
          message={errorMessage}
          refetch={refetch}
          permissionDenied={isNotAuthorize}
        />
      </div>
    );
  }

  const {
    isEmailConfirmed,
    isPhoneNumberConfirmed,
    accountStatus,
    accountType,
  } = user ?? {};

  // If Corporate account type is corporate and account status is pending verification or declined, redirect to required steps
  if (
    !triggerReload &&
    accountType === 'Corporate' &&
    accountStatus === 'CompleteCorporateOnboarding'
  ) {
    return (
      <Navigate
        to="../register"
        state={{
          section: 'register',
          subsection: 'true',
        }}
        replace={true}
      />
    );
  }

  // If account status is pending verification or declined, redirect to required steps
  if (
    !triggerReload &&
    (accountStatus === 'PendingIdentityVerification' ||
      accountStatus === 'IdentityVerificationDeclined')
  ) {
    return (
      <Navigate
        to="../register"
        state={{
          section: 'requiredSteps',
        }}
        replace={true}
      />
    );
  }

  // If email or phone is not confirmed, redirect to verification
  if (!triggerReload && (!isEmailConfirmed || !isPhoneNumberConfirmed)) {
    return (
      <Navigate
        to="../register"
        state={{
          section: 'verification',
          subsection: isPhoneNumberConfirmed ? 'email' : 'phone',
        }}
        replace={true}
      />
    );
  }

  // If authenticated and verified, render the outlet
  return <Outlet />;
};

export default RequireAuth;
