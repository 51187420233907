import { useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { Icons } from '../../assets/icons';
import { toast } from 'sonner';
import React from 'react';
import { cn } from '../../lib/utils';
import { useQueryClient } from '@tanstack/react-query';

interface LikeProps {
  assetId: number;
  isLiked: boolean;
  disabled: boolean;
}

const Like: React.FC<LikeProps> = ({ assetId, isLiked, disabled }) => {
  const axiosPrivate = useAxiosPrivate();
  const [liked, setLiked] = useState<boolean>(isLiked);
  const queryClient = useQueryClient();
  const toggle = async () => {
    setLiked(!liked);
    try {
      if (liked) {
        await axiosPrivate.post(`asset/remove-favorite`, { assetId });
        queryClient.invalidateQueries({ queryKey: ['get-assets'] });
      } else {
        await axiosPrivate.post(`asset/add-favorite`, { assetId });
        queryClient.invalidateQueries({ queryKey: ['get-assets'] });
      }
    } catch (err: any) {
      toast.error(err.response?.data?.responseMessage || 'An error occurred');
    }
  };

  return (
    <button
      type="button"
      onClick={(e) => {
        !disabled && toggle();
        e.stopPropagation();
      }}
      className={cn('z-20', { 'hidden hoverEffect disabled': disabled })}
    >
      {!liked ? (
        <Icons.LikeIcon className="text-[#82849F]" />
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="#FA3E3E"
          id="love"
        >
          <path
            fill="#FA3E3E"
            d="M4.379 12.274A4.841 4.841 0 0 1 3 8.89C3 6.189 5.216 4 7.95 4c1.674 0 3.154.82 4.05 2.077A4.962 4.962 0 0 1 16.05 4C18.784 4 21 6.189 21 8.889a4.845 4.845 0 0 1-1.507 3.512L12 20l-7.621-7.726Z"
          ></path>
        </svg>
      )}
    </button>
  );
};

export default Like;
