import * as React from 'react';
import { cva } from 'class-variance-authority';
import { cn } from '../../lib/utils';

export const InputVariants = cva('relative', {
  variants: {
    iconPosition: {
      left: ' absolute left-3 top-1/2 -translate-y-1/2 transform text-muted-foreground',
      right:
        ' absolute left-auto right-3 top-1/2 -translate-y-1/2 transform text-muted-foreground',
    },
  },
  defaultVariants: {
    iconPosition: 'left',
  },
});

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  startIcon?: React.ReactElement;
  endIcon?: React.ReactElement;
  onClickStartIcon?: () => void;
  onClickEndIcon?: () => void;
  variant?: 'normal';
  error?: boolean;
}

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type,
      startIcon: StartIcon,
      endIcon: EndIcon,
      onClickStartIcon,
      onClickEndIcon,
      variant,
      error,
      ...props
    },
    ref
  ) => {
    return (
      <>
        {variant === 'normal' ? (
          <div className="relative grid items-center w-full">
            <input
              type={type}
              className={cn(
                'bg-background [&:-webkit-autofill]:shadow-[0_0_0px_1000px_white_inset] [&:-webkit-autofill]:dark:shadow-[0_0_0px_1000px_#0E0E0E_inset] transition-colors duration-5000 ease-in-out flex h-12 w-full text-base sm:text-sm rounded-lg border text-foreground px-3 py-2 file:border-0 file:text-sm file:font-medium placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50',
                className,
                {
                  ' border-red-600 focus-visible:ring-red-500 bg-white': error,
                }
              )}
              ref={ref}
              {...props}
            />
          </div>
        ) : StartIcon || EndIcon ? (
          <div className="relative flex items-center w-full h-12 bg-transparent">
            {StartIcon && (
              <span
                onClick={onClickStartIcon}
                className={cn(
                  'z-30',
                  InputVariants({
                    iconPosition: 'left',
                  })
                )}
              >
                {StartIcon}
              </span>
            )}
            <input
              type={type}
              className={cn(
                '[&:-webkit-autofill]:shadow-[0_0_0px_1000px_white_inset] [&:-webkit-autofill]:dark:shadow-[0_0_0px_1000px_#0E0E0E_inset] transition-colors duration-5000 ease-in-out flex h-12 w-full text-base sm:text-sm rounded-lg border bg-background text-foreground px-3 py-2 file:border-0 file:text-sm file:font-medium placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50',
                className,
                StartIcon && EndIcon
                  ? 'pl-10 pr-10'
                  : StartIcon
                  ? 'pl-10 pr-3'
                  : 'pl-3 pr-10',
                {
                  ' border-red-600 focus-visible:ring-red-500 bg-white': error,
                }
              )}
              ref={ref}
              {...props}
            />
            {EndIcon && (
              <span
                onClick={onClickEndIcon}
                className={cn('z-20', InputVariants({ iconPosition: 'right' }))}
              >
                {EndIcon}
              </span>
            )}
          </div>
        ) : (
          <input
            type={type}
            className={cn(
              '[&:-webkit-autofill]:shadow-[0_0_0px_1000px_white_inset] [&:-webkit-autofill]:dark:shadow-[0_0_0px_1000px_#0E0E0E_inset] transition-colors duration-5000 ease-in-out bg-background dark:text-gray-200 flex h-12 items-center justify-center outline-none text-black w-full rounded-lg border p-3 text-base sm:text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50',
              className,
              {
                ' border-red-600 focus-visible:ring-red-500 bg-white': error,
              }
            )}
            ref={ref}
            {...props}
          />
        )}
      </>
    );
  }
);
Input.displayName = 'Input';

export { Input };
